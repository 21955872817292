const ToastPopupMixin = { 
    methods: { 
        createToast(header, body, type='info'){
            let bg_header_class,
                fg_header_class,
                fg_subtitle_class,
                fg_dismiss_class,
                duration = 5000;

            switch (type) {
                case 'info':
                    bg_header_class = 'bg-info';
                    fg_header_class = 'text-light';
                    fg_subtitle_class = 'text-light';
                    fg_dismiss_class = 'btn-close-white';
                    break;

                case 'success':
                    bg_header_class = 'bg-success';
                    fg_header_class = 'text-light';
                    fg_subtitle_class = 'text-light';
                    fg_dismiss_class = 'btn-close-white';
                    break;

                case 'warning':
                case 'warn':
                    bg_header_class = 'bg-warning';
                    fg_header_class = 'text-light';
                    fg_subtitle_class = 'text-light';
                    fg_dismiss_class = 'btn-close-white';
                    break;

                case 'error':
                case 'danger':
                    bg_header_class = 'bg-danger';
                    fg_header_class = 'text-light';
                    fg_subtitle_class = 'text-light';
                    fg_dismiss_class = 'btn-close-white';
                    break;
            }

            let toastContainer = document.getElementById('toastContainer'),
                toastTemplate  = document.getElementById('toastTemplate').cloneNode(true);

            toastTemplate.querySelector('.toast-header').classList.add(bg_header_class);
            toastTemplate.querySelector('.toast-header').classList.add(fg_header_class);
            toastTemplate.querySelector('.btn-close').classList.add(fg_dismiss_class);
            toastTemplate.querySelector('.toast-title').innerHTML = header;
            toastTemplate.querySelector('.toast-body-text').innerHTML = body;
            toastContainer.appendChild(toastTemplate);

            // wait just a bit to add active class to the message to trigger animation
            setTimeout(function(){                 
                toastTemplate.classList.add('show');
            }, 22);

             // check duration
            if(duration > 0){
                // it it's bigger then 0 add it
                setTimeout(function(){                 
                    toastTemplate.classList.remove('show');
                    setTimeout(function(){                 
                        toastTemplate.remove();
                    }, 350);       
                }, duration);
            }else if(duration == null){
                //  it ther isn't any add default one (3000ms)
                setTimeout(function(){                 
                    toastTemplate.classList.remove('show');
                    setTimeout(function(){                 
                        toastTemplate.remove();
                    }, 350);       
                }, 3000);
            }
            //if duration is 0, toast message will not be closed

            //addEventListener on mouse click for standard closing of toast message on right top "x"
            document.addEventListener('click', function (e) {
                //check is the right element clicked
                if (!e.target.matches('.btn-close')){
                    return;
                }
                else {
                    //get toast element
                    var grandElement = e.target.parentElement.parentElement;
                    //wait for 22ms and then remove element
                    setTimeout(function(){                 
                        grandElement.remove();
                    }, 22);
                }
            });
        }
    }
}

export default ToastPopupMixin;
