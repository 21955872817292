import http from "../api";

class EmployeeTitleService {

    getEmployeeTitleList(){
        return http.get("/EmployeeTitle");
    }

    create(data) {
        return http.post("/EmployeeTitle", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/EmployeeTitle', JSON.stringify(data));
    }
}

export default new EmployeeTitleService();
