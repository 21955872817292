<template>
    <div>
        <form id="imageUploadForm">
            <input id="imageFileInput" ref="input" type="file" name="upload-file" accept="image/*" @change="setImage"/>
        </form>

        <!-- <section class="preview-area">
            <p>Preview</p>
            <div class="preview"/>
            <div class="cropped-image">
                <img v-if="cropImg" :src="cropImg" alt="Cropped Image"/>
            </div>
        </section> -->

        <div class="content">
            <section class="cropper-area">
                <div class="img-cropper">
                    <vue-cropper
                        ref="cropper"
                        :guides="false"
                        :viewMode="viewMode"
                        :dragMode="move"
                        :movable="true"
                        :background="false"
                        :aspectRatio="aspectRatio"
                        :cropBoxMovable="cropBoxMovable"
                        :cropBoxResizable="cropBoxResizable"
                        :src="imgSrc"
                        preview=".preview"
                    />
                </div>
                <div class="actions btn-group d-block">
                    <a href="#" class="btn btn-secondary" role="button" @click.prevent="zoom(0.2)"><i class="bi bi-zoom-in"></i></a>
                    <a href="#" class="btn btn-secondary" role="button" @click.prevent="zoom(-0.2)"><i class="bi bi-zoom-out"></i></a>
                    <a href="#" class="btn btn-secondary" role="button" @click.prevent="rotate(90)"><i class="bi bi-arrow-clockwise"></i></a>
                    <a href="#" class="btn btn-secondary" role="button" @click.prevent="rotate(-90)"><i class="bi bi-arrow-counterclockwise"></i></a>
                </div>
                <div class="btn-group d-block">
                    <a href="#" class="btn btn-primary" role="button" @click.prevent="showFileChooser"><i class="bi bi-folder2-open"></i> Select New Image</a>
                    <a href="#" class="btn btn-success" role="button" @click.prevent="submit"><i class="bi bi-cloud-arrow-up-fill"></i> Submit</a>
                    <a href="#" class="btn btn-danger" role="button" @click.prevent="reset"><i class="bi bi-x-circle-fill"></i> Reset</a>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import VueCropper from 'vue-cropperjs';
import defaultProfilePicture from "@/assets/global-images/default-profile-picture.jpg";
    export default {
        components: {
            VueCropper,
        },
        props: {
            modelValue: {
                default: '',
            },
            move: {
                type: String,
                default: 'move',
                required: false
            },
            aspectRatio: {
                // type: Object,
                default: 16/9,
                required: false
            },
            cropBoxMovable: {
                default: true,
                required: false
            },
            cropBoxResizable: {
                default: true,
                required: false
            },
            viewMode: {
                default: 0,
                required: false
            },
        },
        data() {
            return {
                imgSrc: defaultProfilePicture,
                cropImg: '',
                data: null,
            };
        },
        methods: {
            submit() {
                // Surround the input in a form with ID
                // Create a FromData Object
                const form = document.getElementById('imageUploadForm');
                let formData = new FormData(form);

                // append the crop data to the form
                formData.append("cropData", JSON.stringify(this.$refs.cropper.getData()));

                this.$emit('update:modelValue', formData);
            },
            flipX() {
                const dom = this.$refs.flipX;
                let scale = dom.getAttribute('data-scale');
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleX(scale);
                dom.setAttribute('data-scale', scale);
            },
            flipY() {
                const dom = this.$refs.flipY;
                let scale = dom.getAttribute('data-scale');
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleY(scale);
                dom.setAttribute('data-scale', scale);
            },
            getData() {
                this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
            },
            reset() {
                this.$refs.cropper.reset();
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
            setImage(e) {
                const file = e.target.files[0];
                if (file.type.indexOf('image/') === -1) {
                    alert('Please select an image file');
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.imgSrc = event.target.result;
                        // rebuild cropperjs with the updated source
                        this.$refs.cropper.replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            showFileChooser() {
                this.$refs.input.click();
            },
            zoom(percent) {
                this.$refs.cropper.relativeZoom(percent);
            },
        },
    };
</script>

<style>

#imageFileInput{
    display: none!important;
}

    .content {
        display: flex;
        justify-content: space-between;
    }

    .cropper-area {
        width: 614px;
    }

    .actions {
        margin-top: 1rem;
    }

    .preview-area {
        width: 307px;
    }

    .preview-area p {
        font-size: 1.25rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    .preview-area p:last-of-type {
        margin-top: 1rem;
    }

    .preview {
        width: 100%;
        height: calc(372px * (9 / 16));
        overflow: hidden;
    }

    .cropped-image img {
        max-width: 100%;
    }
</style>
