import companyService from '../../service/CompanyService';
export const namespaced = true;

export const state = {
    companyData: {}
};

export const getters = {
    companyData: state => {
        return state.companyData
    },
};

export const mutations = {
    updateCompanyData(state, companyData){
        state.companyData = companyData
    },
    updateCompanyDataCompanyLogo(state, newFileName){
        state.companyData.company_logo_file = newFileName;
    },
};

export const actions = {
    setCompanyData({commit, state}){
        return new Promise((resolve, reject) => {
            companyService.get().then((response) => {
                if(response.data.status === 200){
                    console.log(response.data.record);
                    commit('updateCompanyData', response.data.record);
                    resolve();
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateCompanyData({commit, state}){
        return new Promise((resolve, reject) => {
            companyService.update(1, state.companyData).then((response) => {
                if(response.data.status === 200){
                    console.log(response.data.record);
                    commit('updateCompanyData', response.data.record);
                    resolve(response);
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateCompanyDataCompanyLogo({commit}, data){
        commit('updateCompanyDataCompanyLogo', data.record.newFileName);
    },
};
