<template>
	<div class="container-fluid app-view-containe mt-4">
		<div class="row">
			<div class="col-12 app-view-title">
				<h6>Sales Invoice</h6>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<div class="mb-4">
					<div class="card-body">
						<InvoiceForm
							@invoiceUpdated="invoiceUpdated"
							:pulledWorkingInvoiceData="pulledWorkingInvoiceData"
							:editing="true"
						/>
						<div class="mt-3">
							<div v-if="!invoiceItemList.length" class="mt-3 ,">
								<div v-if="!invoiceItemList.length && !invoiceItemListLoading"></div>
								<div v-else-if="!invoiceItemList.length && invoiceItemListLoading">Loading...</div>
								<div v-else class="text-danger">Error...</div>
							</div>
							<div v-else><br>
								<!-- Contact Table -->
								<h4>Invoice Item(s)</h4>
								<hr>
								<table class="table responsive-table">
									<thead>
									<tr>
										<th scope="col">Item Name</th>
										<th scope="col" class="text-center">Quantity</th>
										<th scope="col">Cost</th>
										<th scope="col">Tax</th>
										<th scope="col">Total</th>
										<th scope="col">&nbsp;</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="row, index in invoiceItemList" :key="index">
										<th scope="row" v-if="row.product?.product_name">{{ row.product?.product_name }}</th>
										<th scope="row" v-else>{{ row?.product_custom_name }}</th>
										<td data-title="purchase_order_item_qty">{{ row.invoice_item_quantity }}</td>
										<td data-title="invoice_item_price">{{ numberWithCommas(row.invoice_item_price) }}</td>
										<td data-title="invoice_item_tax">{{ numberWithCommas(row.invoice_item_tax) }}</td>
										<td data-title="purchase_order_total">{{ numberWithCommas(row.invoice_item_total) }}</td>
										<td>
											<button type="button" @click="removeInvoiceItem(row)" class="ml-2 btn btn-secondary">
												<i class="bi bi-x"></i></button>
										</td>
									</tr>
									</tbody>
								</table>
								<!-- /Contact Table -->
							</div>

							<div class="clearfix mb-3 mt-3">
								<button type="button" class="btn btn-outline-secondary float-start" @click="showItemForm = !showItemForm">
									<i class="bi bi-plus-circle-fill"></i>&nbsp; add new item
								</button>
							</div>

							<!-- New Form-->
							<div class="mb-3">
								<div v-if="showItemForm">
									<div class="row mb-3">
										<div class="col-12">
											<label class="form-label">Product Item:</label>
											<div class="float-end" @click="insertProductTypingActive = !insertProductTypingActive">
												<a v-if="!insertProductTypingActive" class="btn btn-info text-white">
													<i class="bi bi-pencil"></i>&nbsp; Type a new product
												</a>
												<a v-else class="btn btn-info text-white">Cancel</a>
											</div>

											<select v-if="!insertProductTypingActive" class="form-control"
													@change="updateWorkingInvoiceItenDataField('productsID', $event.target.value)">
												<option :value="null" disabled selected> -- select product --</option>
												<option
													v-for="item, index in productList"
													:key="index"
													:value="item.productsID"
													:selected="item.productsID == workingInvoiceItemData.productsID"
												>
													{{ item.product_name }}
												</option>
												<option>
													<button type="button" class="btn btn-success">Input custom product</button>
												</option>
											</select>
											<input v-else type="text" placeholder="Type a product" class="form-control" v-model="workingInvoiceItemData.product_custom_name" v-on:keyup.stop="updateWorkingInvoiceItenDataField('product_custom_name', $event.target.value)"/>
											<span class="text-danger">{{ errors.productError }}</span>
										</div>
									</div>

									<div class="mb-3">
										<label class="form-label">Item Note</label>
										<textarea v-model="workingInvoiceItemData.invoice_item_note" placeholder="Item Note" class="form-control"
												  @keyup.stop="updateWorkingInvoiceItenDataField('invoice_item_note', $event.target.value)"
										></textarea>
									</div>

									<div class="row mt-3 mb-3">
										<div class="col-12 col-md-6 mb-3">
											<label class="form-label">Price:</label>
											<div class="input-group">
												<input type="number" v-model="workingInvoiceItemData.invoice_item_price" class="form-control" placeholder="0" min="1"
													   v-on:keyup.stop="updateWorkingInvoiceItenDataField('invoice_item_price', $event.target.value)" autocomplete="off">
											</div>
										</div>

										<div class="col-12 col-md-6 mb-3">
											<label class="form-label">Tax:</label>
											<div class="input-group">
												<input type="number" v-model="workingInvoiceItemData.invoice_item_tax" class="form-control" placeholder="0" min="1"
													   v-on:keyup.stop="updateWorkingInvoiceItenDataField('invoice_item_tax', $event.target.value)" autocomplete="off">
											</div>
										</div>

										<div class="col-12 col-md-6 mb-3">
											<label class="form-label">Quantity:</label>
											<div class="input-group">
												<input type="number" v-model="workingInvoiceItemData.invoice_item_quantity" class="form-control" placeholder="0" min="1"
													   @keyup.stop="updateWorkingInvoiceItenDataField('invoice_item_quantity', $event.target.value)" autocomplete="off">
												<button @click="addNewInvoiceItem" class="btn btn-outline-secondary" type="button">Add Iten</button>
											</div>
										</div>
									</div>
									<!-- /New Form-->
								</div>

								<div v-if="invoiceItemList.length">
									<div class="alert alert-warning clearfix">
										<div class="float-start">
											<h5 class="text-start">
												Total:
											</h5>
										</div>
										<div class="float-end">
											<ul class="text-end list-group">
												<li class="list-group-item">Sub Total&nbsp;&nbsp;
													<strong>{{ numberWithCommas(invoiceItemSubTotal) }}</strong>
												</li>
												<li class="list-group-item">Tax&nbsp;&nbsp;
													<strong>{{ numberWithCommas(invoiceItemTax) }}</strong>
												</li>
												<li class="list-group-item">Total&nbsp;&nbsp;
													<strong>{{ numberWithCommas(invoiceItemGrandTotal) }}</strong>
												</li>
											</ul>
										</div>
									</div>
									<div class="float-end" @click="submitInvoiceMainForm()">
										<SubmitButton :submitting="submitting" label="Save Invoice" label_action="Saving Invoice"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<EIJAssetForm
					asset-parent-type="invoice"
					:asset-i-d=invoiceID
					@new-asset-uploaded="newAssetUploaded"
				/>

				<EIJAssetGallery
					:assets="workingInvoiceFileAssets"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import InvoiceForm from '@/components/Sales/InvoiceForm';
import SubmitButton from '@/components/Utilities/SubmitButton'
import EIJAssetForm from '@/components/EIJ_Assets/EIJAssetForm';
import EIJAssetGallery from "@/components/EIJ_Assets/EIJAssetGallery";
// Services
import invoiceService from '@/service/InvoiceService';
import invoiceItemService from '@/service/InvoiceItemService';
import productService from '@/service/ProductService';

export default {
	name: "InvoiceDetailView",
	components: {
		InvoiceForm,
		SubmitButton,
		EIJAssetForm,
		EIJAssetGallery
	},
	data() {
		return {
			errors: {},
			invoiceID: null,
			invoiceItemListLoading: false,
			invoiceItemList: [],
			productList: [],
			pulledWorkingInvoiceData: {},
			workingInvoiceItemData: {},
			workingInvoiceFileAssets: {},
			showItemForm: false,
			submitting: false,
			insertProductTypingActive: false,
		}
	},
	computed: {
		invoiceItemSubTotal() {
			if (this.invoiceItemList) {
				return this.invoiceItemList.reduce(function (sum, current) {
					let total = parseFloat(+sum) + +parseFloat(current.invoice_item_price);
					return (Math.round(total).toFixed(2));
				}, 0);
			} else {
				return 0.00;
			}
		},
		invoiceItemTax() {
			if (this.invoiceItemList) {
				return this.invoiceItemList.reduce(function (sum, current) {
					let total = parseFloat(+sum) + +parseFloat(current.invoice_item_tax);
					return (Math.round(total).toFixed(2));
				}, 0);
			} else {
				return 0.00;
			}
		},
		invoiceItemGrandTotal() {
			if (this.invoiceItemList) {
				return this.invoiceItemList.reduce(function (sum, current) {
					let total = parseFloat(+sum) + +parseFloat(current.invoice_item_total);
					return (Math.round(total).toFixed(2));
				}, 0);
			} else {
				return 0.00;
			}
		},
	},
	methods: {
		updateWorkingInvoiceDataField(field, value) {
			this.workingInvoiceItemData[field] = value;
		},
		updateWorkingInvoiceItenDataField(field, value) {
			this.workingInvoiceItemData[field] = value;
		},
		getInvoiceByID() {
			this.vendorID = this.$route.params.ID;
			new Promise((resolve, reject) => {
				invoiceService.getByID(this.vendorID).then((response) => {
					if (response.data.status === 200) {
						this.pulledWorkingInvoiceData = response.data.record;
						this.invoiceItemList = response.data.record.invoice_items;
						this.workingInvoiceFileAssets = response.data.record.fileAssets;
						resolve();
					} else {
						this.createToast('Error', response.data.message, 'danger');
						reject(response.data.message);
					}
				}, (error) => {
					this.createToast('Error', 'Action failed!', 'danger');
					reject(error);
				});
			});
		},
		addNewInvoiceItem() {
			let workingData = this.workingInvoiceItemData;
			if ((!workingData.productsID) && (!workingData.product_custom_name)) {
				this.errors.productError = "Product is required!";
			}

			if (Object.keys(this.errors).length) {
				return;
			}

			if (this.insertProductTypingActive) {
				this.updateWorkingInvoiceItenDataField('productsID', null);
			} else {
				this.updateWorkingInvoiceItenDataField('product_custom_name', null);
			}

			let invoiceID = this.$route.params.ID,
				itemTotal = (+workingData.invoice_item_price + +workingData.invoice_item_tax);

			this.updateWorkingInvoiceItenDataField('invoiceID', parseInt(invoiceID));
			this.updateWorkingInvoiceItenDataField('invoice_item_total', parseInt(itemTotal));

			return new Promise((resolve, reject) => {
				if (this.workingInvoiceItemData.invoiceID) {
					invoiceItemService.create(this.workingInvoiceItemData).then((response) => {
						if (response.data.status === 200) {
							let data = response.data.record;
							if (!this.invoiceItemList) {
								this.invoiceItemList = [data];
							} else {
								this.invoiceItemList.push(data);
							}
							this.showItemForm = false;
							this.clearworkingInvoiceItemData();
							this.createToast('Success', 'Invoice item successfully added!', 'success');
							resolve();
						} else {
							this.createToast('Error', response.data.message, 'danger');
							reject(response.data.message);
						}
					}, (error) => {
						this.createToast('Error', 'Action failed!', 'danger');
						reject(error);
					});
				} else {
					this.createToast('Error', 'Action failed!', 'danger');
					reject('No Job Data to Send');
				}
			});
		},
		getProductList() {
			return new Promise((resolve, reject) => {
				productService.getAll().then((response) => {
					if (response.data.status === 200) {
						this.productList = response.data.record;
						resolve();
					} else {
						this.createToast('Error', response.data.message, 'danger');
						reject(response.data.message);
					}
				}, (error) => {
					this.createToast('Error', 'Action failed!', 'danger');
					reject(error);
				});
			});
		},
		clearworkingInvoiceItemData() {
			this.workingInvoiceItemData = {};
		},
		removeInvoiceItem(data) {
			return new Promise((resolve, reject) => {
				invoiceItemService.delete(data.invoice_itemID).then((response) => {
					const index = this.invoiceItemList.map(x => {
						return x.invoice_itemID;
					}).indexOf(data.invoice_itemID);
					if (index !== -1) {
						this.invoiceItemList.splice(index, 1);
					}
					this.createToast('Success', 'Invoice item successfully deleted!', 'success');
					resolve();
				}, (error) => {
					this.createToast('Error', 'Action failed!', 'danger');
					reject(error);
				});
			});
		},
		numberWithCommas(x) {
			return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		invoiceUpdated() {
			return false;
		},
		submitInvoiceMainForm() {
			this.submitting = true;
			let data = this.pulledWorkingInvoiceData;

			data.invoice_sub_total = this.invoiceItemSubTotal;
			data.invoice_sales_tax = this.invoiceItemTax;
			data.invoice_total = this.invoiceItemGrandTotal;

			return new Promise((resolve, reject) => {
				invoiceService.update(data.invoiceID, data).then((response) => {
					this.submitting = false;
					if (response.data.status === 200) {
						this.createToast('Success', 'Invoice successfully added!', 'success');
						this.$router.push({name: 'SalesView'});
						resolve(response);
					} else {
						this.createToast('Error', response.data.message, 'danger');
						reject(response.data.message);
					}
				}, (error) => {
					this.submitting = false;
					this.createToast('Error', 'Action failed!', 'danger');
					reject(error);
				});
			});
		},
		newAssetUploaded(record) {
			this.workingInvoiceFileAssets.push(record);
		}
	},
	mounted() {
		this.getInvoiceByID();
		this.getProductList();
		this.invoiceID = this.$route.params.ID;
	}
};
</script>
