import Vuex from 'vuex';
import * as user from './modules/UserStore';
import * as company from './modules/CompanyStore';
import * as location from './modules/LocationStore';

import * as todo from './modules/TodoStore';

export default Vuex.createStore({
  modules:{
    user,
    company,
    location,
    todo,
  },
  state: {
    sideBarOpen: false
  },
  getters: {
    sideBarOpen: state => {
      return state.sideBarOpen
    }
  },
  mutations: {
    toggleSidebar (state) {
      state.sideBarOpen = !state.sideBarOpen
    }
  },
  actions: {
    toggleSidebar (context) {
      context.commit('toggleSidebar')
    }
  }
});
