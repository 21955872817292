<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Employees</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="card card-body">
                    <!-- Search -->
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Employee" v-model="searchTerm" @keyup="searchEmployee" />
                    </div>
                    <!-- /Search -->

                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Contact</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="employeePaginatedList.length">
                            <tr v-for="row in employeePaginatedList" v-bind:key="row.employeeID">
                                <th scope="row">{{row.employee_first_name}} {{row.employee_last_name}} </th>
                                <td data-title="employee_address">{{row.employee_street}} <br> {{row.employee_city}} {{row.employee_state}}, {{row.employee_zip}}</td>
                                <td data-title="customer_contact">
                                    <a href="mailto: {{row.employee_email}}">{{row.employee_email}}</a>
                                    <br>
                                    <a href="tel:{{row.employee_phone1}}">{{row.employee_phone1}}</a>
                                    <br>
                                    <a href="tel:{{row.employee_phone2}}">{{row.employee_phone2}}</a>
                                </td>
                                <td data-title="actions">
                                    <router-link :to="{ name: 'EmployeeDetailView', params: {ID: row.employeeID}}" class="btn">
                                        <i class="bi bi-eye"></i> view/edit </router-link>
                                    <button type="button" v-on:click="removeEmployee(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning text-center">No employees...</div>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="employee_last_name"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <ul v-if="newlyAddedEmployee">
                            <li v-for="row in newlyAddedEmployee" :key="row.employeesID">
                                <router-link :to="{ name: 'EmployeeDetailView', params: {ID: row.employeeID}}" class="btn">{{row.employee_first_name}} {{row.employee_last_name}}<span v-if="row"> - {{row.employee_last_name}}</span></router-link>
                            </li>
                        </ul>

                        <!-- Employee Form -->
                        <button type="button" class="btn btn-outline-secondary mb-3" @click="showEmployeeForm = !showEmployeeForm">
                            <i class="bi bi-plus-circle-fill"></i>&nbsp; add employee</button>
                        <transition name="fade">
                            <div v-if="showEmployeeForm" class="card">
                                <div class="card-header">
                                    <h6>
                                        Add Employee Form
                                    </h6>
                                    <button class="btn-close" aria-label="Close" type="button" @click="showEmployeeForm = !showEmployeeForm"></button>
                                </div>
                                <div class="card-body">
                                    <EmployeeForm
                                        @employeeAdded="employeeAdded"
                                    />
                                </div>
                            </div>
                        </transition>
                        <!-- /Employee Form -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import EmployeeForm from '@/components/Employees/EmployeeForm.vue';
// Services
import employeeService from '@/service/EmployeeService';
    export default {
        name: "EmployeesView",
        components: {
            EmployeeForm, PaginationButtons
        },
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'employee_last_name', 'orderDirection':'ASC'},
                currentPage: 1,
                showEmployeeForm: false,
                searchTerm: '',
                newlyAddedEmployee: [],
                employeePaginatedList: [],
                recordCount: 0
            };
        },
        methods: {
            pullEmployeeList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    employeeService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.employeePaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullEmployeeList('hasPageStart', pagStart)
            },
            searchEmployee(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Employee', searchMethod: 'searchByNames', term: this.searchTerm};
                        employeeService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.employeePaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                           }
                           else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                           }
                       }, (error) => {
                           this.createToast('Error', 'Action failed!', 'danger');
                           reject(error);
                       });
                    });
                } else{
                        this.pullEmployeeList();
                }
            },
            removeEmployee(employee){
                if(confirm(`Are you sure you want to remove this employee ${employee.employee_last_name}?`)){
                    new Promise((resolve, reject) => {
                    employeeService.delete(employee.employeeID).then((response) => {
                        const index = this.employeePaginatedList.map(x => {
                            return x.employeeID;
                        }).indexOf(employee.employeeID);
                            if(index !== -1) {
                                this.employeePaginatedList.splice(index, 1);
                                this.clearNewlyAddedEmployees();
                            }
                            this.createToast('Success', 'Employee successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            employeeAdded(data){
                this.pullEmployeeList();
                this.newlyAddedEmployee.push(data);
                this.showEmployeeForm = false;
            },
            clearNewlyAddedEmployees(){
                this.newlyAddedEmployee = [];
            }
        },
        mounted() {
            this.pullEmployeeList();
        }
    };
</script>
