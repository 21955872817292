 <template>
    <form @submit.prevent="submitContactForm">
        <!-- Search Customer -->
        <div class="mb-3" v-if="!inParentLocation && !editing">
            <label class="form-label">Customer Name</label>
                <div v-if="customer.searchIsSet">
                    <div class="input-group mb-2">
                        <input type="text" readonly="true" class="form-control"
                            :value="customer.selected.customer_fname +' '+ customer.selected.customer_lname"
                            v-if="customer.searchIsSet"/>

                        <input type="hidden" :run="!workingContactData.customersID ? workingContactData.customersID = customer.selected.customersID : true" v-model="workingContactData.customersID"/>
                    </div>
                    <a type="button" @click="clearCustomer()" class="btn btn-info text-white">Change Customer</a>
                </div>
                <div v-else>
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Customer Name or Company" v-model="customer.searchTerm"
                        @keyup="searchCustomers" autocomplete="off" />
                    </div>

                    <div class="dropdown-wrapper" v-if="customer.isDropdownOpen">
                        <div v-if="!customer.search_loading">
                            <div v-if="customer.list.length" class="pb-3">
                                <div class="mt-2 list-group">
                                    <a href="#" class="list-group-item list-group-item-action" v-for="item in customer.list"
                                    :key="item.customersID" @click.stop.prevent="selectCustomer(item)">{{ item.customer_fname }} {{item.customer_lname}}</a>
                                </div>
                            </div>
                            <div v-else class="text-danger">
                                <span v-if="!customer.searchIsSet">Customer not found!</span>
                            </div>
                        </div>
                        <div v-else>Loading...</div>
                    </div>
                </div>
            </div>
        <!-- /Search Customer-->

        <div class="mb-3">
            <label class="form-label">First Name</label>
            <input type="text" placeholder="First Name" class="form-control" name="contact_fname" v-model="workingContactData.contact_fname" v-on:keyup.stop="updateWorkingContactDataField('contact_fname', $event.target.value)" required/>
        </div>
        <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input type="text" placeholder="Last Name" class="form-control" name="contact_lname" v-model="workingContactData.contact_lname" v-on:keyup.stop="updateWorkingContactDataField('contact_lname', $event.target.value)" required/>
        </div>
        <div class="mb-3">
            <label class="form-label">Title / Department</label>
            <input type="text" placeholder="Title / Department" class="form-control" name="contact_title" v-model="workingContactData.contact_title" v-on:keyup.stop="updateWorkingContactDataField('contact_title', $event.target.value)"/>
        </div>
        <div class="mb-3">
            <label class="form-label">Street Address</label>
            <input type="text" placeholder="Street Address" class="form-control" name="contact_street" v-on:keyup.stop="updateWorkingContactDataField('contact_street', $event.target.value)" v-model="workingContactData.contact_street" />
        </div>
        <div class="mb-3">
            <label class="form-label">City</label>
            <input type="text" placeholder="City" class="form-control" name="contact_city" v-on:keyup.stop="updateWorkingContactDataField('contact_city', $event.target.value)" v-model="workingContactData.contact_city" />
        </div>
        <div class="mb-3">
            <label class="form-label">State / Province</label>
            <input type="text" placeholder="State / Province" class="form-control" name="contact_state" v-on:keyup.stop="updateWorkingContactDataField('contact_state', $event.target.value)" v-model="workingContactData.contact_state" />
        </div>
        <div class="mb-3">
            <label class="form-label">Postal Code (zip)</label>
            <input type="text" placeholder="Postal Code (zip)" class="form-control" name="contact_zip" v-on:keyup.stop="updateWorkingContactDataField('contact_zip', $event.target.value)" v-model="workingContactData.contact_zip" />
        </div>
        <div class="mb-3">
            <label class="form-label">Main Phone</label>
            <input type="text" placeholder="Main Phone" class="form-control" name="contact_phone" v-on:keyup.stop="updateWorkingContactDataField('contact_phone', $event.target.value)" v-model="workingContactData.contact_phone" />
        </div>
        <div class="mb-3">
            <label class="form-label">Mobile Phone</label>
            <input type="text" placeholder="Mobile Phone" class="form-control" name="contact_cell" v-on:keyup.stop="updateWorkingContactDataField('contact_cell', $event.target.value)" v-model="workingContactData.contact_cell" />
        </div>
        <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="text" placeholder="Email" class="form-control" name="contact_email" v-on:keyup.stop="updateWorkingContactDataField('contact_email', $event.target.value)" v-model="workingContactData.contact_email" />
        </div>
        <div class="mb-3">
            <label class="form-label">Birthday</label>
            <input type="date" placeholder="Birthday" class="form-control" name="contact_birthday" v-on:keyup.stop="updateWorkingContactDataField('contact_birthday', $event.target.value)" v-model="workingContactData.contact_birthday" />
        </div>
        <SubmitButton :submitting="submitting" width="w-100"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import customerService from '@/service/CustomerService';
import contactService from '@/service/ContactService';
    export default {
        name: "ContactForm",
        components: { SubmitButton },
        data: function () {
            return {
                customer: {
                    search_loading: false,
                    isDropdownOpen: false,
                    selected: {},
                    searchIsSet: null,
                    searchTerm: '',
                    list: [],
                },
                submitting: false
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            inParentLocation: {
                type: Boolean,
                required: false,
                default: false,
            },
            parent: {
                type: Object,
                required: false,
                default: null,
            },
            pulledContactWorkingData:  {
                type: Object,
                required: false,
            },
        },
        emits: ['contactAdded', 'contactUpdated'],
        computed: {
            workingContactData(){
                if(this.editing){
                    return this.pulledContactWorkingData;
                } else {
                    return {};
                }
            },
            workingContactParentData(){
                return this.parent;
            }
        },
        methods: {
            updateWorkingContactDataField(field, value){
                this.workingContactData[field] = value;
            },
            submitContactForm(){
                this.submitting = true;
                let data = this.workingContactData;
                if(!this.editing){
                    let parentData = this.workingContactParentData;

                    if(parentData?.parentIDType){
                        if(parentData.parentIDType == 'customersID'){
                           this.updateWorkingContactDataField(parentData.parentIDType, parentData.parentID);
                        }

                        if(parentData.parentIDType == 'vendorsID'){
                           this.updateWorkingContactDataField(parentData.parentIDType, parentData.parentID);
                        }
                    }

                    new Promise((resolve, reject) => {
                        if(data.contact_fname && data.contact_lname){
                            contactService.create(data).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('contactAdded', response.data.record);
                                    this.createToast('Success', 'Contact successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Contact Data to Send');
                        }
                    });
                } else {
                    new Promise((resolve, reject) => {
                        contactService.update(data.contactsID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('contactUpdated');
                                this.createToast('Success', 'Customer successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingContactData = {};
            },
            searchCustomers(){
                this.customer.search_loading = true;
                let payload = {searchClass: 'Customer', searchMethod: 'searchByNames', term: this.customer.searchTerm};
                new Promise((resolve, reject) => {
                    customerService.search(payload).then((response) => {
                        if(response.data.status === 200){
                            this.customer.list = response.data.record;
                            this.customer.search_loading = false;
                            this.customer.isDropdownOpen = true;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            selectCustomer(customer){
                this.customer.searchIsSet = true;
                this.customer.selected = customer;
            },
            clearCustomer(){
                this.customer.searchIsSet = false;
                this.customer.isDropdownOpen = false;
                this.customer.list = [];
                this.customer.searchTerm = '';
            }
        },
    };
</script>
