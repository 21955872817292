<template>
    <form id="productForm" @submit.prevent="submitProductForm">
        <div class="form-group mb-3" v-if="!editing">
            <label>Product Category</label>
            <select name="product_categoryID" v-model="workingProductData.product_categoryID" v-if="productCategories" class="form-control" @change.stop="updateWorkingProductDataField('product_categoryID', $event.target.value)">
                <option value="">Select Category</option>
                <option
                        v-for="productCategory in productCategories"
                        :key="productCategory.product_categoryID"
                        :value="productCategory.product_categoryID">
                    {{ productCategory.product_category_name }}
                </option>
            </select>
        </div>
        <div class="mb-3">
            <label>Product Name</label>
            <input type="text" placeholder="Product Name" name="product_name" class="form-control" v-on:keyup.stop="updateWorkingProductDataField('product_name', $event.target.value)" v-model="workingProductData.product_name"/>
        </div>
        <div class="mb-3">
            <label>Product Description</label>
            <input type="text" placeholder="Product Description" name="product_description" class="form-control" v-on:keyup.stop="updateWorkingProductDataField('product_description', $event.target.value)" v-model="workingProductData.product_description"/>
        </div>
        <div class="mb-3">
            <label>Product Cost</label>
            <input type="text" placeholder="Product Cost" name="product_cost" class="form-control" v-on:keyup.stop="updateWorkingProductDataField('product_cost', $event.target.value)" v-model="workingProductData.product_cost"/>
        </div>
        <div class="mb-3">
            <label>Product Price</label>
            <input type="text" placeholder="Product Price" name="product_price" class="form-control" v-on:keyup.stop="updateWorkingProductDataField('product_price', $event.target.value)" v-model="workingProductData.product_price"/>
        </div>
        <div class="mb-3 form-check">
            <input type="checkbox" name="product_inventory_item" class="form-check-input" @change="updateWorkingProductDataField('product_inventory_item', $event.target.checked)" v-model="workingProductData.product_inventory_item" true-value="1" false-value="0"/>
            <label class="form-check-label">Inventory Item?</label>
        </div>
        <div class="mb-3 form-check">
            <input type="checkbox" name="sales_taxable" class="form-check-input" @change="updateWorkingProductDataField('sales_taxable', $event.target.checked)" v-model="workingProductData.sales_taxable" true-value="1" false-value="0"/>
            <label class="form-check-label">Sales Taxable?</label>
        </div>
        <SubmitButton :submitting="submitting"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton';
// Services
import productService from '@/service/ProductService';
    export default {
        name: "ProductForm",
        components: { SubmitButton },
        data() {
            return {
                submitting: false,
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            productCategories: {
                required: false,
            },
            pulledWorkingProductData: {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['productAdded', 'productUpdated'],
        computed: {
            workingProductData(){
                if(this.editing){
                    return this.pulledWorkingProductData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingProductDataField(field, value){
                this.workingProductData[field] = value;
            },
            submitProductForm(){
                this.submitting = true;
                if(!this.editing){
                    if(!this.workingProductData.productsID){
                        this.setFirstCategory(this.productCategories);
                    }

                    return new Promise((resolve, reject) => {
                        productService.create(this.workingProductData).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('productAdded', response.data.record);
                                this.createToast('Success', 'Product successfully added!', 'success');
                                this.clearFormData();
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
                else{
                     return new Promise((resolve, reject) => {
                        productService.update(this.workingProductData.productsID, this.workingProductData).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('productUpdated');
                                this.createToast('Success', 'Product successfully updated!', 'success');
                                this.clearFormData();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            setFirstCategory(payload){
                if(this.workingProductData.productsID){
                    this.workingProductData.productsID = payload[0].productsID;
                }
            },
            clearFormData(){
                this.workingProductData.product_name = '';
                this.workingProductData.product_description = '';
                this.workingProductData.product_cost = '';
                this.workingProductData.product_price = '';
                this.workingProductData.product_inventory_item = false;
                this.workingProductData.sales_taxable = false;
            }
        },
    };
</script>
