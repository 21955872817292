import http from "../api";

class PurchaseOrderItemService {
    getAll() {
        return http.get("/PurchaseOrderItem");
    }

    getByID(ID) {
        return http.get('/PurchaseOrderItem/'+ID);
    }

    create(data) {
        return http.post("/PurchaseOrderItem", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/PurchaseOrderItem', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/PurchaseOrderItem/', {params: {purchase_order_itemID: id}});
    }
}

export default new PurchaseOrderItemService();