<template>
    <div class="container-fluid app-view-container">
        <div class="row">
            <div class="col-12 app-view-title mt-4">
                <h6>Vendors</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
               <div class="card card-body">
                    <!-- Search -->
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Vendor Company or Name" v-model="searchTerm" @keyup="searchVendors" autocomplete="off" />
                    </div>
                    <!-- /Search -->

                    <!-- Vendor Table -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Person Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="vendorPaginatedList.length" >
                            <tr v-for="row in vendorPaginatedList" v-bind:key="row.vendorsID">
                                <th scope="row" data-title="vendor_company_name">{{row.vendor_company_name}} </th>
                                <td data-title="vendor_name">{{row.vendor_first_name}} {{row.vendor_last_name}}</td>
                                <td data-title="vendors_address">{{row.vendor_street}}, <br> {{row.vendor_city}} {{row.vendor_state}}, {{row.vendor_zip}}</td>
                                <td data-title="vendors_contact">
                                    <a href="mailto: {{row.vendors_email}}">{{row.vendor_email}}</a>
                                    <br>
                                    <a href="tel:{{row.vendor_phone1}}">{{row.vendor_phone1}}</a>
                                    <br>
                                    <a href="tel:{{row.vendor_phone2}}">{{row.vendor_phone2}}</a>
                                    <br>
                                    <a :href='row.vendor_website'>{{row.vendor_website}}</a>
                                </td>
                                <td data-title="actions">
                                    <router-link :to="{ name: 'VendorDetailView', params: {ID: row.vendorsID}}" class="btn"><i class="bi bi-eye"></i> view/edit</router-link>
                                    <button type="button" v-on:click="removeVendor(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning text-center">No Vendors...</div>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="vendor_last_name"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <!-- /Vendor Table -->

                </div>
            </div>

            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <ul v-if="newlyAddedVendors">
                            <li v-for="newVendor in newlyAddedVendors" :key="newVendor.vendorsID">
                                <router-link :to="{ name: 'VendorDetailView', params: {ID: newVendor.vendorsID}}" class="btn">{{newVendor.vendor_first_name}}</router-link>
                            </li>
                        </ul>

                        <!-- Vendor Form -->
                        <button type="button" class="btn mb-2 btn-outline-secondary" v-on:click="showVendorForm = !showVendorForm"><i class="bi bi-plus-circle-fill"></i>&nbsp; add new vendor</button>
                        <transition name="fade">
                            <div v-if="showVendorForm" class="card mt-3">
                                <div class="card-header">
                                    <h6>
                                        Add Vendor Form
                                    </h6>
                                    <button class="btn-close" aria-label="Close" type="button" v-on:click="showVendorForm = !showVendorForm"></button>
                                </div>
                                <div class="card-body">
                                    <VendorForm
                                        @vendorAdded="vendorAdded"
                                    />
                                </div>
                            </div>
                        </transition>
                        <!-- /Vendor Form -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import VendorForm from '@/components/Vendors/VendorForm';
// Services
import vendorService from '@/service/VendorService';
    export default {
        name: "VendorsView",
        components: {
            PaginationButtons,
            VendorForm
        },
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'vendor_company_name', 'orderDirection':'ASC'},
                currentPage: 1,
                showVendorForm: false,
                searchTerm: '',
                newlyAddedVendors: [],
                vendorPaginatedList: [],
                recordCount: 0
            };
        },
        methods: {
            pullVendorList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    vendorService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.vendorPaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullVendorList('hasPageStart', pagStart)
            },
            searchVendors(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Vendor', searchMethod: 'searchByNames', term: this.searchTerm};
                        vendorService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.vendorPaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                } else{
                    this.pullVendorList();
                }
            },
            removeVendor(vendor){
                if(confirm(`Are you sure you want to remove this vendor ${vendor.vendor_last_name}?`)){
                    new Promise((resolve, reject) => {
                    vendorService.delete(vendor.vendorID).then((response) => {
                        const index = this.vendorPaginatedList.map(x => {
                            return x.vendorID;
                        }).indexOf(vendor.vendorID);
                            if(index !== -1) {
                                this.vendorPaginatedList.splice(index, 1);
                                this.clearNewlyAddedVendors();
                            }
                            this.createToast('Success', 'Vendor successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            vendorAdded(data){
                this.pullVendorList();
                this.newlyAddedVendors.push(data);
                this.showVendorForm = false;
            },
            clearNewlyAddedVendors(){
                this.newlyAddedVendors = [];
            }
        },
        mounted() {
            this.pullVendorList();
        }
    };
</script>
