import http from "../api";

class LocationService {
    getAll() {
        return http.get("/Location");
    }

    get(id) {
        return http.get('/Location/'+id);
    }

    create(data) {
        return http.post("/Location", data);
    }

    update(id, data) {
        return http.patch('/Location', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Location/'+id);
    }

}

export default new LocationService();
