import http from "../api";

class EstimateService {
    /**
     * Get Estimate with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Estimate", {params: parameters});
    }

    getByID(ID) {
        return http.get('/Estimate/'+ID);
    }
    
    create(data) {
        return http.post("/Estimate", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Estimate', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Estimate/', {params: {estimateID: id}});
    }
}

export default new EstimateService();