<template>
    <div class="container-fluid app-view-containe mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Purchase Order Detail</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <div class="card-body">
                    <PurchaseOrderForm
                        :pulledWorkingPurchseOrderData="pulledWorkingPurchseOrderData"
                        :editing="true"
                    />
                    <div class="mt-3">
                        <div v-if="!purchaseOrderItemList.length" class="mt-3">
                            <div v-if="!purchaseOrderItemList.length && !purchaseOrderItemListLoading"></div>
                            <div v-else-if="!purchaseOrderItemList.length && purchaseOrderItemListLoading">Loading...</div>
                            <div v-else class="text-danger">Error...</div>
                        </div>
                        <div v-else>
                            <!-- Contact Table -->
                            <h4>Purchase Order Item(s)</h4><hr>
                            <table class="table responsive-table">
                                <thead>
                                <tr>
                                    <th scope="col">Item Name</th>
                                    <th scope="col">Item Text</th>
                                    <th scope="col" class="text-center">Quantity</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="row, index in purchaseOrderItemList" :key="index">
                                    <th scope="row">{{row.supply_item.supply_item_name}} </th>
                                    <td data-title="supply_item_text" class="text-center">{{row.supply_item.supply_item_desc}}</td>
                                    <td data-title="purchase_order_item_qty" class="text-center">{{row.purchase_order_item_qty}}
                                    </td>
                                    <td data-title="purchase_order_item_cost">{{numberWithCommas(row.purchase_order_item_cost)}}</td>
                                    <td data-title="purchase_order_total">{{numberWithCommas(purchaseOderTotal(row.purchase_order_item_qty, row.purchase_order_item_cost))}}</td>
                                    <td>
                                        <button type="button" @click="removePurchaseOrderItem(row)" class="ml-2 btn btn-secondary"><i class="bi bi-x"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>

                                </tr>
                                </tfoot>
                            </table>
                            <!-- /Contact Table -->
                        </div>

                        <div class="clearfix mb-3 mt-3">
                            <button type="button" class="btn btn-outline-secondary float-start" @click="showPOItemForm = !showPOItemForm"><i class="bi bi-plus-circle-fill"></i>&nbsp;  add new item</button>
                        </div>

                        <!-- New Form-->
                        <div class="mb-3">
                            <div v-if="showPOItemForm">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">Select Supply Item:</label>
                                        <select class="form-control" v-model="workingPurchaseOrderItemData.supply_itemID"
                                            @change="updateWorkingPurachseOrderItenDataField('supply_itemID', $event.target.value)"
                                            @click="updateWorkingPurachseOrderItenDataField('supply_item_text')">
                                            <option :value="null" disabled selected> -- select supply name -- </option>
                                            <option
                                                v-for="item in supplyItem.list"
                                                :key="item.supply_itemID"
                                                :value="parseInt(item.supply_itemID)"
                                                :selected= "item.supply_itemID == workingPurchaseOrderItemData.supply_itemID"
                                                ref="supplyItem"
                                                :supply_item_text="item.supply_item_desc"
                                                >
                                                {{ item.supply_item_name }}
                                            </option>
                                        </select>
                                        <span class="text-danger">{{ errors.supply_itemIDError }}</span>
                                    </div>
                                </div>

                                <!-- Search Job -->
                                <div class="form-group mb-3">
                                    <label for="staticEmail" class="col-form-label">Search Job</label>
                                    <div class="">
                                        <div v-if="job.searchIsSet">
                                            <div class="input-group mb-2">
                                                <input type="text" readonly="true" class="form-control"
                                                    :value="job.selected.job_name"
                                                    v-if="job.searchIsSet"/>

                                                <input type="hidden" :run="!workingPurchaseOrderItemData.jobID ? workingPurchaseOrderItemData.jobID = job.selected.jobID : true" v-model="workingPurchaseOrderItemData.jobID"/>
                                            </div>
                                            <button @click="clearJobList" class="btn btn-info text-white">Change Job</button>
                                        </div>
                                        <div v-else>
                                            <div class="input-group mb-2">
                                                <input type="text" class="form-control" placeholder="Search by Job Name or Company" v-model="job.searchTerm" @keyup="searchJobs" />
                                            </div>
                                            <div class="dropdown-wrapper" v-if="job.isDropdownOpen">
                                                <div v-if="!job.search_loading">
                                                    <div v-if="job.list.length" class="pb-3">
                                                        <div class="mt-2 list-group">
                                                            <a href="#" class="list-group-item list-group-item-action" v-for="item in job.list"
                                                                :key="item.jobID" @click.stop.prevent="selectJob(item)">
                                                                <p ref="job" :job_text="item.job_text" @click="updateWorkingPurachseOrderItenDataField('job_text')">
                                                                    {{ item?.job_name }}
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-danger">
                                                        <span v-if="!job.searchIsSet">Job not found!</span>
                                                    </div>
                                                </div>
                                                <div v-else>Loading...</div>
                                            </div>
                                            <div v-else>
                                                <span class="text-danger">{{ errors.jobError }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Search Job-->

                                <div class="row mt-3">
                                    <div class="col-12 col-md-6">
                                        <label class="form-label">Coast:</label>
                                        <div class="input-group">
                                            <input type="number" v-model="workingPurchaseOrderItemData.purchase_order_item_cost" class="form-control" placeholder="0" min="1"
                                            v-on:keyup.stop="updateWorkingPurachseOrderItenDataField('purchase_order_item_cost', $event.target.value)" autocomplete="off">
                                        </div>
                                        <span class="text-danger">{{ errors.costError }}</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <label class="form-label">Quantity:</label>
                                        <div class="input-group">
                                            <input type="number" v-model="workingPurchaseOrderItemData.purchase_order_item_qty" class="form-control" placeholder="0" min="1"
                                            v-on:keyup.stop="updateWorkingPurachseOrderItenDataField('purchase_order_item_qty', $event.target.value)" autocomplete="off">
                                            <button @click="addNewPurchaseOrderItem" class="btn btn-outline-secondary" type="button">Add Iten</button>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /New Form-->

                        <div v-if="purchaseOrderItemList.length">
                            <div class="alert alert-warning clearfix">
                                <div class="float-start">
                                   <h5 class="text-start">
                                        Total:
                                   </h5>
                                </div>
                                <div class="float-end">
                                    <ul class="text-end list-group">
                                        <li class="list-group-item">Subtotal&nbsp;&nbsp; <strong>{{numberWithCommas(purchaseOrderItemSubTotal)}}</strong></li>
                                        <li class="list-group-item">VAT (10%)&nbsp;&nbsp;  <strong>{{numberWithCommas(purchaseOrderItemTax)}}</strong></li>
                                        <li class="list-group-item">Total&nbsp;&nbsp; <strong>{{numberWithCommas(purchaseOrderItemGrandTotal)}}</strong></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="float-end" @click="submitPurchaseOrderMainForm()">
                                <SubmitButton :submitting="submitting" label="Save Purchase Order" label_action="Saving Purchase Order" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PurchaseOrderForm from '@/components/Purchasing/PurchaseOrderForm';
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import supplyItemService from '@/service/SupplyItemService';
import purchaseOrderService from '@/service/PurchaseOrderService';
import purchaseOrderItemService from '@/service/PurchaseOrderItemService';
import jobService from '@/service/JobService';
    export default {
        name: "PurchaseOrderCreateView",
        components: {
            PurchaseOrderForm, SubmitButton
        },
        data(){
            return{
                supplyItem: {
                    supply_ItemID: 0,
                    list: [],
                },
                job: {
                    search_loading: false,
                    isDropdownOpen: false,
                    selected: null,
                    searchIsSet: false,
                    searchTerm: '',
                },
                errors: {},
                purchaseOrderItemList: [],
                purchaseOrderItemListLoading: false,
                pulledWorkingPurchseOrderData: {},
                workingPurchaseOrderItemData: {},
                showPOItemForm: false,
                submitting: false
            }
        },
        computed: {
            purchaseOrderItemSubTotal(){
                if(this.purchaseOrderItemList){
                        return this.purchaseOrderItemList.reduce(function(sum, current){
                        let total = parseFloat(+sum) + +parseFloat(+current.purchase_order_item_cost * +current.purchase_order_item_qty);
                            return (Math.round(total).toFixed(2));
                        }, 0);
                    } else {
                        return 0.00;
                    }
                },
            purchaseOrderItemTax(){
                let sub = this.purchaseOrderItemSubTotal,
                net = ((sub * .10)); // This is a preset for 10 percent tax

                return (Math.round(net).toFixed(2));
            },
            purchaseOrderItemGrandTotal(){
                let sub = this.purchaseOrderItemSubTotal,
                tax = this.purchaseOrderItemTax; // This is a preset for 10 percent tax

                return (Math.round(+sub + +tax).toFixed(2));
            },
        },
        methods: {
            updateWorkingInvoiceDataField(field, value){
                this.workingPurchaseOrderData[field] = value;
            },
            updateWorkingPurachseOrderItenDataField(field, value){
                this.workingPurchaseOrderItemData[field] = value;
            },
            getPurchaseOrderByID(){
                this.vendorID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    purchaseOrderService.getByID(this.vendorID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingPurchseOrderData = response.data.record;
                           this.purchaseOrderItemList = response.data.record.purchase_order_items;
                           resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            addNewPurchaseOrderItem(){
                let purchase_orderID = this.$route.params.ID;
                this.updateWorkingPurachseOrderItenDataField('purchase_orderID', parseInt(purchase_orderID));

                return new Promise((resolve, reject) => {
                    if(this.workingPurchaseOrderItemData.purchase_orderID){
                        purchaseOrderItemService.create(this.workingPurchaseOrderItemData).then((response) => {
                            if(response.data.status === 200){
                                let data = response.data.record;
                                if(!this.purchaseOrderItemList){
                                    this.purchaseOrderItemList = [data];
                                } else {
                                    this.purchaseOrderItemList.push(data);
                                }

                                this.showPOItemForm = false;
                                this.clearWorkingPurchaseOrderItemData();
                                this.clearWorkingJobData();
                                this.createToast('Success', 'Purchase order successfully added!', 'success');
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    }
                    else{
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject('No Job Data to Send');
                    }
                });
            },
            getSupplyList(){
                return new Promise((resolve, reject) => {
                    supplyItemService.getAll().then((response) => {
                        if(response.data.status === 200){
                            this.supplyItem.list = response.data.record;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            clearWorkingPurchaseOrderItemData(){
                this.workingPurchaseOrderItemData = {};
            },
            removePurchaseOrderItem(data){
                return new Promise((resolve, reject) => {
                   purchaseOrderItemService.delete(data.purchase_order_itemID).then((response) => {
                       const index = this.purchaseOrderItemList.map(x => {
                            return x.purchase_order_itemID ;
                        }).indexOf(data.purchase_order_itemID );
                        if(index !== -1) {
                            this.purchaseOrderItemList.splice(index, 1);
                        }

                        this.createToast('Success', 'Purchase order successfully deleted!', 'success');
                        resolve();
                   }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                   });
                });
            },
            invoiceUpdated(){
                return false;
            },
            searchJobs(){
                this.job.search_loading = true;
                this.job.isDropdownOpen = true;
                let payload = {searchClass: 'Job', searchMethod: 'searchByNames', term: this.job.searchTerm};
                new Promise((resolve, reject) => {
                    jobService.search(payload).then((response) => {
                        if(response.data.status === 200){
                            this.job.search_loading = false;
                            this.job.list = response.data.record;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                   });
                });
            },
            selectJob(job){
                this.job.searchIsSet = true;
                this.job.selected = job;
            },
            clearWorkingJobData(){
                this.job.selected = {};
                this.job.searchIsSet = false;
                this.job.list = [];
                this.job.searchTerm = '';
                this.job.isDropdownOpen = false;
            },
            submitPurchaseOrderMainForm(){
                this.submitting = true;
                let data = this.pulledWorkingPurchseOrderData;
                data.purchase_order_sub_total = this.purchaseOrderItemSubTotal;
                data.purchase_order_tax = this.purchaseOrderItemTax;
                data.purchase_order_total = this.purchaseOrderItemGrandTotal;

                return new Promise((resolve, reject) => {
                    purchaseOrderService.update(data.purchase_orderID, data).then((response) => {
                        this.submitting = false;
                        if(response.data.status === 200){
                            this.createToast('Success', 'Invoice successfully updated!', 'success');
                            this.$router.push({name: 'PurchaseOrderView'});
                            resolve(response);
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.submitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            purchaseOderTotal(qty, cost){
                return (Math.round(qty * cost).toFixed(2));
            },
        },
        mounted(){
            this.getPurchaseOrderByID();
            this.getSupplyList();
        }
    };
</script>
