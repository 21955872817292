<template>
    <div class="container-fluid app-view-containe mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Purchase Order</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <div class="card mb-5">
                    <div class="card-body">
                        <!-- Contact Table -->
                        <table class="table responsive-table">
                            <thead>
                                <tr>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">Date Sent</th>
                                    <th scope="col">Sub Total</th>
                                    <th scope="col">Tax</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="purchaseOrderPaginatedList.length">
                                <tr v-for="row, index in purchaseOrderPaginatedList" :key="index">
                                    <th scope="row">{{row.vendor.vendor_company_name}}</th>
                                    <td data-title="purchase_order_sent">{{row.purchase_order_sent}} </td>
                                    <td data-title="purchase_order_sub_total">{{numberWithCommas(row.purchase_order_sub_total)}}</td>
                                    <td data-title="purchase_order_tax">{{numberWithCommas(row.purchase_order_tax)}}</td>
                                    <td data-title="purchase_order_total"><strong>{{numberWithCommas(row.purchase_order_total)}}</strong></td>
                                    <td data-title="actions">
                                        <router-link :to="{ name: 'PurchaseOrderDetailView', params: {ID: row.purchase_orderID}}" class="btn">
                                            <i class="bi bi-eye"></i> View/edit
                                        </router-link>
                                        <button type="button" @click="removePurchaseOrder(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning">No Purchase Orders Found</div>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="purchase_orderID"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <!-- /Contact Table -->
                    </div>
                </div>
            </div>
            <div class="col-3">
                <router-link :to="{ name: 'PurchaseOrderCreateView'}" class="btn btn-success">Create New Purchase Order</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
// Services
import purchaseOrderService from '@/service/PurchaseOrderService';
    export default {
        name: "PurchaseOrderCreateView",
        components: {
            PaginationButtons
        },
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'purchase_orderID', 'orderDirection':'ASC'},
                currentPage: 1,
                showPurchaseOrderForm: false,
                searchTerm: '',
                newlyAddedPurchaseOrders: [],
                purchaseOrderPaginatedList: [],
                recordCount: 0
            }
        },
        methods: {
            pullPurchaseOrderList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    purchaseOrderService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.purchaseOrderPaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullPurchaseOrderList('hasPageStart', pagStart)
            },
            searchPurchaseOrders(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'PurchaseOrder', searchMethod: 'searchByNames', term: this.searchTerm};
                        purchaseOrderService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.purchaseOrderPaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                } else{
                    this.pullPurchaseOrderList();
                }
            },
            removePurchaseOrder(purchaseOrder){
                if(confirm(`Are you sure you want to remove this purchaseOrder?`)){
                    new Promise((resolve, reject) => {
                    purchaseOrderService.delete(purchaseOrder.purchase_orderID).then((response) => {
                        const index = this.purchaseOrderPaginatedList.map(x => {
                            return x.purchase_orderID;
                        }).indexOf(purchaseOrder.purchase_orderID);
                            if(index !== -1) {
                                this.purchaseOrderPaginatedList.splice(index, 1);
                            }
                            this.createToast('Success', 'Purchase order successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            purchaseOrderAdded(contactData){
                this.pullPurchaseOrderList();
                this.newlyAddedPurchaseOrders.push(contactData);
                this.showPurchaseOrderForm = false;
            },
        },
        mounted() {
            this.pullPurchaseOrderList();
        }
    };
</script>
