import todoService from '../../service/TodoService';
export const namespaced = true;

export const state = {
    todoData: []
};

export const getters = {
    todoData: state => {
        return state.todoData
    },
};

export const mutations = {
    updateTodoData(state, todoData){
        state.todoData = todoData
    },
    addTodoItem(state, newTodoItem){
        state.todoData.push(newTodoItem);
    },
    updateTodoItem(state, todoItem){
        state.todoData[todoItem.todo_status] = todoItem;
    },
    removeTodoItem(state, todoItemID){
        const itemToRemoveIndex = state.todoData.findIndex(function(item) {
            return item.todoID === todoItemID;
        });

        // proceed to remove an item only if it exists.
        if(itemToRemoveIndex !== -1){
            state.todoData.splice(itemToRemoveIndex, 1);
        }
    },
    updateTodoOrder(state, payload){
        this.todoData = payload;
    }
};

export const actions = {
    setTodoDataForUser({commit, state}){
        return new Promise((resolve, reject) => {
            todoService.getAllByUser().then((response) => {
                if(response.data.status === 200){
                    commit('updateTodoData', response.data.record);
                    resolve();
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    createUserTodoItem({commit, state}, payload){
        return new Promise((resolve, reject) => {
            todoService.create(payload).then((response) => {
                if(response.data.status === 200){
                    commit('addTodoItem', response.data.record);
                    resolve();
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateUserTodoItem({commit, state}, payload){
        return new Promise((resolve, reject) => {
            todoService.updateUserTodoItem(payload).then((response) => {
                if(response.data.status === 200){
                    commit("updateTodoItem", response.data.record);
                    resolve(response);
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateSortedTodoItem({commit, state}, payload){
        return new Promise((resolve, reject) => {
            todoService.updateUserTodoItem(payload).then((response) => {
                if(response.data.status === 200){
                    resolve(response);
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateUserTodoOrder({commit}, payload){
        commit("updateTodoData", payload);
    },
    deleteUserTodoItem({commit, state}, payload){
        return new Promise((resolve, reject) => {
            todoService.deleteUserTodoItem(payload).then((response) => {
                if(response.data.status === 200){
                    commit('removeTodoItem', payload.todoID);
                    resolve(response);
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    }
};
