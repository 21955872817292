import http from "../api";

class ProductCategoryService {
    getAll() {
        return http.get("/ProductCategory");
    }

    getByID(id) {
        return http.get('/ProductCategory/'+id);
    }

    create(data) {
        return http.post("/ProductCategory", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/ProductCategory', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/ProductCategory/'+id);
    }

}

export default new ProductCategoryService();
