<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Job Name: {{pulledJobWorkingData.job_name}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <JobForm
                            :pulledJobWorkingData="pulledJobWorkingData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4">
				<EIJAssetForm
					asset-parent-type="job"
					:asset-i-d=jobID
					@new-asset-uploaded="newAssetUploaded"
				/>

				<EIJAssetGallery
					:assets="workingJobFileAssets"
				/>
            </div>
        </div>
    </div>
</template>
<script>
import jobService from '@/service/JobService';
import JobForm from '@/components/Jobs/JobForm';
import EIJAssetForm from '@/components/EIJ_Assets/EIJAssetForm';
import EIJAssetGallery from "@/components/EIJ_Assets/EIJAssetGallery";
    export default {
        name: "JobDetailView",
        components: {
            JobForm,
			EIJAssetForm,
			EIJAssetGallery,
        },
        data(){
            return {
				jobID: null,
                newNoteInput: '',
                pulledJobWorkingData: {},
				workingJobFileAssets: {},
                showContactForm: false
            }
        },
        methods: {
            removeContact(job){
                if(confirm("Are you sure you want to remove this job?")){
                    new Promise((resolve, reject) => {
                        jobService.delete(job.jobsID).then((response) => {
                            this.getJobByID();
                            this.createToast('Success', 'Contact successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                }
            },
            getJobByID(){
                this.vendorID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    jobService.getByID(this.vendorID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledJobWorkingData = response.data.record;
							this.workingJobFileAssets = response.data.record.fileAssets;
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
        },
        mounted(){
            this.getJobByID();
			this.jobID = this.$route.params.ID;
        }
    };
</script>
