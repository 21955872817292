<template>
    <form v-on:submit.prevent="submitVendorForm" class="">
        <div class="mb-3">
            <label class="form-label">Vendor Company Name</label>
            <input type="text" v-model="workingVendorData.vendor_company_name" placeholder="Vendor Company Name" class="form-control" name="vendor_company_name" v-on:keyup.stop="updateWorkingVendorDataField('vendor_company_name', $event.target.value)" autocomplete="off" />
        </div>

       <div class="mb-3">
            <label class="form-label">First Name</label>
            <input type="text" v-model="workingVendorData.vendor_first_name" placeholder="First Name" class="form-control" name="vendor_first_name" v-on:keyup.stop="updateWorkingVendorDataField('vendor_first_name', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input type="text" v-model="workingVendorData.vendor_last_name" placeholder="Last Name" class="form-control" name="vendor_last_name" v-on:keyup.stop="updateWorkingVendorDataField('vendor_last_name', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Job Title</label>
            <input type="text" v-model="workingVendorData.vendor_job_title" placeholder="Job Title" class="form-control" name="vendor_job_title" v-on:keyup.stop="updateWorkingVendorDataField('vendor_job_title', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Street</label>
            <input type="text" v-model="workingVendorData.vendor_street" placeholder="Street" class="form-control" name="vendor_street" v-on:keyup.stop="updateWorkingVendorDataField('vendor_street', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">City</label>
            <input type="text" v-model="workingVendorData.vendor_city" placeholder="City" class="form-control" name="vendor_city" v-on:keyup.stop="updateWorkingVendorDataField('vendor_city', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Zip</label>
            <input type="text" v-model="workingVendorData.vendor_zip" placeholder="Zip" class="form-control" name="vendor_zip" v-on:keyup.stop="updateWorkingVendorDataField('vendor_zip', $event.target.value)" autocomplete="off" />
        </div>

       <div class="mb-3">
            <label class="form-label">Phone 1</label>
            <input type="text" v-model="workingVendorData.vendor_phone1" placeholder="Phone 1" class="form-control" name="vendor_phone1" v-on:keyup.stop="updateWorkingVendorDataField('vendor_phone1', $event.target.value)" autocomplete="off" />
        </div>

       <div class="mb-3">
            <label class="form-label">Phone 2</label>
            <input type="text" v-model="workingVendorData.vendor_phone2" placeholder="Phone 2" class="form-control" name="vendor_phone2" v-on:keyup.stop="updateWorkingVendorDataField('vendor_phone2', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="text" v-model="workingVendorData.vendor_email" placeholder="Email" class="form-control" name="vendor_email" v-on:keyup.stop="updateWorkingVendorDataField('vendor_email', $event.target.value)" autocomplete="off" />
        </div>

        <div class="mb-3">
            <label class="form-label">Website</label>
            <input type="text" v-model="workingVendorData.vendor_website" placeholder="Website" class="form-control" name="vendor_website" v-on:keyup.stop="updateWorkingVendorDataField('vendor_website', $event.target.value)" autocomplete="off" />
        </div>
        <SubmitButton :submitting="submitting" width="w-100"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import vendorService from '@/service/VendorService';
    export default {
        name: "VendorForm",
        components: { SubmitButton },
        data: function () {
            return {
                vendor: {},
                submitting: false
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledVendorWorkingData:  {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['vendorAdded', 'vendorUpdated'],
        computed: {
            workingVendorData(){
                if(this.editing){
                    return this.pulledVendorWorkingData;
                } else {
                    return {};
                }
            }
        },
        methods: {
            updateWorkingVendorDataField(field, value){
                this.workingVendorData[field] = value;
            },
            submitVendorForm(){
                this.submitting = true;
                let data = this.workingVendorData;
                if(!this.editing){
                    new Promise((resolve, reject) => {
                        if(data.vendor_first_name && data.vendor_last_name){
                            vendorService.create(data).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('vendorAdded', response.data.record);
                                    this.createToast('Success', 'Vendor Successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                reject(error);
                            });
                        }
                        else{
                            reject('No Vendor Data to Send');
                        }
                    });
                } else {
                    new Promise((resolve, reject) => {
                        vendorService.update(data.vendorsID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.createToast('Success', 'Vendor Successfully updated!', 'success');
                                this.$emit('updatedContact');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingVendorData = {};
            },
        },
    };
</script>
