import http from "../api";

class SupplyCategoryService {
    getAll() {
        return http.get("/SupplyCategory");
    }

    getByID(ID) {
        return http.get('/SupplyCategory/'+ID);
    }

    create(data) {
        return http.post("/SupplyCategory", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/SupplyCategory', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/SupplyCategory/'+id);
    }
}

export default new SupplyCategoryService();
