<template>
   <div aria-live="polite" aria-atomic="true" class="position-fixed top-0 start-0 end-0" style="z-index: 9999">
      <div id="toastContainer" class="toast-container position-absolute top-0 end-0 p-3"></div>
   </div>
</template>
<script>
export default {
   inheritAttrs: false,
   name: "PopupAlert"
};
</script>
