<template>
  <div v-if="$route.name == 'SalesInvoiceDetailViewPublic' || $route.name == 'EstimateDetailViewPublic'">
        <router-view/>
  </div>
  <!-- Main Container -->
  <div v-else class="container-fluid" id="main-body">

    <!-- Primiary Row - House sidebar and main view area -->
    <div class="row">

      <!-- Side Bar -->
      <div class="col-md-2 side-bar-container v-100">
        <Sidebar/>
      </div>
      <!-- /Side Bar -->

      <!-- Router View -->
      <div class="col-md-10" :class="sideBarOpen ? 'overlay' : ''" id="main-content">
        <!-- Container is in each view -->
        <router-view/>
      </div>
      <!-- /Router View -->

    </div>
    <!-- /Primary Row -->

  </div>
  <!-- /Main Container -->
</template>

<script>
import { mapState } from 'vuex'

import Sidebar from './Sidebar'
//import Navbar from './Navbar'
//import Footer from './Footer'

export default {
  name: 'AppFrame',
  computed: {
    ...mapState(['sideBarOpen'])
  },
  components: {
    Sidebar
  }
}

</script>
<style scoped lang="scss">
.side-bar-container{
  background-color: $gunmetal;
}
</style>
