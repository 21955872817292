import http from "../api";

class InvoiceService {
    /**
     * Get Invoice with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Invoice", {params: parameters});
    }

    getByID(ID) {
        return http.get('/Invoice/'+ID);
    }
    
    create(data) {
        return http.post("/Invoice", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Invoice', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Invoice/', {params: {invoiceID: id}});
    }
}

export default new InvoiceService();