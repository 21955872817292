<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Purchase Order</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="mb-4">
                    <div class="card-body">
                        <PurchaseOrderForm
                            @purchaseOrderAdded="purchaseOrderAdded"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PurchaseOrderForm from '@/components/Purchasing/PurchaseOrderForm';
    export default {
        name: "PurchaseOrderCreateView",
        components: {
            PurchaseOrderForm
        },
        methods: {
            purchaseOrderAdded(purchase_orderID){
                this.$router.push({ name: 'PurchaseOrderDetailView', params: {ID: purchase_orderID}});
            }
        }
    };
</script>
