<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>{{ pulledCustomerWorkingData.customer_company_name ? pulledCustomerWorkingData.customer_company_name +' - ': ''}} {{ pulledCustomerWorkingData.customer_fname }} {{ pulledCustomerWorkingData.customer_lname }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">{{}}
                    <div class="card-body">
                        <CustomerForm
                            :pulledCustomerWorkingData="pulledCustomerWorkingData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h6>Contacts</h6>
                    </div>
                    <div class="card-body">
                        <!-- Contact Form -->
                        <button type="button" class="btn btn-outline-secondary" @click="showContactForm = !showContactForm">add new contact</button>
                        <transition name="fade">
                            <div v-if="showContactForm" class="mt-4">
                                <ContactForm
                                    :parent="{parentID: pulledCustomerWorkingData.customersID, parentIDType: 'customersID'}"
                                    :pulledContactWorkingData="pulledVendorWorkingData"
                                    :inParentLocation="true"
                                    :editing="false"
                                    @contactAdded="contactAdded"
                                />
                            </div>
                        </transition>
                        <!-- /Contact Form -->

                        <!-- Contact Table -->
                        <table v-if="pulledCustomerWorkingData.contacts" class="table responsive-table mt-3">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Title</th>
                                <th scope="col">Contact</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody  >
                            <tr v-for="row in pulledCustomerWorkingData.contacts" v-bind:key="row.contactsID">
                                <th scope="row">{{row.contact_lname}}, {{row.contact_fname}} </th>
                                <td data-title="contact_address">{{row.contact_title}}</td>
                                <td data-title="contact_contact">
                                    <div v-if="row.contact_email"><a href="mailto: {{row.contact_email}}">{{row.contact_email}}</a></div>

                                    <div v-if="row.contact_phone"><a href="tel:{{row.contact_phone}}">{{row.contact_phone}}</a></div>

                                    <div v-if="row.contact_cell"><a href="tel:{{row.contact_cell}}">{{row.contact_cell}}</a></div>
                                </td>
                                <td data-title="actions">
                                    <router-link :to="{ name: 'ContactDetailView', params: {ID: row.contactsID}}" class="btn">view</router-link>
                                    <button type="button" @click="removeContact(row)" class="ml-2 btn btn-info text-white">remove</button>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <!-- need an if statement for pagination buttons -->
                            </tr>
                            </tfoot>
                        </table>
                        <!-- /Contact Table -->
                    </div>
                </div>
                <br/>

                <!-- Customer Notes Card -->
                <div class="card">
                    <div class="card-header">
                        <h6>Customer Notes</h6>
                    </div>
                    <div class="card-body">
                        <!-- Note Form -->
                        <form id="newCustomerNote" @submit.prevent="createNewNote">
                            <div class="mb-3">
                                <label class="mb-3">New Customer Note</label>
                                <textarea name="customer_note" placeholder="New Customer Note" class="form-control" v-model="newNoteInput"></textarea>
                            </div>
                            <SubmitButton :submitting="noteSubmitting"/>
                        </form>
                        <!-- /Note Form -->
                        <br/>

                        <div v-if="pulledCustomerWorkingData.customerNotes">
                            <div class="card mb-2" v-for="row in pulledCustomerWorkingData.customerNotes" v-bind:key="row.customer_notesID">
                                <div class="card-body">
                                    <p>{{ row.customer_note }}</p>
                                </div>
                                <div class="card-footer">
                                    <span class="float-start">{{ row.user.user_fname }} {{ row.user.user_lname }}</span>&nbsp; <span class="float-end">{{ row.created }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Customer Notes Card -->
            </div>
        </div>
    </div>
</template>

<script>
import CustomerForm from '@/components/Customers/CustomerForm';
import ContactForm from '@/components/Contacts/ContactForm';
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import customerService from '@/service/CustomerService';
import contactService from '@/service/ContactService';
    export default {
        name: "CustomerDetailView",
        components: {
            CustomerForm,
            ContactForm,
            SubmitButton
        },
        data(){
            return {
                newNoteInput: '',
                pulledCustomerWorkingData: {},
                showContactForm: false,
                noteSubmitting: false
            }
        },
        methods: {
            createNewNote: function() {
                if(this.newNoteInput && this.newNoteInput != ''){
                    this.noteSubmitting = true;
                    let data = {customer_note: this.newNoteInput, customersID: this.pulledCustomerWorkingData.customersID}
                    customerService.createCustomerNote(data).then((response) => {
                        this.noteSubmitting = false;
                        if(response.data.status === 200){
                            this.getCustomerByID();
                            this.newNoteInput = '';
                            this.createToast('Success', 'Note successfully added!', 'success');
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.noteSubmitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                }
                else{
                    /**ToDo
                     * Handle empty note
                     **/
                }
            },
            contactAdded(){
                this.showContactForm = false;
                this.getCustomerByID();
            },
            removeContact(contact){
                if(confirm(`Are you sure you want to remove this contact ${contact.contact_fname}?`)){
                    new Promise((resolve, reject) => {
                        contactService.delete(contact.contactsID).then((response) => {
                            this.getCustomerByID();
                            this.createToast('Success', 'Contact successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                }
            },
            getCustomerByID(){
                this.customerID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    customerService.getByID(this.customerID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledCustomerWorkingData = response.data.record;
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
        },
        mounted(){
            this.getCustomerByID();
        }
    };
</script>
