<template>
    <div id="home">
		<div class="row mt-3">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						Jobs
					</div>
					<div class="card-body">
						Jobs
					</div>
					<div class="card-footer">

					</div>
				</div>
			</div>
		</div>

        <div class="row mt-3">
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Schedule
					</div>
					<div class="card-body">
						Schedule
					</div>
					<div class="card-footer">

					</div>
				</div>
			</div>

            <div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Open Estimates
					</div>
					<div class="card-body">
						Estimates
					</div>
					<div class="card-footer">

					</div>
				</div>
			</div>

			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Open Invoices
					</div>
					<div class="card-body">
						Invoices
					</div>
					<div class="card-footer">

					</div>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js';
    //import userService from "../../service/UserService";

    export default {
        name: 'DashboardHome',
        data() {
            return {}
        },
        methods: {

        },
        mounted() {

        }
    }
</script>
<style lang="scss">

</style>
