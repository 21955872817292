import http from "../api";

class CustomerService {

    /**
     * Get Customers with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Customer", {params: parameters});
    }

    getByID(id){
        return http.get("/Customer/"+id);
    }

    create(data) {
        return http.post("/Customer", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Customer', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Customer', {params: {customersID: id}});
    }

    /** Customer Notes **/
    createCustomerNote(data){
        return http.post("/CustomerNote", data)
    }
}

export default new CustomerService();
