<template>
    <div class="container-fluid app-view-container">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>{{ pulledWorkingProductData.product_name }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <ProductForm
                            @productUpdated="productUpdated"
                            :pulledWorkingProductData="pulledWorkingProductData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductForm from '@/components/Products/ProductForm';
// Services
import productService from '@/service/ProductService';
    export default {
        name: "ProductDetailView",
        components: {
            ProductForm
        },
        data: function(){
            return {
                pulledWorkingProductData: {},
                productsID: null
            }
        },
        methods: {
            pullWorkingSupplyCategoryData(){
                this.product_itemID = this.$route.params.ID;
                return new Promise((resolve, reject) => {
                    productService.getByID(this.product_itemID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingProductData = response.data.record;
                           resolve();
                        }
                       else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                       }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    })
                });
            },
            productUpdated(){
                this.$router.push({name: 'ProductsView'});
            }
        },
        mounted(){
           this.pullWorkingSupplyCategoryData();
        }
    };
</script>
