<template>
    <form @submit.prevent="submitJobForm">
        <div class="mb-3" v-if="!editing">
            <label class="form-label">Company / Organization</label>
            <input type="text" class="form-control" placeholder="Type to search database" @keyup="searchOrg" v-model="searchName"/>
            <input type="hidden" class="form-control" @keyup="searchOrg" v-model="company.customersID"/>
            <div v-if="orgSearchArray">
                <div class="mt-2 list-group">
                    <a href="#" class="list-group-item list-group-item-action" v-for="org, index in orgSearchArray"
                    :key="index" @click.stop.prevent="selectOrg(org)">{{ org.customer_company_name }}, {{ org.customer_fname }} {{ org.customer_lname }}</a>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Name</label>
            <input type="text" placeholder="Job Name" class="form-control" name="job_name" v-model="workingJobData.job_name" v-on:keyup.stop="updateWorkingJobDataField('job_name', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Due Date</label>
            <input type="date" placeholder="Job Due Date" class="form-control" name="job_due_date" v-model="workingJobData.job_due_date" v-on:keyup.stop="updateWorkingJobDataField('job_due_date', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Street</label>
            <input type="text" placeholder="job Street" class="form-control" name="job_street" v-model="workingJobData.job_street" v-on:keyup.stop="updateWorkingJobDataField('job_street', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Suite</label>
            <input type="text" placeholder="Job Address" class="form-control" name="job_suite" v-model="workingJobData.job_suite" v-on:keyup.stop="updateWorkingJobDataField('job_suite', $event.target.value)"/>
        </div>

         <div class="mb-3">
            <label class="form-label">Job City</label>
            <input type="text" placeholder="Job City" class="form-control" name="job_city" v-model="workingJobData.job_city" v-on:keyup.stop="updateWorkingJobDataField('job_city', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Job State</label>
            <input type="text" placeholder="Job State" class="form-control" name="job_state" v-model="workingJobData.job_state" v-on:keyup.stop="updateWorkingJobDataField('job_state', $event.target.value)"/>
        </div>

       <div class="mb-3">
            <label class="form-label">Job Zip</label>
            <input type="text" placeholder="Job Zip" class="form-control" name="job_zip" v-model="workingJobData.job_zip" v-on:keyup.stop="updateWorkingJobDataField('job_zip', $event.target.value)"/>
        </div>
        <SubmitButton :submitting="submitting" width="w-100"/>
    </form>
</template>
<script>
import http from "@/api";
import SubmitButton from '@/components/Utilities/SubmitButton'
import jobService from '@/service/JobService';
    export default {
        name: "JobForm",
        components: { SubmitButton },
        data: function () {
            return {
                company: {},
                searchName: '',
                orgSearchArray: [],
                submitting: false
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            inParentLocation: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledJobWorkingData:  {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['jobAdded', 'jobUpdated'],
        computed: {
            workingJobData(){
                if(this.editing){
                    return this.pulledJobWorkingData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingJobDataField (field, value){
                this.workingJobData[field] = value;
            },
            submitJobForm(){
                this.submitting = true;
                let data = this.workingJobData;
                if(!this.editing){
                    new Promise((resolve, reject) => {
                        if(this.company.companyID && data.job_name){
                           this.updateWorkingJobDataField('companyID', this.company.companyID );

                            jobService.create(data).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('jobAdded', response.data.record);
                                    this.createToast('Success', 'Job successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Job Data to Send');
                        }
                    });
                } else {
                    new Promise((resolve, reject) => {
                        jobService.update(data.jobID, this.workingJobData).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('jobUpdated');
                                this.createToast('Success', 'Job successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingJobData = {};
            },
            searchOrg(){
                let parameters = {searchClass: 'Customer', searchMethod: 'searchByNames', term: this.searchName}
                if(this.searchName.length >= 3){
                    http.get("/public/search/", {params: parameters}).then(response => (this.orgSearchArray = response.data.record));
                }
            },
            selectOrg(org){
                this.company.customersID = org.customersID;
                this.searchName = org.customer_company_name;
                this.orgSearchArray = [];
            },
        }
    };
</script>
