import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';

import Home from '../components/Home';
import CheckIdentity from '../views/ResetPassword/CheckIdentity';
import ResetPassword from '../views/ResetPassword/ResetPassword';

import Dashboard from '../components/AppFrame';
import DashboardHome from '../views/Dashboard/Dashboard';

import ContactsView from '../views/Contacts/ContactsView';
import ContactDetailView from '../views/Contacts/ContactDetailView';

import CustomersView from '../views/Customers/CustomersView';
import CustomerDetailView from '../views/Customers/CustomerDetailView';

import JobsView from '../views/Jobs/JobsView';
import JobDetailView from '../views/Jobs/JobDetailView';

import EmployeesView from '../views/Employees/EmployeesView';
import EmployeeDetailView from '../views/Employees/EmployeeDetailView';

import EmployeeScheduleView from '../views/Employees/EmployeeScheduleView';

import InventoryView from '../views/Inventory/InventoryView';
import MarketingView from '../views/Marketing/MarketingView';
import MyCompanyView from '../views/MyCompany/MyCompanyView';

import ProductsView from '../views/Products/ProductsView';
import ProductDetailView from '../views/Products/ProductDetailView';
import ProductCategoryDetailView from '../views/Products/ProductCategoryDetailView';

import VendorsView from '../views/Vendors/VendorsView';
import VendorDetailView from '../views/Vendors/VendorDetailView';


import PurchaseOrderView from '../views/Purchasing/PurchaseOrderView';
import PurchaseOrderCreateView from '../views/Purchasing/PurchaseOrderCreateView';
import PurchaseOrderDetailView from '../views/Purchasing/PurchaseOrderDetailView';

import SupplyView from '../views/Supplys/SupplyView';
import SupplyDetailView from '../views/Supplys/SupplyDetailView';
import SupplyCategoryDetailView from '../views/Supplys/SupplyCategoryDetailView';

import SalesView from '../views/Sales/SalesView';

import InvoicesView from '../views/Invoices/InvoicesView';
import InvoiceCreateView from '../views/Invoices/InvoiceCreateView';
import InvoiceDetailView from '../views/Invoices/InvoiceDetailView';
import InvoiceDetailViewPublic from '../views/Invoices/InvoiceDetailViewPublic';

import EstimateView from '../views/Estimates/EstimateView';
import EstimateCreateView from '../views/Estimates/EstimateCreateView';
import EstimateDetailView from '../views/Estimates/EstimateDetailView';
import EstimateDetailViewPublic from '../views/Estimates/EstimateDetailViewPublic';

import ToDoView from '../views/ToDo/ToDoView';
import MyProfileView from '../views/User/MyProfileView';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/identity',
        name: 'CheckIdentity',
        component: CheckIdentity
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/stm',
        redirect: { name: 'DashboardHome' },
        meta: {requiresAuth: true }
    },
    {
        path: '/stm',
        component: Dashboard,
        children: [
            { path: 'dashboard',
				name: 'DashboardHome',
				component: DashboardHome,
				meta: {requiresAuth: true }
			},
            { path: 'my-profile',
				name: 'MyProfileView',
				component: MyProfileView,
				meta: {requiresAuth: true } }
			,
            { path: 'contacts',
				name: 'ContactsView',
				component: ContactsView,
				meta: {requiresAuth: true }
			},
            {
                path: 'contacts/view/:ID',
                name: 'ContactDetailView',
                component: ContactDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'customers',
                name: 'CustomersView',
                component: CustomersView,
                meta: {requiresAuth: true },
            },
            {
                path: 'customers/view/:ID',
                name: 'CustomerDetailView',
                component: CustomerDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'jobs',
                name: 'JobsView',
                component: JobsView,
                meta: {requiresAuth: true },
            },
            {
                path: 'jobs/view/:ID',
                name: 'JobDetailView',
                component: JobDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'employees',
                name: 'EmployeesView',
                component: EmployeesView,
                meta: {requiresAuth: true },
            },
            {
                path: 'employees/view/:ID',
                name: 'EmployeeDetailView',
                component: EmployeeDetailView,
                meta: {requiresAuth: true },
                children: []
            },
			{
				path: 'employees/schedule',
				name: 'EmployeeScheduleView',
				component: EmployeeScheduleView,
				meta: {requiresAuth: true },
			},
            {   path: 'inventory',
				name: 'InventoryView',
				component: InventoryView,
				meta: {requiresAuth: true }
			},
            {   path: 'marketing',
				name: 'MarketingView',
				component: MarketingView,
				meta: {requiresAuth: true }
			},
            {   path: 'my-company',
				name: 'MyCompanyView',
				component: MyCompanyView,
				meta: {requiresAuth: true }
			},
            {   path: 'products',
				name: 'ProductsView',
				component: ProductsView,
				meta: {requiresAuth: true }
			},
            {
                path: 'products/view/:ID',
                name: 'ProductDetailView',
                component: ProductDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'products/category/:ID',
                name: 'ProductCategoryDetailView',
                component: ProductCategoryDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'vendors',
                name: 'VendorsView',
                component: VendorsView,
                meta: {requiresAuth: true },
            },
            {
                path: 'vendors/view/:ID',
                name: 'VendorDetailView',
                component: VendorDetailView,
                meta: {requiresAuth: true },
                children: []
            },
            {
                path: 'purchase/order',
                name: 'PurchaseOrderView',
                component: PurchaseOrderView,
                meta: {requiresAuth: true },
            },
            {
                path: 'purchase/order/new',
                name: 'PurchaseOrderCreateView',
                component: PurchaseOrderCreateView,
                meta: {requiresAuth: true },
            },
            {
                path: 'purchase/view/:ID',
                name: 'PurchaseOrderDetailView',
                component: PurchaseOrderDetailView,
                meta: {requiresAuth: true },
            },
            {
                path: 'supplies', name: 'SupplyView',
                component: SupplyView,
                meta: {requiresAuth: true }
            },
            {
                path: 'supplies/view/:ID',
                name: 'SupplyDetailView',
                component: SupplyDetailView,
                meta: {requiresAuth: true },
                children: [],
            },
            {
                path: 'supplies/category/:ID',
                name: 'SupplyCategoryDetailView',
                component: SupplyCategoryDetailView,
                meta: {requiresAuth: true },
                children: [],
            },
            {   path: 'sales',
				name: 'SalesView',
				component: SalesView,
				meta: {requiresAuth: true }
			},
			{   path: 'sales/invoice/',
				name: 'InvoicesView',
				component: InvoicesView,
				meta: {requiresAuth: true }
			},
            {   path: 'sales/invoice/create',
				name: 'InvoiceCreateView',
				component: InvoiceCreateView,
				meta: {requiresAuth: true }
			},
            {
                path: 'sales/invoice/edit/:ID',
                name: 'InvoiceDetailView',
                component: InvoiceDetailView,
                meta: {requiresAuth: true },
                children: [],
            },
            {
                path: 'sales/invoice/view/:uuid',
                name: 'SalesInvoiceDetailViewPublic',
                component: InvoiceDetailViewPublic,
                meta: {requiresAuth: false },
                children: [],
            },
            {   path: 'estimates',
				name: 'EstimateView',
				component: EstimateView,
				meta: {requiresAuth: true }
			},
            {   path: 'estimates/create',
				name: 'EstimateCreateView',
				component: EstimateCreateView,
				meta: {requiresAuth: true }
			},
            {
                path: 'estimates/edit/:ID',
                name: 'EstimateDetailView',
                component: EstimateDetailView,
                meta: {requiresAuth: true },
                children: [],
            },
            {
                path: 'estimates/view/:uuid',
                name: 'EstimateDetailViewPublic',
                component: EstimateDetailViewPublic,
                meta: {requiresAuth: false },
                children: [],
            },
            {   path: 'to-do',
				name: 'ToDoView',
				component: ToDoView,
				meta: {requiresAuth: true }
            }
        ]
    }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters['user/authenticated']) {
            next({ name: 'Home' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
});



export default router
