<template>
    <form @submit.prevent="submitCustomerForm">
        <div class="mb-3">
            <label class="form-label">First Name</label>
            <input type="text" placeholder="First Name" class="form-control" name="customer_fname" v-model="workingCustomerData.customer_fname" v-on:keyup.stop="updateWorkingCustomerDataField('customer_fname', $event.target.value)" required/>
        </div>
        <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input type="text" placeholder="Last Name" class="form-control" name="customer_lname" v-model="workingCustomerData.customer_lname" v-on:keyup.stop="updateWorkingCustomerDataField('customer_lname', $event.target.value)" required/>
        </div>
        <div class="mb-3">
            <label class="form-label">Company Name</label>
            <input type="text" placeholder="Company Name" class="form-control" name="customer_company_name" v-on:keyup.stop="updateWorkingCustomerDataField('customer_company_name', $event.target.value)" v-model="workingCustomerData.customer_company_name" />
        </div>
        <div class="mb-3">
            <label class="form-label">Street Address</label>
            <input type="text" placeholder="Street Address" class="form-control" name="customer_street" v-on:keyup.stop="updateWorkingCustomerDataField('customer_street', $event.target.value)" v-model="workingCustomerData.customer_street" />
        </div>
        <div class="mb-3">
            <label class="form-label">City</label>
            <input type="text" placeholder="City" class="form-control" name="customer_city" v-on:keyup.stop="updateWorkingCustomerDataField('customer_city', $event.target.value)" v-model="workingCustomerData.customer_city" />
        </div>
        <div class="mb-3">
            <label class="form-label">State / Province</label>
            <input type="text" placeholder="State / Province" class="form-control" name="customer_state" v-on:keyup.stop="updateWorkingCustomerDataField('customer_state', $event.target.value)" v-model="workingCustomerData.customer_state" />
        </div>
        <div class="mb-3">
            <label class="form-label">Postal Code (zip)</label>
            <input type="text" placeholder="Postal Code (zip)" class="form-control" name="customer_zip" v-on:keyup.stop="updateWorkingCustomerDataField('customer_zip', $event.target.value)" v-model="workingCustomerData.customer_zip" />
        </div>
        <div class="mb-3">
            <label class="form-label">Main Phone</label>
            <input type="text" placeholder="Main Phone" class="form-control" name="customer_phone" v-on:keyup.stop="updateWorkingCustomerDataField('customer_phone', $event.target.value)" v-model="workingCustomerData.customer_phone" />
        </div>
        <div class="mb-3">
            <label class="form-label">Mobile Phone</label>
            <input type="text" placeholder="Mobile Phone" class="form-control" name="customer_cell" v-on:keyup.stop="updateWorkingCustomerDataField('customer_cell', $event.target.value)" v-model="workingCustomerData.customer_cell" />
        </div>
        <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="text" placeholder="Email" class="form-control" name="customer_email" v-on:keyup.stop="updateWorkingCustomerDataField('customer_email', $event.target.value)" v-model="workingCustomerData.customer_email" />
        </div>
        <div class="mb-3">
            <label class="form-label">Birthday</label>
            <input type="date" placeholder="Birthday" class="form-control" name="customer_birthday" v-on:keyup.stop="updateWorkingCustomerDataField('customer_birthday', $event.target.value)" v-model="workingCustomerData.customer_birthday" />
        </div>
        <SubmitButton :submitting="submitting" width="w-100"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import customerService from '@/service/CustomerService';
    export default {
        name: "CustomerForm",
        components: { SubmitButton },
        data: function () {
            return {
                customer: {},
                submitting: false
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            inParentLocation: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledCustomerWorkingData:  {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['customerAdded', 'customerUpdated'],
        computed: {
            workingCustomerData(){
                if(this.editing){
                    return this.pulledCustomerWorkingData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingCustomerDataField (field, value){
                this.workingCustomerData[field] = value;
            },
            submitCustomerForm(){
                this.submitting = true;
                let data = this.workingCustomerData;
                if(!this.editing){
                    new Promise((resolve, reject) => {
                        if(data.customer_fname && data.customer_lname){
                            customerService.create(data).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('customerAdded', response.data.record);
                                    this.createToast('Success', 'Customer successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Customer Data to Send');
                        }
                    });
                } else {
                    new Promise((resolve, reject) => {
                        customerService.update(data.customersID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('customerUpdated');
                                this.createToast('Success', 'Customer successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingCustomerData = {};
            },
        }
    };
</script>
