import http from "../api";

class CompanyService {
    getAll() {
        return http.get("/public/Company/");
    }

    get() {
        return http.get('/Company/'+1);
    }

    update(id, data) {
        data['ID'] = id;
        return http.patch('/Company', JSON.stringify(data));
    }
}

export default new CompanyService();
