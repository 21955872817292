import http from "../api";

class EmployeeService {

    /**
     * Get Employees with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Employee", {params: parameters});
    }

    getByID(id){
        return http.get("/Employee/"+id);
    }

    create(data) {
        return http.post("/Employee", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Employee', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Employee', {params: {employeeID: id}});
    }

    /** Employee Notes **/
    createEmployeeNote(data){
        return http.post("/EmployeeNote", data)
    }
}

export default new EmployeeService();
