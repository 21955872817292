<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Estimate</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <div class="card mb-5">
                    <div class="card-body">
                        <!-- Contact Table -->
                        <div class="table-responsive">
                            <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Customer Company</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Estimate Title</th>
                                    <th scope="col">Sub Total</th>
                                    <th scope="col">Tax</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="estimatePaginatedList">
                                <tr v-for="row, index in estimatePaginatedList" :key="index">
                                    <td>{{row.customer?.customer_company_name}}</td>
                                    <td>{{row.customer?.customer_fname}} {{row.customer?.customer_lname}}</td>
                                    <td>{{row.estimate_title}}</td>
                                    <td data-title="estimate_sub_total">{{numberWithCommas(row.estimate_sub_total)}}</td>
                                    <td data-title="estimate_sales_tax">{{numberWithCommas(row.estimate_sales_tax)}}</td>
                                    <td data-title="estimate_total"><strong>{{numberWithCommas(row.estimate_total)}}</strong></td>
                                    <td data-title="actions">
                                        <a :href="viewLink(row.uuid)" target="_blank" class="btn">
                                            <i class="bi bi-eye"></i> View
                                        </a>
                                         <router-link :to="{ name: 'EstimateDetailView', params: {ID: row.estimateID}}" class="btn">
                                            <i class="bi bi-pencil"></i> Edit
                                        </router-link>
                                        <button type="button" @click="removeEstimate(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning">No Sales Estimates Found</div>
                            </tbody>
                             <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="estimateID"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                             </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <router-link :to="{ name: 'EstimateCreateView'}" class="btn btn-success">Create New Estimate</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
// Services
import estimateService from '@/service/EstimateService';
    export default {
        name: "EstimateView",
        components: {PaginationButtons},
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'estimateID', 'orderDirection':'ASC'},
                currentPage: 1,
                showEstimateForm: false,
                searchTerm: '',
                estimatePaginatedList: [],
                recordCount: 0
            }
        },
        methods: {
            pullEstimateList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    estimateService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.estimatePaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullEstimateList('hasPageStart', pagStart)
            },
            searchInsvoices(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Estimate', searchMethod: 'searchByNames', term: this.searchTerm};
                        estimateService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.estimatePaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                } else{
                    this.pullEstimateList();
                }
            },
            removeEstimate(estimate){
                if(confirm(`Are you sure you want to remove this estimate from ${estimate.customer.customer_company_name}?`)){
                    new Promise((resolve, reject) => {
                    estimateService.delete(estimate.estimateID).then((response) => {
                        this.createToast('Success', 'Estimate successfully deleted!', 'success');
                        const index = this.estimatePaginatedList.map(x => {
                            return x.estimateID;
                        }).indexOf(estimate.estimateID);
                            if(index !== -1) {
                                this.estimatePaginatedList.splice(index, 1);
                                this.clearNewlyAddedEstimates();
                            }
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            viewLink(query){
                return 'https://simpletomanage.com/estimate/?uuid="'+query+'"';
            }
        },
        mounted() {
            this.pullEstimateList();
        }
    };
</script>
