<template>
    <form @submit.prevent="submitPurchaseOrderForm">
        <div class="mb-3">
            <!-- Search Vendor -->
            <div class="form-group mb-3">
                <label class="col-form-label">Search Vendor Name</label>
                <div class="">
                    <div v-if="vendor.searchIsSet">
                        <div class="input-group mb-2" v-if="vendor.reset">
                            <input type="text" readonly="true" class="form-control"
                                :value="vendor.selected?.vendor_company_name"
                            />
                        </div>
                        <div  class="input-group mb-2" v-else>
                            <input type="text" readonly="true" class="form-control" :value="workingPurchaseOrderData.vendor?.vendor_company_name">
                        </div>
                        <input type="hidden" :run="!workingPurchaseOrderData.vendorsID ? workingPurchaseOrderData.vendorsID = vendor.selected?.vendorsID : true" v-model="workingPurchaseOrderData.vendorsID"/>
                        <button v-if="!editing" @click.prevent="clearVendorList" class="btn btn-info text-white">Change Vendor</button>
                    </div>
                    <div v-else>
                        <div class="input-group mb-2">
                            <input type="text" class="form-control" placeholder="Search by Vendor Name or Company" v-model="vendor.searchTerm" @keyup="searchVendors" />
                        </div>
                        <div class="dropdown-wrapper" v-if="vendor.isDropdownOpen">
                            <div v-if="!vendor.search_loading">
                                <div v-if="vendor.list.length" class="pb-3">
                                    <div class="mt-2 list-group">
                                        <a href="#" class="list-group-item list-group-item-action" v-for="item in vendor.list"
                                        :key="item.vendorsID" @click.stop.prevent="selectVendor(item)">{{ item.vendor_company_name }}</a>
                                    </div>
                                </div>
                                <div v-else class="text-danger">
                                    <span v-if="!vendor.searchIsSet">Vendor not found!</span>
                                </div>
                            </div>
                            <div v-else>Loading...</div>
                        </div>
                        <div v-else>
                            <span class="text-danger">{{ errors.vendorError }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Search Vendor-->
            <div class="mb-3">
                <label class="form-label">Purchase Order Sent</label>
                <input type="date" v-model="workingPurchaseOrderData.purchase_order_sent" placeholder="Purchase Order Sent" class="form-control" name="purchase_order_sent" v-on:keyup.stop="updateWorkingPurachseOrderDataField('purchase_order_sent', $event.target.value)" autocomplete="off" />
            </div>
        </div>
        <SubmitButton :submitting="submitting" v-if="!editing"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import purchaseOrderService from '@/service/PurchaseOrderService';
import vendorService from '@/service/VendorService';
    export default {
        name: "PurchaseOrderForm",
        components: { SubmitButton },
        data: function () {
            return {
                vendor: {
                    search_loading: false,
                    isDropdownOpen: false,
                    selected: null,
                    searchIsSet: false,
                    searchTerm: '',
                    purchaseOrderSubTotal: 0,
                    purchaseOrderTax: 0,
                    purchaseOrderTotal: 0,
                    reset: false,
                    list: []
                },
                submitting: false,
                errors: {},
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledWorkingPurchseOrderData:  {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['purchaseOrderAdded', 'purchaseOrderUpdated'],
        computed: {
            workingPurchaseOrderData(){
                if(this.editing){
                    return this.pulledWorkingPurchseOrderData;
                } else {
                    return {};
                }
            }
        },
        methods: {
            updateWorkingPurachseOrderDataField(field, value){
                this.workingPurchaseOrderData[field] = value;
            },
            submitPurchaseOrderForm(){
                this.submitting = true;
                this.errors = {};

                // if (!this.vendor.searchIsSet) this.errors.vendorError = "Vendor is required!";
                // if (Object.keys(this.errors).length) return;

                if(!this.editing){
                     if(this.workingPurchaseOrderData.vendorsID){
                        purchaseOrderService.create(this.workingPurchaseOrderData).then((response) => {
                            this.submitting = false;
                            if(response.data.status == 200){
                                this.$emit('purchaseOrderAdded', response.data.record.purchase_orderID);
                                this.createToast('Success', 'Purchase Order successfully added!', 'success');
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    }
                    else{
                        reject('No Job Data to Send');
                    }
                }
                else{
                    new Promise((resolve, reject) => {
                        purchaseOrderService.update(data.invoiceID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('purchaseOrderUpdated');
                                this.createToast('Success', 'Purchase Order successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            searchVendors(){
                this.vendor.search_loading = true;
                this.vendor.isDropdownOpen = true;
                let payload = {searchClass: 'Vendor', searchMethod: 'searchByNames', term: this.vendor.searchTerm};
                if(this.vendor.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        vendorService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.vendor.search_loading = false;
                                this.vendor.list = response.data.record;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                }
            },
            selectVendor(vendor){
                this.vendor.isDropdownOpen = true;
                this.vendor.searchIsSet = true;
                this.vendor.reset = true;
                this.vendor.selected = vendor;
            },
            clearVendorList(){
                this.vendor.searchIsSet = false;
                this.vendor.isDropdownOpen = false;
                this.vendor.list = [];
                this.vendor.searchTerm = '';
            }
        },
        created(){
            this.vendor.isDropdownOpen = false;
            if(this.editing){
                this.vendor.searchIsSet = true;
                this.vendor.selected = this.workingPurchaseOrderData.vendor;
            }
        },
    };
</script>
<style scoped>
.dropdown-wrapper {
    height: auto;
    max-height: 200px!important;
    overflow-y: scroll;
    border:  none;
}

.dropdown-wrapper::-webkit-scrollbar {
    width: 10px;
    border-radius: 50px;
}

.dropdown-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}

.dropdown-wrapper::-webkit-scrollbar-thumb {
   background-color: #efefef;
   border-radius: 19px;
}

.dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #888;
    transition: 0.3s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
