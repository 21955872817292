<template>      
    <PopupAlertContainer/>
    <router-view />
    <PopupAlertTemplate/>
</template>
<script>
export default {
  name: 'app',
  beforeCreate(){
    this.$store.dispatch('user/isAuthenticated').then((resolve) => {
      console.log(resolve);
    },(reject) => {
      console.log(reject);
    });
  },
};
</script>
<style lang="scss">
  #app {
    display: block;
  }

  .toast {
    transition: all 0.3s ease-in-out!important;
  }

  .center-box {
      background-color: #ffffffce;
      border: solid 0.11rem rgba(255, 255, 255, 0.349);
      padding: 1.36rem 1.88rem 0.7rem 1.88rem;
      box-shadow: 0 0.325rem 1.4rem -0.1875rem rgba(20, 20, 20, 0.028);
      -webkit-box-shadow: 0 0.325rem 1.4rem -0.1875rem rgba(20, 20, 20, 0.028);
      position: relative;
      width: 420px;
      margin: 5% auto;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
      .center-box {
          border: none!important;
          border-radius: 0%!important;
          box-shadow: none!important;
          -webkit-box-shadow: none!important;
          border-radius: none!important;
          width: 100%!important;
      }   
  }
  
  .update-icon {
      cursor: pointer!important;
      display: none;

      &.show {
          display: inline-block!important;
      }
  }
</style>