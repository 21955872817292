<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">Product Category Item Form</div>
                    <div class="card-body">
                        <ProductCategoryForm
                            :pulledWorkingProductCategoryData="pulledWorkingProductCategoryData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductCategoryForm from '@/components/Products/ProductCategoryForm';
import productCategoryService from '@/service/ProductCategoryService';
    export default {
        name: "ProductCategoryDetailView",
        components: {
            ProductCategoryForm,
        },
        data(){
            return {
                pulledWorkingProductCategoryData: {},
                product_itemID: null,
            }
        },
        methods: {
            pullWorkingProductCategoryData(){
                this.product_itemID = this.$route.params.ID;
                return new Promise((resolve, reject) => {
                    productCategoryService.getByID(this.product_itemID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingProductCategoryData = response.data.record;
                           resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                       }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    })
                });
            }
        },
        mounted(){
           this.pullWorkingProductCategoryData();
        }
    };
</script>
