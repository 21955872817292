import http from "../api";

class SupplyItemService {
    getAll() {
        return http.get("/SupplyItem");
    }

    getByID(id){
        return http.get("/SupplyItem/"+id);
    }

    create(data) {
        return http.post("/SupplyItem", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/SupplyItem', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/SupplyItem', {params: {supply_itemID: id}});
    }
}

export default new SupplyItemService();