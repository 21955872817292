<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Contacts</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="card card-body">
                    <!-- Search -->
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Contact Name or Company" v-model="searchTerm" @keyup="searchContacts" />
                    </div>
                    <!-- /Search -->

                    <!-- Contact Table -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Contact</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody v-if="contactPaginatedList.length" >
                            <tr v-for="row in contactPaginatedList" v-bind:key="row.contactsID">
                                <th scope="row">{{row.customer?.customer_fname}} {{row.customer?.customer_lname}}</th>
                                <td data-title="contact_address">{{row.customer?.customer_street}} <br> {{row.customer?.customer_city}}
                                    {{row.customer?.customer_state}}, {{row.customer?.customer_zip}}
                                </td>
                                <td data-title="contact_contact">
                                    <p>{{row.contact_fname}} {{row.contact_lname}}</p>
                                    <a :href="`mailto:${row.contact_email}`">{{row.contact_email}}</a>
                                    <br>
                                    <a :href="`tel:${row.contact_phone}`">{{row.contact_phone}}</a>
                                    <br>
                                    <a :href="`tel:${row.contact_cell}`">{{row.contact_cell}}</a>
                                </td>
                                <td data-title="actions">
                                    <router-link :to="{ name: 'ContactDetailView', params: {ID: row.contactsID}}" class="btn"><i class="bi bi-eye"></i> view/edit</router-link>
                                    <button type="button" v-on:click="removeContact(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning text-center">No Conctacts found...</div>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="contact_lname"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <!-- /Contact Table -->
                </div>
            </div>

            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <ul v-if="newlyAddedContacts">
                            <li v-for="newContact in newlyAddedContacts" :key="newContact.contactsID">
                                <router-link :to="{ name: 'ContactDetailView', params: {ID: newContact.contactsID}}" class="btn">{{newContact.contact_fname}} {{newContact.contact_lname}}<span v-if="newContact"> - {{newContact.contact_lname}}</span></router-link>
                            </li>
                        </ul>

                        <!-- Contact Form -->
                        <button type="button" class="btn mb-2 btn-outline-secondary" v-on:click="showContactForm = !showContactForm"><i class="bi bi-plus-circle-fill"></i>&nbsp;  add new contact</button>
                        <transition name="fade">
                            <div v-if="showContactForm" class="card mt-3">
                                <div class="card-header">
                                    <h6>
                                        Add Contact Form
                                    </h6>
                                        <button class="btn-close" aria-label="Close" type="button" v-on:click="showContactForm = !showContactForm"></button>
                                </div>
                                <div class="card-body">
                                    <ContactForm
                                        @contactAdded="contactAdded"
                                    />
                                </div>
                            </div>
                        </transition>
                        <!-- /Contact Form -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import ContactForm from '@/components/Contacts/ContactForm';
// Services
import contactService from '@/service/ContactService';
    export default {
        name: "ContactsView",
        components: {
            PaginationButtons,
            ContactForm,
        },
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'contact_lname', 'orderDirection':'ASC'},
                currentPage: 1,
                showContactForm: false,
                searchTerm: '',
                newlyAddedContacts: [],
                showCustomerForm: false,
                newlyAddedCustomers: [],
                contactPaginatedList: [],
                recordCount: 0
            };
        },
        methods: {
            pullContactList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    contactService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.contactPaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullContactList('hasPageStart', pagStart)
            },
            searchContacts(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Contact', searchMethod: 'searchByNames', term: this.searchTerm};
                        contactService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.contactPaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            };
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                } else{
                    this.pullContactList();
                }
            },
            removeContact(contact){
                if(confirm("Are you sure you want to remove this contact?")){
                    new Promise((resolve, reject) => {
                    contactService.delete(contact.contactsID).then((response) => {
                        const index = this.contactPaginatedList.map(x => {
                            return x.contactsID;
                        }).indexOf(contact.contactsID);
                            if(index !== -1) {
                                this.contactPaginatedList.splice(index, 1);
                                this.clearNewlyAddedContacts();
                            }
                            this.createToast('Success', 'Contact successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            contactAdded(contactData){
                this.pullContactList();
                this.newlyAddedContacts.push(contactData);
                this.showContactForm = false;
            },
            clearNewlyAddedContacts(){
                this.newlyAddedContacts = [];
            },
            customerAdded(customerData){
                this.newlyAddedCustomers.push(customerData);
                this.showCustomerForm = false;
            },
        },
        mounted() {
            this.pullContactList();
        }
    };
</script>
