<template>
    <div class="container-fluid">
        <form @submit.prevent="submit" class="login-form center-box">
			<div class="card">
				<div class="card-header text-center bg-dark">
					<img src="/global-images/stm-logo.png" width="150" />
				</div>
				<div class="card-body">
					<h4 class="text-center">Login</h4>
					<div class="mb-2 mt-1">
						<label class="">Username</label>
						<input
							type="text"
							class="form-control"
							v-model="username"
							placeholder="Type your username here..."
						/>
					</div>
					<div class="mb-2">
						<label class="">Password</label>
						<input
							type="password"
							class="form-control"
							v-model="password"
							placeholder="Type your password here..."
							autocomplete="off"
						/>
					</div>
					<SubmitButton
						:submitting="submitting"
						label="Login"
						label_action="Logging in...."
						width="w-100"/>
				</div>
				<div class="card-footer">
					<div class="text-center">
						<router-link :to="{ name: 'CheckIdentity' }" class="link">forgot password</router-link>
					</div>
				</div>
			</div>
        </form>
    </div>
</template>
<script>
    export default {
        name: "Home",
        data: function() {
            return {
                username: '',
                password: '',
                submitting: false,
                errorMessage: ''
            };
        },
        methods: {
            submit(){
                this.submitting = true;
                this.$store.dispatch('user/authenticateUser', {username: this.username, password: this.password}).then(response => {
                    this.submitting = false;
                    this.createToast('Success', 'Successfully logged in!', 'success');
                    this.$router.push("/stm/");
                }, () => {
                    this.submitting = false;
                    this.errorMessage = this.$store.getters['user/authenticationErrorMessage'];
                    this.createToast('Error', this.errorMessage, 'danger');
                });
            }
        },
        mounted(){
            this.$store.dispatch('user/isAuthenticated').then(() => {
                this.$router.push("/stm/");
            }, (reject) => {
                console.log(reject);
            });
        }
    };
</script>
