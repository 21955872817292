<template>
	<div class="card">
		<div class="card-header">
			<h6>Upload an Image or Document</h6>
		</div>
		<div class="card-body">
			<form @submit.prevent="submitAssetForm" id="assetUploadForm">
				<div class="mb-3">
					<label class="">Name or Description <span class="small">(optional)</span></label>
					<input type="text" placeholder="Description" name="asset_name" v-model="assetName" class="form-control"/>
				</div>
				<div class="mb-3">
					<label for="formFile" class="form-label">Choose a File</label>
					<input class="form-control" type="file" name="upload-file" id="formFile">
				</div>

				<SubmitButton :submitting="submitting" v-if="!editing"/>
			</form>
		</div>
	</div>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
import http from "@/api";

export default {
	name: "EIJAssetForm",
	components: {SubmitButton},
	data() {
		return {
			submitting: false,
			errors: {},
			assetName: '',

		}
	},
	props: {
		editing: {
			type: Boolean,
			required: false,
			default: false,
		},
		assetParentType: {
			type: String,
			required: true,
			default: null,
		},
		assetID:{
			type: Number,
			required: true,
			default: 0
		}
	},
	emits: ['fileUploaded', 'newAssetUploaded'],
	computed: {

	},
	methods: {
		clearFormData() {
			//clear the form & refocus for next entry

		},
		submitAssetForm(){
			this.submitting = true;

			const form = document.getElementById('assetUploadForm');
			let formData = new FormData(form);

			// append the crop data to the form
			formData.append("assetID", this.assetID);
			formData.append('assetType', this.assetParentType);

			this.uploadAsset(formData);
		},
		uploadAsset(formData){
			formData.append("altMethod","PUT");

			http.post('/EIJAsset', formData).then((response)=>{
				if(response.data.status == 200){
					this.$emit('newAssetUploaded', response.data.record);
					this.submitting = false;
				}
				else{
					console.log(response.data.record);
					this.submitting = false;
				}
			});
			this.assetName = '';
			document.getElementById('formFile').value = '';
		}

	},
	created() {

	},
};
</script>
<style scoped>

</style>
