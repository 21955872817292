import http from "../api";

class InvoiceItemService {
    getAll() {
        return http.get("/InvoiceItem");
    }

    getByID(ID) {
        return http.get('/InvoiceItem/'+ID);
    }

    create(data) {
        return http.post("/InvoiceItem", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/InvoiceItem', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/InvoiceItem/', {params: {invoice_itemID: id}});
    }
}

export default new InvoiceItemService();