<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Customers</h6>
            </div>
        </div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<ul v-if="newlyAddedCustomers">
							<li v-for="newCustomer in newlyAddedCustomers" :key="newCustomer.customersID">
								<router-link :to="{ name: 'CustomerDetailView', params: {ID: newCustomer.customersID}}" class="btn">{{newCustomer.customer_fname}} {{newCustomer.customer_lname}}<span v-if="newCustomer"> - {{newCustomer.customer_lname}}</span></router-link>
							</li>
						</ul>

						<!-- Customer Form -->
						<button type="button" class="btn mb-2 btn-outline-secondary" @click="showCustomerForm = !showCustomerForm"><i class="bi bi-plus-circle-fill"></i>&nbsp; add new customer</button>
						<transition name="fade">
							<div v-if="showCustomerForm" class="card mt-3">
								<div class="card-header">
									<h6>
										Add Customer Form
									</h6>
									<button class="btn-close" aria-label="Close" type="button" @click="showCustomerForm = !showCustomerForm"></button>
								</div>
								<div class="card-body">
									<CustomerForm
										@customerAdded="customerAdded"
									/>
								</div>
							</div>
						</transition>
						<!-- /Customer Form -->
					</div>
				</div>
			</div>
		</div>
        <div class="row">
            <div class="col-12">
                <div class="card card-body">
                    <!-- Search -->
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Customer Name or Company" v-model="searchTerm" @keyup="searchCustomers" autocomplete="false" />
                    </div>
                    <!-- /Search -->

                    <!-- Customer Table -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Company</th>
                                <th scope="col">Address</th>
                                <th scope="col">Contact</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody v-if="customerPaginatedList.length" >
                            <tr v-for="row in customerPaginatedList" v-bind:key="row.customersID">
                                <th scope="row">{{row.customer_lname}}, {{row.customer_fname}} </th>
                                <td data-title="company_name">{{row.customer_company_name}}</td>
                                <td data-title="customer_address">{{row.customer_street}} <br> {{row.customer_city}} {{row.customer_state}}, {{row.customer_zip}}</td>
                                <td data-title="customer_contact">
                                    <a :href="`mailto:${row.customer_email}`">{{row.customer_email}}</a>
                                    <br>
                                    <a :href="`tel:${row.customer_phone}`">{{row.customer_phone}}</a>
                                    <br>
                                    <a :href="`tel:${row.customer_cell}`">{{row.customer_cell}}</a>
                                </td>
                                <td data-title="actions">
                                    <router-link :to="{ name: 'CustomerDetailView', params: {ID: row.customersID}}" class="btn"><i class="bi bi-eye"></i> view/edit</router-link>
                                    <button type="button" v-on:click="removeCustomer(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="customer_lname"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <!-- /Customer Table -->
                    </div>
                </div>
            </div>
		</div>

    </div>
</template>

<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import CustomerForm from '@/components/Customers/CustomerForm';
// Services
import customerService from '@/service/CustomerService';
    export default {
        name: "CustomersView",
        components: {
            PaginationButtons,
            CustomerForm
        },
        data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'customer_company_name', 'orderDirection':'ASC'},
                currentPage: 1,
                showCustomerForm: false,
                searchTerm: '',
                newlyAddedCustomers: [],
                customerPaginatedList: [],
                recordCount: 0
            };
        },
        methods: {
            pullCustomerList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    customerService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.customerPaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullCustomerList('hasPageStart', pagStart)
            },
            searchCustomers(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Customer', searchMethod: 'searchByNames', term: this.searchTerm};
                        customerService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.customerPaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                } else{
                    this.pullCustomerList();
                }
            },
            removeCustomer(customer){
                if(confirm(`Are you sure you want to remove this customer ${customer.customer_l_name}?`)){
                    new Promise((resolve, reject) => {
                    customerService.delete(customer.customersID).then((response) => {
                        const index = this.customerPaginatedList.map(x => {
                            return x.customersID;
                        }).indexOf(customer.customersID);
                            if(index !== -1) {
                                this.customerPaginatedList.splice(index, 1);
                                this.clearNewlyAddedCustomers();
                                this.createToast('Success', 'Customer successfully deleted!', 'success');
                            }
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            customerAdded(data){
                this.pullCustomerList();
                this.newlyAddedCustomers.push(data);
                this.showCustomerForm = false;
            },
            clearNewlyAddedCustomers(){
                this.newlyAddedCustomers = [];
            }
        },
        mounted() {
            this.pullCustomerList();
        }
    };
</script>
