<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>To Do</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="card card-body">
                    <div class="list-group">
                        <draggable v-if="todoList.length" v-model="todoList" :sort="true">
                            <transition-group>
                                <div class="list-group-item" v-for="row in todoList" v-bind:key="row.todoID">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-on:change="completeTodo(row)" v-bind:checked="row.todo_status">
                                        <label class="form-check-label editable-field" contenteditable="true"
                                               v-on:keydown.enter="updateTodoItem($event, row)"
                                               v-on:blur="updateTodoItem($event, row)"
                                               v-bind:class="{ 'text-strike': row.todo_status }">
                                            {{ row.todo_text }}
                                        </label>
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body">
                    <form v-on:submit.prevent="addTodoItem">
                        <div class="mb-3">
                            <label class="form-label" for="new-todo">Add a todo</label>
                            <div class="input-group mb-3">
                                <input
                                    v-model="newTodoText"
                                    id="new-todo"
                                    class="form-control"
                                    placeholder="E.g. Feed the dog"
                                    v-on:keydown.enter="addTodoItem">
                                 <button class="btn btn-info"><span class="text-white">+</span></button>
                            </div>
                        </div>
                    </form>
                    <hr>
                    <button type="button" class="btn" v-on:click.prevent="clearTodoList">Clear To Do List</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * https://vue-draggable-next.vercel.app/
     * https://github.com/anish2690/vue-draggable-next
     */
    import { VueDraggableNext } from 'vue-draggable-next';

    export default {
        name: "ToDoView",
        components: {
            draggable: VueDraggableNext,
        },
        data() {
            return {
                todoData : [],
                enabled: true,
                dragging: false,
            };
        },
        methods: {
            addTodoItem(){
                if(this.newTodoText != ''){
                    let newTodoData = {
                        todo_text: this.newTodoText,
                        todo_sort: this.todoData.length++
                    };
                    this.$store.dispatch('todo/createUserTodoItem', newTodoData).then((resolve) => {
                        console.log(resolve);
                    }, (reject) => {
                        console.log(reject);
                    });
                    this.newTodoText = ''
                }
            },
            updateTodoItem(e, item){
                item.ID = item.todoID;
                item.todo_text = e.target.innerText;
                e.preventDefault();
                this.$store.dispatch('todo/updateUserTodoItem', item).then((resolve) => {
                    /**
                     * ToDo
                     * returning 400 from API even when record is updated.
                     **/

                    console.log(resolve);
                }, (reject) => {
                    console.log(reject);
                });
                e.target.blur();
            },
            completeTodo(todoItem){
                todoItem.todo_status = !todoItem.todo_status;
                todoItem.ID = todoItem.todoID;
                this.$store.dispatch('todo/updateUserTodoItem', todoItem).then((resolve) => {
                    console.log(resolve);
                }, (reject) => {
                   console.log(reject);
                });
            },
            clearTodoList(){
                for(let i=0; i<this.todoData.length; i++){
                    this.$store.dispatch('todo/deleteUserTodoItem', this.todoData[i]).then((resolve) => {
                        console.log(resolve);
                    }, (reject) => {
                        console.log(reject);
                    });
                }
            }
        },
        computed: {
            todoList:{
                get() {
                    return this.$store.getters['todo/todoData'];
                },
                set(value) {
                    this.$store.dispatch('todo/updateUserTodoOrder', value);

                    for(let i=0; i < value.length; i++){
                        console.log(value[i]);
                        value[i].todo_sort = i;
                        value[i].ID = value[i].todoID;
                        this.$store.dispatch('todo/updateSortedTodoItem', value[i]).then((resolve) => {
                            console.log(resolve);
                        }, (reject) => {
                            console.log(reject);
                        });
                    }
                }
            }
        },
        mounted() {
            /** Populate the Company Data**/
            this.$store.dispatch('todo/setTodoDataForUser').then(() => {

            });
        }
    };
</script>