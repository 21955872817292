<template>
   <div id="toastTemplate" class="toast align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay="5000" data-bs-animation="true">
      <div class="toast-header">
         <strong class="mr-auto toast-title"></strong>
         <button type="button" class="btn-close close" data-dismiss="toast" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body"><p class="toast-body-text"></p></div>
   </div>
</template>
<script>
export default {
   inheritAttrs: false,
   name: "PopupAlert"
}; 
</script>