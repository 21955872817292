<template>
    <form @submit.prevent="submitInvoiceForm">
        <!-- Search Customer -->
        <div class="form-group mb-3">
        <label class="col-form-label">Customer Name: </label>
            <div>
                <div v-if="customer.searchIsSet">
                    <div class="input-group mb-2" v-if="customer.reset">
                        <input type="text" readonly="true" class="form-control" :value="customer.selected?.customer_fname +' '+ customer.selected?.customer_lname"
                        />
                    </div>
                    <div class="input-group mb-2" v-else>
                        <input type="text" readonly="true" class="form-control" :value="workingInvoiceData.customer?.customer_fname +' '+ workingInvoiceData.customer?.customer_lname">
                    </div>
                    <input type="hidden" :run="!workingInvoiceData.customersID ? workingInvoiceData.customersID = customer.selected?.customersID : true" v-model="workingInvoiceData.customersID"/>
                    <button v-if="!editing" @click="clearCustomerList" class="btn btn-info text-white">Change Customer</button>
                </div>
                <div v-else>
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Customer Name or Company" v-model="customer.searchTerm" @keyup="searchCustomers" />
                    </div>

                    <div class="dropdown-wrapper" v-if="customer.isDropdownOpen">
                        <div v-if="!customer.search_loading">
                            <div v-if="customer.list" class="pb-3">
                                <div class="mt-2 list-group">
                                    <a href="#" class="list-group-item list-group-item-action" v-for="item, index in customer.list"
                                    :key="index" @click.stop.prevent="selectCustomer(item)">{{ item.customer_fname }} {{item.customer_lname}}</a>
                                </div>
                            </div>
                            <div v-else class="text-danger">
                                <span v-if="!customer.searchIsSet">Customer not found!</span>
                            </div>
                        </div>
                        <div v-else>Loading...</div>
                    </div>
                    <div v-else>
                        <span class="text-danger">{{ errors.customerError }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Search Customer-->

        <div class="mb-3 form-group row mt-4">
            <label class="col-12 col-md-3 col-form-label">Invoice Title: </label>
            <div class="col-12 col-md-9">
                <input type="text" placeholder="Invoice Title" name="invoice_title" class="form-control" @keyup.stop="updateWorkingInvoiceDataField('invoice_title', $event.target.value)" autocomplete="off"
                    v-model="workingInvoiceData.invoice_title"/>
            </div>
        </div>

        <div class="mb-3 form-group row">
            <label class="col-12 col-md-3 col-form-label">Invoice Description: </label>
            <div class="col-12 col-md-9">
                <textarea name="invoice_description" placeholder="Invoice Description" class="form-control" @keyup.stop="updateWorkingInvoiceDataField('invoice_description', $event.target.value)"
                    v-model="workingInvoiceData.invoice_description"></textarea>
            </div>
        </div>
        <SubmitButton :submitting="submitting" v-if="!editing"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import customerService from '@/service/CustomerService';
import invoiceService from '@/service/InvoiceService';
    export default {
        name: "InvoiceForm",
        components: { SubmitButton },
        data(){
            return {
                customer: {
                    search_loading: false,
                    isDropdownOpen: false,
                    selected: '',
                    searchIsSet: false,
                    searchTerm: '',
                    reset: false,
                    list: [],
                },
                submitting: false,
                errors: {}
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledWorkingInvoiceData:  {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['invoiceAdded', 'invoiceUpdated'],
        computed: {
            workingInvoiceData(){
                if(this.editing){
                    return this.pulledWorkingInvoiceData;
                } else {
                    return {};
                }
            }
        },
         methods: {
            updateWorkingInvoiceDataField(field, value){
                this.workingInvoiceData[field] = value;
            },
            submitInvoiceForm(){
                this.submitting = true;
                let data = this.workingInvoiceData;
                this.errors = {};
                if (!this.customer.searchIsSet) this.errors.customerError = "Customer is required!";
                if (Object.keys(this.errors).length) return;

                if(!this.editing){
                   new Promise((resolve, reject) => {
                        if(data.customersID && data.invoice_title){
                            this.submitting = false;
                            invoiceService.create(data).then((response) => {
                                if(response.data.status == 200){
                                    this.$emit('invoiceAdded', response.data.record);
                                    this.createToast('Success', 'Invoice successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            reject('No Invoice Data to Send');
                        }
                    });
                }
                else{
                    new Promise((resolve, reject) => {
                        invoiceService.update(data.invoiceID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('invoiceUpdated');
                                this.createToast('Success', 'Invoice successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            searchCustomers(){
                this.customer.search_loading = true;
                this.customer.isDropdownOpen = true;
                let payload = {searchClass: 'Customer', searchMethod: 'searchByNames', term: this.customer.searchTerm};
                new Promise((resolve, reject) => {
                    customerService.search(payload).then((response) => {
                       if(response.data.status === 200){
                            this.customer.search_loading = false;
                            this.customer.list = response.data.record;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                       }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                   });
                });
            },
            selectCustomer(customer){
                this.customer.searchIsSet = true;
                this.customer.reset = true;
                this.customer.selected = customer;
            },
            clearCustomerList(){
                this.customer.searchIsSet = false;
                this.customer.reset = false;
                this.customer.selected = {};
                this.customer.list = [];
                this.customer.isDropdownOpen = false;
                this.customer.searchTerm = '';
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingInvoiceData = {};
                this.customer = {}
            },
        },
        created(){
            this.customer.isDropdownOpen = false;
            if(this.editing){
                this.customer.searchIsSet = true;
                this.customer.selected = this.workingInvoiceData.customer;
            }
        },
    };
</script>
<style scoped>
.dropdown-wrapper {
    height: auto;
    max-height: 200px!important;
    overflow-y: scroll;
    border:  none;
}

.dropdown-wrapper::-webkit-scrollbar {
    width: 10px;
    border-radius: 50px;
}

.dropdown-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}

.dropdown-wrapper::-webkit-scrollbar-thumb {
   background-color: #efefef;
   border-radius: 19px;
}

.dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #888;
    transition: 0.3s;
}
</style>
