<template>
    <div class="container mt-5">
        <div class="row">
            <!-- BEGIN INVOICE -->
            <div class="col-xs-12">
                <div class="grid invoice">
                    <div class="grid-body divToPrint">
                        <div class="invoice-title">
                            <div class="row">
                                <div class="col-xs-12">
                                    <img src="../../assets/global-images/stm-logo.png" alt="Logo" height="35" style="background: grey;" />
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-xs-12">
                                    <h2>{{pulledInvoiceData.invoice_title}}<br>
                                    <span class="small">invoice #{{pulledInvoiceData.invoiceID}}</span></h2>
                                </div>
                                <div class="col-xs-12">
                                    <p>Description: {{pulledInvoiceData.invoice_description}}</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    {{pulledInvoiceData.customer?.customer_company_name}}<br>
                                    {{pulledInvoiceData.customer?.customer_street}}<br>
                                    {{pulledInvoiceData.customer?.customer_city}}, {{pulledInvoiceData.customer?.customer_state}}
                                    {{pulledInvoiceData.customer?.customer_zip}}<br>
                                    <p>Phone:&nbsp; {{pulledInvoiceData.customer?.customer_phone}}</p>
                                </address>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <h3>Items</h3>
                                <table class="table table-striped">
                                    <thead>
                                        <tr class="line">
                                        <td>#</td>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Length</th>
                                            <th scope="col">Hight</th>
                                            <th scope="col">Width</th>
                                            <th scope="col" class="text-center">Quantity</th>
                                            <th scope="col">Cost</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row, index in invoiceItemList" :key="index"  class="line">
                                            <td>{{index + 1}}</td>
                                            <td class="text-start" scope="row" v-if="row.product?.product_name">{{row.product?.product_name}} </td>
                                            <td scope="row" v-else>{{row?.product_custom_name}} </td>
                                            <td data-title="purchase_order_item_qty">{{row.invoice_item_length}}</td>
                                            <td data-title="purchase_order_item_qty">{{row.invoice_item_height}}</td>
                                            <td data-title="purchase_order_item_qty">{{row.invoice_item_width}}</td>
                                            <td data-title="purchase_order_item_qty">{{row.invoice_item_quantity}}</td>
                                            <td data-title="invoice_item_price">{{numberWithCommas(row.invoice_item_price)}}</td>
                                            <td data-title="invoice_item_tax">{{numberWithCommas(row.invoice_item_tax)}}</td>
                                            <td data-title="purchase_order_total">{{numberWithCommas(row.invoice_item_total)}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7"></td><td><strong>Tax</strong></td>
                                            <td v-if="pulledInvoiceData?.invoice_sales_tax"><strong>{{numberWithCommas(pulledInvoiceData.invoice_sales_tax)}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colspan="7"></td><td><strong>Total</strong></td>
                                            <td v-if="pulledInvoiceData?.invoice_total"><strong>{{numberWithCommas(pulledInvoiceData.invoice_total)}}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button type="button" id="printButtonID" class="btn btn-success" value="Print" onclick = "window.print()" >
                                    <i class="bi bi-printer"></i>&nbsp;Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END INVOICE -->
        </div>
    </div>
</template>
<script>
// Services
import invoiceService from '@/service/InvoiceService';
export default {
    name: "InvoiceDetailViewPublic",
    data(){
        return{
            pulledInvoiceData: {},
            invoiceItemList: []
        }
    },
    methods: {
        getInvoiceByUUID(){
            let UUID = this.$route.params.uuid;
            new Promise((resolve, reject) => {
                invoiceService.getByID(UUID).then((response) => {
                    if(response.data.status === 200){
                        this.pulledInvoiceData = response.data.record;
                        this.invoiceItemList = response.data.record.invoice_items;
                        resolve();
                    }
                    else{
                        this.createToast('Error', response.data.message, 'danger');
                        reject(response.data.message);
                    }
                }, (error) => {
                    this.createToast('Error', 'Action failed!', 'danger');
                    reject(error);
                });
            });
        },
    },
    mounted(){
        this.getInvoiceByUUID();
    }
}
</script>
<style scoped>
@media print {
    #printButtonID {
      display: none;
    }

    .divToPrint {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }

    @page {
        size: auto;
        margin: 25px;
    }
}
body, #app {
    margin-top:20px;
    background:#eee!important;
}

.invoice {
    padding: 30px;
}

.invoice h2 {
	margin-top: 0px;
	line-height: 0.8em;
}

.invoice .small {
	font-weight: 300;
}

.invoice hr {
	margin-top: 10px;
	border-color: #ddd;
}

.invoice .table tr.line {
	border-bottom: 1px solid #ccc;
}

.invoice .table td {
	border: none;
}

.invoice .identity {
	margin-top: 10px;
	font-size: 1.1em;
	font-weight: 300;
}

.invoice .identity strong {
	font-weight: 600;
}

.grid {
    position: relative;
	width: 100%;
	background: #fff;
	color: #666666;
	border-radius: 2px;
	margin-bottom: 25px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
</style>
