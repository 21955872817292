<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Job View</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="card card-body">
                    <!-- Search -->
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search by Job Name" v-model="searchTerm" @keyup="searchJobs" />
                    </div>
                    <!-- /Search -->

                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Job Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Suite</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody v-if="jobPaginatedList.length">
                                <tr v-for="row in jobPaginatedList" v-bind:key="row.jobID">
                                    <th scope="row">{{row.job_name}}</th>
                                    <td data-title="employee_address">{{row.job_street}} <br> {{row.job_city}} {{row.job_state}}, {{row.job_zip}}</td>
                                    <td data-title="actions">{{row.job_suite}}</td>
                                    <td data-title="actions">
                                        <router-link :to="{ name: 'JobDetailView', params: {ID: row.jobID}}" class="btn">
                                            <i class="bi bi-eye"></i> view/edit </router-link>
                                        <button type="button" v-on:click="removeJob(row)" class="ml-2 btn"><i class="bi bi-trash"></i> remove</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <div class="alert alert-warning text-center">No Jobs...</div>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="9">
                                        <PaginationButtons
                                            :record-count="recordCount"
                                            :current-page="currentPage"
                                            sort-direction="ASC"
                                            sort-by="job_name"
                                            @paginate="paginate"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <ul v-if="newlyAddedJobs">
                            <li v-for="newJob in newlyAddedJobs" :key="newJob.jobID">
                                <router-link :to="{ name: 'JobDetailView', params: {ID: newJob.jobID}}" class="btn">{{newJob.job_fname}} {{newJob.job_name}}<span v-if="newJob"> - {{newJob.job_name}}</span></router-link>
                            </li>
                        </ul>

                        <!-- Job Form -->
                        <button type="button" class="btn btn-outline-secondary mb-3" v-on:click="showJobForm = !showJobForm">
                            <i class="bi bi-plus-circle-fill"></i> add a job
                        </button>
                        <transition name="fade">
                            <div v-if="showJobForm" class="card">
                                <div class="card-header">
                                    <h6>
                                        Add Job Form
                                    </h6>
                                     <button class="btn-close" aria-label="Close" type="button" v-on:click="showJobForm = !showJobForm"></button>
                                </div>
                                <div class="card-body">
                                    <JobForm
                                        @jobAdded="jobAdded"
                                    />
                                </div>
                            </div>
                        </transition>
                        <!-- /Job Form -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import JobForm from '@/components/Jobs/JobForm';
// Services
import jobService from '@/service/JobService';
    export default {
        name: "JobsView",
        components: {
            JobForm,
			PaginationButtons
        },
         data() {
            return {
                queryParameters: {'pagStart':0, 'pagLimit':15,'orderBy':'job_name', 'orderDirection':'ASC'},
                currentPage: 1,
                showJobForm: false,
                searchTerm: '',
                newlyAddedJobs: [],
                jobPaginatedList: [],
                recordCount: 0
            };
        },
        methods: {
            pullJobList(state = null, value = null){
                if(state = 'hasPageStart'){
                    this.queryParameters.pagStart = value;
                }
                new Promise((resolve, reject) => {
                    jobService.getPaginatedtList(this.queryParameters).then((response) => {
                        if(response.data.status === 200){
                            this.jobPaginatedList = response.data.record;
                            this.recordCount = response.data.recordCount;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data?.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            paginate(pagStart){
                this.pullJobList('hasPageStart', pagStart)
            },
            searchJobs(){
                if(this.searchTerm.length >= 3){
                    new Promise((resolve, reject) => {
                        let payload = {searchClass: 'Job', searchMethod: 'searchByNames', term: this.searchTerm};
                        jobService.search(payload).then((response) => {
                            if(response.data.status === 200){
                                this.jobPaginatedList = response.data.record;
                                this.recordCount = response.data.recordCount;
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                } else{
                    this.pullJobList();
                }
            },
            removeJob(job){
                if(confirm(`Are you sure you want to remove this job ${job.job_name}?`)){
                    new Promise((resolve, reject) => {
                    jobService.delete(job.jobID).then((response) => {
                        const index = this.jobPaginatedList.map(x => {
                            return x.jobID;
                        }).indexOf(job.jobID);
                            if(index !== -1) {
                                this.jobPaginatedList.splice(index, 1);
                                this.clearNewlyAddedJobs();
                            }
                            this.createToast('Success', 'Job successfully deleted!', 'success');
                            resolve();
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                       });
                    });
                };
            },
            jobAdded(data){
                this.pullJobList();
                this.newlyAddedJobs.push(data);
                this.showJobForm = false;
            },
            clearNewlyAddedJobs(){
                this.newlyAddedJobs = [];
            }
        },
        mounted() {
            this.pullJobList();
        }
    };
</script>
