<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Sales Invoice</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <div class="card-body">
                        <InvoiceForm
                            @invoiceAdded="invoiceAdded"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InvoiceForm from '@/components/Sales/InvoiceForm';
    export default {
        name: "InvoiceCreateView",
        components: {
            InvoiceForm
        },
        methods: {
            invoiceAdded(invoice){
                this.$router.push({ name: 'SalesInvoiceDetailView', params: {ID: invoice.invoiceID}});
            }
        }
    };
</script>
