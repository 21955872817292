import userService from '../../service/UserService';
export const namespaced = true;

export const state = {
    userData: {},
    authenticated: false,
    authenticationErrorMessage: '',
    resetPasswordKey: '',
};

export const getters = {
    authenticated: state => {
        return state.authenticated
    },
    userData: state => {
        return state.userData
    },
    authenticationErrorMessage: state => {
        return state.authenticationErrorMessage;
    },
    resetPasswordKey: state => {
        return state.resetPasswordKey
    },
};

export const mutations = {
    updateUserData(state, userData){
        state.userData = userData
    },
    updateUserDataProfilePic(state, newFileName){
        state.userData.user_profile_pic_file = newFileName;
    },
    setAuthentication (state, authenticated) {
        state.authenticated = authenticated;
        localStorage.setItem('authenticated', authenticated);
    },
    setAuthenticationErrorMessage(state, msg){
        state.authenticationErrorMessage = msg;
    },
    initialiseStore(state){
        if (localStorage.getItem('authenticated') == 'true') {
            state.authenticated = true;
        }
        else{
            state.authenticated = false;
        }
    },
    setResetPasswordKey(state, string){
        state.resetPasswordKey = string;
    },
};

export const actions = {
    isAuthenticated({commit}){
        return new Promise((resolve, reject) => {
            userService.checkAuthentication().then((response) => {
                if (response.data.status === 200){
                    commit('updateUserData', response.data.record);
                    commit('setAuthentication',  true );
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }
                else{
                    commit('setAuthentication',  false );
                    reject();
                }
            }, (error) => {
                console.log(error);
                commit('setAuthentication',  false );
                reject();
            });
        });
    },
    authenticateUser({commit}, payload){
        return new Promise((resolve, reject) => {
            userService.authenticate(payload.username, payload.password).then((response) => {
                if (response.data.status === 200){
                    commit('setAuthentication', true);
                    commit('updateUserData', response.data.userData);
                    resolve();
                }
                else{
                    commit('setAuthenticationErrorMessage', 'User Credentials Not Accepted...');
                    reject();
                }
            }, (error) => {
                commit('setAuthenticationErrorMessage', 'There was a server error. Please try again or contact the Simple To Manage Team.'+error);
                reject();
            });
        });
    },
    updateUserData({commit, state}){
        return new Promise((resolve, reject) => {
           userService.update(state.userData.userID, state.userData).then((response) => {
               if(response.data.status === 200){
                   console.log(response.data.record);
                   commit('updateUserData', response.data.record);
                   resolve(response);
               }
               else{
                   reject(response.data.message);
               }
            }, (error) => {
               reject(error);
            });
        });
    },
    updateUserDataProfilePic({commit}, data){
        commit('updateUserDataProfilePic', data.record.newFileName);
    },
    setAuthentication({commit}, payload){
        commit('setAuthentication', payload);
    },
    setResetPasswordKey({commit}, payload){
        commit('setResetPasswordKey', payload);
    },
    logOut({commit}){
        return new Promise((resolve, reject) => {
            userService.logOut().then((response) => {
                if(response.data.status === 200){
                    commit('setAuthentication', false);
                    resolve();
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    }
};
