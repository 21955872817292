import http from "../api";

class VendorService {

    /**
     * Get Vendors with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Vendor", {params: parameters});
    }

    getByID(id){
        return http.get("/Vendor/"+id);
    }

    create(data) {
        return http.post("/Vendor", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Vendor', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Vendor', {params: {vendorsID: id}});
    }

    /** Vendor Notes **/
    createVendorNote(data){
        return http.post("/VendorNote", data)
    }
}

export default new VendorService();
