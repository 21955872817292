<template>
    <h4>Inventory View</h4>
</template>

<script>
    export default {
        name: "InventoryView"
    }
</script>

<style scoped>

</style>