
<template>
    <ul class="pagination">
        <li class="page-item"><a href="#" class="page-link" @click.stop.prevent="clickFirstPage" :disabled="isInFirstPage">First</a></li>
        <li class="page-item"><a href="#" class="page-link" @click.stop.prevent="clickPreviousPage" :disabled="isInFirstPage">Previous</a></li>

        <!-- Range of pages -->
        <li class="page-item" v-for="index in calculateTotalPages" :key="index"><a href="#" class="page-link" @click.stop.prevent="clickPage(getPageStart(index))">{{ index }}</a></li>
        <!-- /Range of pages -->

        <li class="page-item"><a href="#" class="page-link" @click.stop.prevent="clickNextPage" :disabled="isInLastPage">Next</a></li>
        <li class="page-item"><a href="#" class="page-link" @click.stop.prevent="clickLastPage" :disabled="isInLastPage">Last</a></li>
    </ul>
</template>

<script>
    export default {
        name: "Paginationas",
        data(){
            return {
                currentPage: 1,
                totalPages: 0
            }
        },
        props: {
            recordCount: {
              type: Number,
              required: true,
              default: 0
            },
            paginationLimit: {
                type: Number,
                required: false,
                default: 15
            },
            paginationStart: {
                type: Number,
                required: false,
                default: 0
            },
            sortBy: {
                type: String,
                required: false,
                default: null
            },
            sortDirection: {
                type: String,
                required: false,
                default: 'ASC'
            }
        },
        computed: {
            calculateTotalPages: function(){
                return Math.ceil(this.recordCount / this.paginationLimit);
            },
            isInFirstPage() {
                return (this.currentPage === 1 || this.currentPage === 0);
            },
            isInLastPage() {
                return this.currentPage ===  Math.ceil(this.recordCount / this.paginationLimit);
            }
        },
        emits: ['paginate'],
        methods: {
            clickPage(pageStart){
                this.currentPage = pageStart;
                this.$emit('paginate', pageStart);
            },
            clickFirstPage(){
                this.currentPage = 0;
                this.$emit('paginate', 0);
            },
            clickNextPage(){
                this.currentPage++;
                this.$emit('paginate', this.getPageStart(this.currentPage));

            },
            clickPreviousPage(){
                this.currentPage--;
                this.$emit('paginate', this.getPageStart(this.currentPage));
            },
            clickLastPage(){
                this.currentPage =  Math.ceil(this.recordCount / this.paginationLimit);
                this.$emit('paginate', this.getPageStart(this.currentPage));
            },
            getPageStart(index){
                return (index * this.paginationLimit) - this.paginationLimit;
            }
        }
    }
</script>

<style scoped>

</style>
