<template>
	<div v-if="assets.length" class="mt-3 card-group">
		<div class="row row-cols-1 row-cols-md-2 g-4">
			<div class="col" v-for="row in assets" :key="row.eij_assetsID">
				<div class="card h-100">
					<a :href="'https://stmapi.simpletomanage.com/files/' + row.eij_asset_file_name + '/'+row.eij_asset_file_name+'.' + row.file_extension" target="_blank">

						<img class="card-img-top" :src="getImageCap(row)" />

					</a>
					<div class="card-body">
						<h5 class="card-title">{{ row.eij_asset_name }}</h5>
						<small class="text-muted">{{ row.original_file_name }}</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EIJAssetGallery",
	data(){
		return{
			extensionType: {
				jpg:  'img',
				jpeg: 'img',
				png:  'img',
				gif:  'img',
				mp4:  'vid',
				mpg:  'vid',
				mpeg: 'vid',
				mov:  'vid',
				avi:  'vid',
				m4v:  'vid',
				xlxs: 'xl',
				xls:  'xl',
				csv:  'xl',
				doc:  'word',
				docx: 'word',
				pdf:  'pdf',
			},
			imgType: {
				headerImage: 'self',
				tag: 'img'
			},
			vidType: {
				headerImage: 'video-file.png',
				tag: 'video'
			},
			xlType:{
				headerImage: 'excel-file.png',
				tag: 'img'
			},
			wordType:{
				headerImage: 'word-file.png',
				tag: 'img'
			},
			pdfType: {
				headerImage: 'pdf-file.png',
				tag: 'img'
			},
			defaultType: {
				headerImage: 'unknown-file.png',
				tag: 'img'
			}
		}
	},
	props: {
		assets: {
			type: Object,
			required: false,
			default: null,
		},
	},
	methods: {
		getImageCap(row){
			let extension = row.file_extension;
			if(this.extensionType[extension.toLowerCase()]){
				let objectName = this.extensionType[extension.toLowerCase()] + 'Type';
				if(this[objectName].headerImage == 'self'){
					return 'https://stmapi.simpletomanage.com/files/' + row.eij_asset_file_name + '/'+row.eij_asset_file_name+'.' + row.file_extension;
				}
				else{
					return require('@/assets//global-images/' + this[objectName].headerImage);

				}
			}
			else{
				return require('@/assets/global-images/unknown-file.png');
			}
		}
	},
	computed: {

	},
	mounted(){

	}
}
</script>

<style scoped>

</style>
