import http from "../api";

class UserService {
    getAll() {
        return http.get("/public/User/");
    }

    get(id) {
        return http.get('/User/${id}'+id);
    }

    create(data) {
        return http.post("/User", data);
    }

    update(id, data) {
        return http.patch('/User', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/User/${id}'+id);
    }

    deleteAll() {
        return http.delete('/User');
    }

    findByTitle(title) {
        return http.get('/User?title=${title}'+title);
    }

    authenticate(username, password){
        return http.post("/public/login/", {username: username, password: password});
    }

    logOut(){
        return http.post("/public/logout/");
    }

    checkAuthentication(){
        //sending this request should return the logged in users data
        return http.get('/User', { params: { getME: true } });
    }
}

export default new UserService();
