<template>
    <form v-on:submit.prevent="submitSupplyItemForm">
        <div class="form-group mb-3" v-if="!editing">
            <label>Choose The Parent Category</label>
            <select name="parent_categoryID" class="form-control" v-model="workingSupplyItemData.supply_categoryID" @change="updateWorkingSupplyItemDataField('supply_categoryID', $event.target.value)">
                <option :value="null" disabled selected> -- select category -- </option>
                <option
                    v-for="category in supplyCategories"
                    v-bind:key="category.supply_categoryID"
                    :value="category.supply_categoryID"
                    :selected="category.supply_categoryID"
                    >
                    {{ category.supply_category_name }}
                </option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">Item Name</label>
            <input type="text" placeholder="Item Name" class="form-control" name="supply_item_name" v-model="workingSupplyItemData.supply_item_name" v-on:keyup.stop="updateWorkingSupplyItemDataField('supply_item_name', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="mb-3">Item description</label>
            <textarea name="supply_item_desc" placeholder="Item description " class="form-control" v-model="workingSupplyItemData.supply_item_desc"  v-on:keyup.stop="updateWorkingSupplyItemDataField('supply_item_desc', $event.target.value)"></textarea>
        </div>
        <SubmitButton :submitting="submitting"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import supplyCategoriesService from '@/service/SupplyCategoryService';
import supplyItemService from '@/service/SupplyItemService';
    export default {
        name: "SupplyItemForm",
        components: { SubmitButton },
        data(){
            return {
                submitting: false,
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            supplyCategories: {
                required: false,
            },
            pulledWorkingSupplyItemData: {
                type: Object,
                required: false,
                default: null,
            }
        },
        emits: ['supplyItemAdded', 'supplyItemUpdated'],
        computed: {
            workingSupplyItemData(){
                if(this.editing){
                    return this.pulledWorkingSupplyItemData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingSupplyItemDataField(field, value){
                this.workingSupplyItemData[field] = value;
            },
            submitSupplyItemForm(){
                this.submitting = true;
                if(!this.editing){
                    if(!this.workingSupplyItemData.supply_categoryID){
                        this.setFirstCategory(this.supplyCategories);
                    }

                    return new Promise((resolve, reject) => {
                        supplyItemService.create(this.workingSupplyItemData).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.clearFormData();
                                this.createToast('Success', 'Supply Item Successfully added!', 'success');
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
                else{
                     return new Promise((resolve, reject) => {
                        supplyItemService.update(this.workingSupplyItemData.supply_itemID, this.workingSupplyItemData).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('supplyItemUpdated');
                                this.createToast('Success', 'Supply Item Successfully updated!', 'success');
                                this.clearFormData();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            setFirstCategory(payload){
                this.workingSupplyItemData.supply_categoryID = payload[0].supply_categoryID;
            },
            clearFormData(){
                this.workingSupplyItemData.supply_item_name = '';
                this.workingSupplyItemData.supply_item_desc = '';
            }
        },
    };
</script>
