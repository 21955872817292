<template>
	<div>
		<div class="row mt-5">
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Open Estimates
					</div>
					<div class="card-body">

					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Open Invoices
					</div>
					<div class="card-body">

					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Sales to Date
					</div>
					<div class="card-body">

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SalesView"
}
</script>

<style scoped>

</style>
