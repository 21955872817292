import http from "../api";

class UploadService {
    uploadProfileImage(formData){
        formData.append("altMethod","PUT");

        return http.post('/ProfilePic', formData);
    }
    
    uploadCompanyLogo(formData){
        formData.append("altMethod","PUT");

        return http.post('/CompanyLogo', formData);
    }
}

export default new UploadService();
