import http from "../api";

class TodoService {
    getAllByUser() {
        return http.get("/Todo/");
    }

    getAllByCompany(){

    }

    get() {
        return http.get('/Todo/'+1);
    }

    create(data) {
        return http.post("/Todo", data);
    }

    updateUserTodoItem(data) {
        return http.patch('/Todo', JSON.stringify(data));
    }

    deleteUserTodoItem(data) {
        return http.delete('/Todo', {data: data});
    }
}
export default new TodoService();
