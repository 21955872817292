<template>
    <div class="container-fluid">
        <section v-if="pageLevel == 1" class="login-form center-box">
            <form @submit.prevent="sendResetPasswordEmail()">
                <h4 class="text-center">Find Your Account</h4>
                <div class="mb-2 mt-1 row">
                    <label for="email" class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-12">
                        <input
                        type="text"
                        class="form-control"
                        v-model="email"
                        placeholder="Type your email here..."
                        autocomplete="off"
                    />
                    </div>
                </div>
                <SubmitButton
                    :submitting="submittingLevel1"
                    label="Search"
                    label_action="Searching...."
                    width="w-100"/>
            </form>
        </section>
        <section v-else-if="pageLevel == 2" class="login-form center-box">
            <h3>Verify Your Email Address</h3>
                <p>
                    Before proceeding, please check your email for a verification link.
                </p>
                <div>
                    <span>or</span>
                </div>
                <p>
                    If you did not receive the email click the button below to request another
                </p>
                <div @click="sendResetPasswordEmail()">
                    <SubmitButton
                    :submitting="submittingLevel2"
                    label="Request another"
                    label_action="Requesting...."
                    width="w-100"/>
                </div>
        </section>
        <section v-else-if="pageLevel == 3" class="login-form center-box">
        <h3>Successfully Resent!</h3>
            <p>A fresh verification link has been sent to your email address.</p>
            <br />
            <router-link
                :to="{ name: 'Home' }"
                class="btn btn-block btn-fill btn-success w-100"
                type="button"
                > Okay
            </router-link>
        </section>
    </div>
</template>
<script>
import http from "@/api";
    export default {
        name: "CheckIdentity",
        data() {
            return {
                pageLevel: 1,
                submittingLevel1: false,
                submittingLevel2: false,
                email: '',
                errorMessage: '',
            };
        },
        methods: {
            submit(){
                this.submitting = true;
            },
            sendResetPasswordEmail(){
                 if(this.pageLevel == 1){
                    this.submittingLevel1 = true;
                }
                else {
                    this.submittingLevel2 = true;
                }

                const string_length = 85,
                    generatedRey = [...Array(string_length)].map(i=>(~~(Math.random()*36)).toString(36)).join(''),
                    data = {
                        key: generatedRey,
                        email: this.email
                    };

                new Promise((resolve, reject) => {
                    http.post("public/reset-password/", JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Credentials': true,
                                'Content-Type': 'application/json; charset=UTF-8'
                            }
                        }).then((response) => {
                            if(response.data.status === 200){
                                if(this.pageLevel == 1){
                                    this.pageLevel = 2;
                                }
                                else {
                                    this.pageLevel = 3;
                                }
                                this.submittingLevel1 = false;
                                this.createToast('Success', response.data.message, 'success');
                            }
                            else {
                                this.email = '';
                                this.submittingLevel1 = false;
                                this.createToast('User Error', response.data.message, 'danger');
                            }
                        });
                    }, (error) => {
                        this.submittingLevel1 = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                });
            }
        },
    };
</script>
