<template>
  <div class="mb-3 mt-4 m-y">
    <button v-if="submitting" disabled="true" class="btn btn-large btn-success btn-disabled" :class="width">{{label_action}}</button>
    <button v-else type="submit" class="btn btn-large btn-success" :class="width">{{label}}</button>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "SubmitButton",
  props: {
    submitting: {
      default: false
    },
    label: {
      type: String,
      default: 'submit'
    },
    label_action: {
      type: String,
      default: 'submitting...'
    },
    width: {
      this: String,
      default: ''
    }
  }
};
</script>