import http from "../api";

class EstimateItemService {
    getAll() {
        return http.get("/EstimateItem");
    }

    getByID(ID) {
        return http.get('/EstimateItem/'+ID);
    }

    create(data) {
        return http.post("/EstimateItem", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/EstimateItem', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/EstimateItem/', {params: {estimate_itemID: id}});
    }
}

export default new EstimateItemService();