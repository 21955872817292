import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

/**CSS**/
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

/**Cropper**/
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const stmApp = createApp(App);

/**Components**/
import SubmitButton from '@/components/Utilities/SubmitButton';
import PopupAlertContainer from '@/components/Utilities/PopupAlertContainer';
import PopupAlertTemplate from '@/components/Utilities/PopupAlertTemplate';
stmApp.component('SubmitButton', SubmitButton)
stmApp.component('PopupAlertContainer', PopupAlertContainer);
stmApp.component('PopupAlertTemplate', PopupAlertTemplate);

/**Mixin**/
import ToastPopupMixin from '@/mixin/ToastPopupMixin';
import NumberWithCommasMixin from '@/mixin/NumberWithCommasMixin';
stmApp.mixin(ToastPopupMixin);
stmApp.mixin(NumberWithCommasMixin);

stmApp.use(VueCropper);
stmApp.use(router);
stmApp.use(store);

stmApp.mount('#app');