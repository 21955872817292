<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Products</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="card card-body">
                    <ul v-if="productTree">
                        <li v-for="categoryRow, index in productTree" :key="index">
                            <label class="form-check-label editable-field" contenteditable="true"
                                   @keyup="keyUpEdit($event, categoryRow)"
                                   v-on:keydown.enter="keyDownEdit($event, categoryRow)"
                                   v-on:blur="keyDownEdit($event, categoryRow)"
                                   >
                                {{  categoryRow.product_category_name }}
                            </label>&nbsp;
                            <span class="update-icon" @click="updateCategory($event, categoryRow)">
                                <i class="bi bi-arrow-left-circle"></i>
                            </span>

                            <ul v-if="categoryRow.subCategories">
                                <li v-for="subCategoryRow, index in categoryRow.subCategories" :key="index">
                                    <label class="form-check-label editable-field" contenteditable="true"
                                        @keyup="keyUpEdit($event, subCategoryRow)"
                                        v-on:keydown.enter="keyDownEdit($event, subCategoryRow)"
                                        v-on:blur="keyDownEdit($event, subCategoryRow)"
                                    >
                                        {{ subCategoryRow.product_category_name }}
                                    </label>&nbsp;
                                    <span class="update-icon" @click="updateCategory($event, subCategoryRow)">
                                        <i class="bi bi-arrow-left-circle"></i>
                                    </span>

                                    <ul v-if="subCategoryRow.products">
                                        <li v-for="productRow, index  in subCategoryRow.products" :key="index">
                                            <router-link :to="{ name: 'ProductDetailView', params: {ID: productRow.productsID}}" >
                                                {{ productRow.product_name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul v-if="categoryRow.products">
                                <li v-for="productRow in categoryRow.products" v-bind:key="productRow.productsID">
                                    <router-link :to="{ name: 'ProductDetailView', params: {ID: productRow.productsID}}" >
                                        {{ productRow.product_name }}
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">
                        <h6>Categories</h6>
                    </div>
                    <div class="card-body">
                        <ProductCategoryForm
                            :productCategories="productCategories"
                            @productCategoryAdded="productCategoryAdded"
                        />
                    </div>
                </div>
                <br>

                <div class="card">
                    <div class="card-header">
                        <h6>New Product Form</h6>
                    </div>
                    <div class="card-body">
                        <ProductForm
                            :productCategories="productCategories"
                            @productAdded="productAdded"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaginationButtons from '@/components/Utilities/PaginationButtons';
import ProductForm from '@/components/Products/ProductForm';
import ProductCategoryForm from '@/components/Products/ProductCategoryForm';
// Services
import productCategoryService from '@/service/ProductCategoryService';
    export default {
        name: "ProductsView",
        components: {
            ProductForm,
            ProductCategoryForm
        },
        data() {
            return {
                productTree: [],
                productCategories: [],
                categoryID: null,
                newCategoryInput: null,
                makeSubCategoryCheck: false,
            }
        },
        methods: {
            pullProductTree(reset = false){
                new Promise((resolve, reject) => {
                productCategoryService.getAll().then((response) => {
                    if(response.data.status === 200){
                        this.productTree = response.data.record;
                            for(let i = 0; i < this.productTree.length; i++){
                                let payloadA = {
                                    product_categoryID: this.productTree[i].product_categoryID,
                                    product_category_name: this.productTree[i].product_category_name
                                };
                                this.productCategories.push(payloadA)

                                if (this.productTree[i].subCategories){
                                    for(let j = 0; j < this.productTree[i].subCategories.length; j++){
                                        let payloadB = {
                                            product_categoryID: this.productTree[i].subCategories[j].product_categoryID,
                                            product_category_name: this.productTree[i].subCategories[j].product_category_name
                                        };
                                        this.productCategories.push(payloadB);
                                    }
                                }
                            }
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            keyUpEdit(e, item){
                if(e.target.innerText.length > 50){
                    this.createToast('Error', 'Category name reached the limit!', 'danger');
                    e.target.innerText = '';
                    return;
                }
                let sibling = e.target.nextElementSibling;
                sibling.classList.add('show');
            },
            keyDownEdit(e, item){
                let sibling = e.target.nextElementSibling;
                item.product_category_name = e.target.innerText;
                this.updateCategory(e, item);
                setTimeout(function(){
                    sibling.classList.remove('show');
                }, 2000);
            },
            updateCategory(e, item){
                e.target.classList.remove('show');
                if (!e.target.matches('.editable-field')){
                    return;
                }
                else {
                    item.product_category_name = e.target.innerText;
                }

                new Promise((resolve, reject) => {
                productCategoryService.update(item.product_categoryID, { 'product_category_name': item.product_category_name}).then((response) => {
                    if(response.data.status === 200){
                        this.createToast('Success', 'Product category successfully updated!', 'success');
                        resolve(response);
                    }
                    else{
                        this.createToast('Error', response.data.message, 'danger');
                        reject(response.data.message);
                    }
                }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            productCategoryAdded(category){
                this.productCategories.push(category);

                if(!category.parent_categoryID){
                    this.productTree.push(category);
                }
                else {
                    for(let i = 0; i < this.productTree.length; i++){
                        if(this.productTree[i].product_categoryID == category.parent_categoryID){
                            if(this.productTree[i].subCategories){
                                this.productTree[i].subCategories.push(category);
                            }
                            else {
                                this.productTree[i].subCategories = [category];
                            }
                        }
                    }
                }
            },
            productAdded(item){
                for(let i = 0; i < this.productTree.length; i++){
                    if(this.productTree[i].product_categoryID == item.product_categoryID){
                        if(this.productTree[i].products){
                            this.productTree[i].products.push(item);
                        }
                        else {
                            this.productTree[i].products = [item];
                        }
                    }
                }
            }
        },
        mounted() {
            this.pullProductTree();
        }
    };
</script>
