import http from "../api";

class ContactService {

    /**
     * Get Contacts with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Contact", {params: parameters});
    }

    getByID(id){
        return http.get("/Contact/"+id);
    }

    create(data) {
        return http.post("/Contact", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Contact', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Contact', {params: {contactsID: id}});
    }

    /** Contact Notes **/
    createContactNote(data){
        return http.post("/ContactNote", data)
    }
}

export default new ContactService();
