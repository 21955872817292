<template>
    <div class="container-fluid app-view-containe mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Supplies</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="card">
                    <div class="card-body">
                       <ul v-if="supplyItemTree">
                            <li v-for="categoryRow, index in supplyItemTree" :key="index">
                                <label class="form-check-label editable-field" contenteditable="true"
                                       @keyup="keyUpEdit($event, categoryRow)"
                                       v-on:keydown.enter="keyDownEdit($event, categoryRow)"
                                       v-on:blur="keyDownEdit($event, categoryRow)"
                                       >
                                    {{  categoryRow.supply_category_name }}
                                </label>&nbsp;
                                <span class="update-icon" @click="updateCategory($event, categoryRow)">
                                    <i class="bi bi-arrow-left-circle"></i>
                                </span>

                                <ul v-if="categoryRow.subCategories">
                                    <li v-for="subCategoryRow, index in categoryRow.subCategories" :key="index">

                                        <label class="form-check-label editable-field" contenteditable="true"
                                            @keyup="keyUpEdit($event, subCategoryRow)"
                                            v-on:keydown.enter="keyDownEdit($event, subCategoryRow)"
                                            v-on:blur="keyDownEdit($event, subCategoryRow)"
                                        >
                                            {{  subCategoryRow.supply_category_name }}
                                        </label>&nbsp;
                                        <span class="update-icon" @click="updateCategory($event, subCategoryRow)">
                                            <i class="bi bi-arrow-left-circle"></i>
                                        </span>

                                        <ul v-if="subCategoryRow.supplyItems">
                                            <li v-for="supplyRow, index in subCategoryRow.supplyItems" :key="index">
                                                <router-link :to="{ name: 'SupplyDetailView', params: {ID: supplyRow.supply_itemID}}" >
                                                    {{ supplyRow.supply_item_name }} &nbsp;
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <ul v-if="categoryRow.supplyItems">
                                    <li v-for="supplyRow in categoryRow.supplyItems" v-bind:key="supplyRow.supply_itemID">
                                        <router-link :to="{ name: 'SupplyDetailView', params: {ID: supplyRow.supply_itemID}}" >
                                            {{ supplyRow.supply_item_name }}  &nbsp;
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <h6>Categories</h6>
                    </div>
                    <div class="card card-body">
                	    <SupplyCategoryForm
                            :supplyCategories="supplyCategories"
                            @supplyCategoryAdded="supplyCategoryAdded"
                        />

                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        <h6>New Supply Item Form</h6>
                    </div>
                    <div class="card card-body">
                        <SupplyItemForm
                            :supplyCategories="supplyCategories"
                            @supplyItemAdded="supplyItemAdded"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SupplyCategoryForm from '@/components/Supplys/SupplyCategoryForm';
import SupplyItemForm from '@/components/Supplys/SupplyItemForm';
// Services
import supplyCategoryService from '@/service/SupplyCategoryService';
    export default {
        name: "SupplyView",
        components: {
            SupplyCategoryForm,
        	SupplyItemForm,
        },
        data(){
            return {
                supplyItemTree: [],
                supplyCategories: [],
            }
        },
        methods: {
            pullSupplyTree(reset = false){
                new Promise((resolve, reject) => {
                    supplyCategoryService.getAll().then((response) => {
                        if(response.data.status === 200){
                            this.supplyItemTree = response.data.record;
                            for(let i = 0; i < this.supplyItemTree.length; i++){
                                let payloadA = {
                                    supply_categoryID: this.supplyItemTree[i].supply_categoryID,
                                    supply_category_name: this.supplyItemTree[i].supply_category_name
                                };
                                this.supplyCategories.push(payloadA)

                                if(this.supplyItemTree[i].subCategories){
                                    for(let j = 0; j < this.supplyItemTree[i].subCategories.length; j++){
                                        let payloadB =  {
                                            supply_categoryID: this.supplyItemTree[i].subCategories[j].supply_categoryID,
                                            supply_category_name: this.supplyItemTree[i].subCategories[j].supply_category_name
                                        }
                                        this.supplyCategories.push(payloadB)
                                    }
                                }
                                resolve();
                            }
                        }
                         else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                         }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            keyUpEdit(e, item){
                if(e.target.innerText.length > 50){
                    this.createToast('Error', 'Category name reached the limit!', 'danger');
                    e.target.innerText = '';
                    return;
                }
                let sibling = e.target.nextElementSibling;
                sibling.classList.add('show');
            },
            keyDownEdit(e, item){
                let sibling = e.target.nextElementSibling;
                this.updateCategory(e, item);
                setTimeout(function(){
                    sibling.classList.remove('show');
                }, 2000);
            },
            updateCategory(e, item){
                e.target.classList.remove('show');
                if (!e.target.matches('.editable-field')){
                    return;
                }
                else {
                    item.supply_category_name = e.target.innerText;
                }

                new Promise((resolve, reject) => {
                supplyCategoryService.update(item.supply_categoryID, { 'supply_category_name': item.supply_category_name}).then((response) => {
                    if(response.data.status === 200){
                        this.createToast('Success', 'Supply category successfully updated!', 'success');
                        resolve(response);
                    }
                    else{
                        this.createToast('Error', response.data.message, 'danger');
                        reject(response.data.message);
                    }
                }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            supplyItemAdded(){
                this.pullSupplyTree();
            },
            supplyCategoryAdded(){
                this.pullSupplyTree();
            },
        },
        created() {
            this.pullSupplyTree();
        },

    };
</script>
