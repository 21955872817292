<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>{{pulledEmployeeWorkingData.employee_first_name}} {{pulledEmployeeWorkingData.employee_first_name}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card mb-4">
                    <div class="card-body">
                        <EmployeeForm
                            :pulledEmployeeWorkingData="pulledEmployeeWorkingData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6">

              <!-- Employee Notes Card -->
                <div class="card">
                    <div class="card-header">
                        <h6>Employee Notes</h6>
                    </div>
                    <div class="card-body">
                        <!-- Note Form -->
                        <form id="newEmployeeNote" @submit.prevent="createNewNote">
                            <div class="mb-3">
                                <label class="form-label">New Employee Note</label>
                                <textarea name="employee_note" class="form-control" placeholder="Write a note here..." v-model="newNoteInput"></textarea>
                            </div>
                            <SubmitButton :submitting="noteSubmitting"/>
                        </form>
                        <!-- /Note Form -->

                        <div v-if="pulledEmployeeWorkingData.notes">
                            <div class="card mb-2 mt-3" v-for="row, index in pulledEmployeeWorkingData.notes" :key="index">
                                <div class="card-body">
                                    <p>{{ row.employee_note }}</p>
                                </div>
                                <div class="card-footer">
                                    <span class="float-start">{{ row.user.user_fname }} {{ row.user.user_lname }}</span>&nbsp; <span class="float-end">{{ row.created }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!-- /Employee Notes Card -->

            </div>
        </div>
    </div>
</template>

<script>
import EmployeeForm from '@/components/Employees/EmployeeForm.vue';
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import employeeService from '@/service/EmployeeService';
    export default {
        name: "EmployeeDetailView",
        components: {
            EmployeeForm, SubmitButton
        },
        data(){
            return {
                newNoteInput: '',
                pulledEmployeeWorkingData: {},
                showContactForm: false,
                noteSubmitting: false
            }
        },
        methods: {
            createNewNote: function() {
                if(this.newNoteInput && this.newNoteInput != ''){
                    this.noteSubmitting = true;
                    let data = {employee_note: this.newNoteInput, employeeID: this.pulledEmployeeWorkingData.employeeID}
                    employeeService.createEmployeeNote(data).then((response) => {
                        this.noteSubmitting = false;
                        if(response.data.status === 200){
                            this.getEmployeeByID();
                            this.newNoteInput = '';
                            this.createToast('Success', 'Note successfully added!', 'success');
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.noteSubmitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                }
                else{
                    /**ToDo
                     * Handle empty note
                     **/
                }
            },
            getEmployeeByID(){
                this.employeeID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    employeeService.getByID(this.employeeID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledEmployeeWorkingData = response.data.record;
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
        },
        mounted(){
            this.getEmployeeByID();
        }
    };
</script>
