<template>
    <div class="container-fluid app-view-container">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>My Profile</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card card-body mb-3">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <img width="200" height="200" :src="profilePictureSrc(userData.user_profile_pic_file)" alt="Profile Picture"/>
                        </div>
                        <div class="col-12 col-md-4">
                            <button type="button" class="btn btn-outline-secondary mb-2 mt-3" @click="showProfilePictureCard = !showProfilePictureCard">
                                Update Profile Picture</button>
                        </div>
                    </div>
                    <form @submit.prevent="updateUserSubmit">
                        <div class="mb-3">
                            <label class="form-label">First Name</label>
                            <input type="text" placeholder="First Name" name="user_fname" class="form-control" v-model="userData.user_fname"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" placeholder="Last Name" name="user_lname" class="form-control" v-model="userData.user_lname"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" placeholder="Email" name="user_email" class="form-control" v-model="userData.user_email"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Phone Number</label>
                            <input type="text" placeholder="Phone Number" name="user_phone" class="form-control" v-model="userData.user_phone"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Username</label>
                            <input type="text" placeholder="Username" name="username" class="form-control" v-model="userData.username"/>
                        </div>

                        <SubmitButton :submitting="submitting" label="update" label_action="updating"/>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <!-- Profile Picture Card -->
                <transition name="fade">              
                    <div v-if="showProfilePictureCard"><hr>
                        <ImageCropper 
                            v-model="imageModel" 
                            :aspectRatio="1"
                            :cropBoxMovable="Boolean(false)"
                            :cropBoxResizable="Boolean(false)"
                            :viewMode="1"
                        />
                    </div>
                </transition>
                <!-- / Profile Picture Card -->
            </div>
        </div>
    </div>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton';
import ImageCropper from '@/components/Utilities/ImageCropper';
import UploadService from '@/service/UploadService';
import defaultProfilePicture from "@/assets/global-images/default-profile-picture.jpg";
    export default {
        name: "MyProfileView",
        components: {
            ImageCropper, SubmitButton
        },
        data() {
            return {
                submitted: false,
                imageModel: '',
                submitting: false,
                showProfilePictureCard: false,
            };
        },
        methods: {
            updateUserSubmit: function(){
                this.submitting = true;
                this.$store.dispatch('user/updateUserData').then((resolve) => {
                    this.submitting = false;
                    this.createToast('Success', 'Successfully updated!', 'success');
                    console.log(resolve);
                }, (reject) => {
                    this.submitting = false;
                    this.createToast('Error', 'Update unsuccessfull!', 'danger');
                });
            },
            profilePictureSrc(img){
                return (img)? 'http://stmapi.simpletomanage.com/files/' + img + '/' + img + '@800.jpg' : defaultProfilePicture;
            }
        },
        computed: {
            userData: function(){
              return this.$store.getters['user/userData'];
            }
        },
        watch: {
            imageModel: function (formData) {
                return new Promise((resolve, reject) => {
                    UploadService.uploadProfileImage(formData).then((response) => {
                       this.$store.dispatch('user/updateUserDataProfilePic', response.data);

                    }, (error) => {
                       console.log(error)
                    });
                });
            }
        },
    };
</script>
