<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Company Profile</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <img width="200" :src="companyLogoSrc(companyData.company_logo_file)" alt="Company Logo"/>
                        </div>
                        <div class="col-12 col-md-4">
                            <button type="button" class="btn btn-outline-secondary mb-2 mt-3" @click="showLogoCard = !showLogoCard">
                            Update Logo</button>
                        </div>
                    </div>
                    <br>
                    <form @submit.prevent="updateCompanyForm">
                        <div class="mb-3">
                            <label class="form-label">Company Name</label>
                            <input type="text" placeholder="Company Name" name="company_name" class="form-control" v-model="companyData.company_name"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Street Address</label>
                            <input type="text" placeholder="Street Address" name="company_street" class="form-control" v-model="companyData.company_street"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">City</label>
                            <input type="text" placeholder="City" name="company_city" class="form-control" v-model="companyData.company_city"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">State</label>
                            <input type="text" placeholder="State" name="company_state" class="form-control" v-model="companyData.company_state"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Zip Code</label>
                            <input type="text" placeholder="Zip Code" name="company_zip" class="form-control" v-model="companyData.company_zip"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" placeholder="Phone" name="company_phone" class="form-control" v-model="companyData.company_phone"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Website</label>
                            <input type="text" placeholder="Website" name="company_website" class="form-control" v-model="companyData.company_website"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Established</label>
                            <input type="text" placeholder="Established" name="company_established" class="form-control" v-model="companyData.company_established"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">EIN</label>
                            <input type="text" placeholder="EIN" name="company_ein" class="form-control" v-model="companyData.company_ein"/>
                        </div>
                        <SubmitButton :submitting="submitting" label="update" label_action="updating"/>
                    </form>
                </div>
            </div>

            <div class="col-md-6">
               <!-- Logo Card -->
                <transition name="fade">
                    <div v-if="showLogoCard">
                        <ImageCropper
                            v-model="imageModel"
							aspect-ratio="NaN"
                        />
                    </div>
                </transition>
                <!-- / Logo Card -->

                <div class="card">
                    <div class="card-header">
                        <h6>Locations</h6>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th> Name</th>
                                        <th>Address</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody id="locationsTableBody" v-if="locationData?.length">
                                    <tr v-for="row in locationData" v-bind:key="row.locationID">
                                        <td>{{ row.location_name }}</td>
                                        <td>{{ row.location_street +' '+ row.location_suite +' '+ row.location_city +' '+ row.location_state +' '+ row.location_zip }}</td>
                                        <td><button type="button" class="btn btn-link" @click="editLocation(row.locationID)">edit</button><button type="button" class="btn btn-link ml-1" @click="removeLocation(row.locationID)">delete</button></td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <div class="alert alert-warning text-center">No Locations...</div>
                                </tbody>
                            </table>
                        </div>

                    <!-- Contact Form -->
                    <button type="button" class="btn btn-outline-secondary mb-3 mt-5" v-on:click="showNewLocationForm = !showNewLocationForm">
                        <i class="bi bi-plus-circle-fill"></i> Add Location </button>
                        <transition name="slide-fade">
                            <form id="newLocationContainer" v-if="showNewLocationForm">
                                <div class="mb-3">
                                    <label class="form-label">Location Name</label>
                                    <input type="text" placeholder="Location Name" name="location_name" class="form-control" v-model="newLocationData.location_name" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Location Street</label>
                                    <input type="text" placeholder="Location Street" name="location_street" class="form-control" v-model="newLocationData.location_street"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Location Suite</label>
                                    <input type="text" placeholder="Location Suite" name="location_suite" class="form-control" v-model="newLocationData.location_suite" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Location City</label>
                                    <input type="text" placeholder="Location City" name="location_city" class="form-control" v-model="newLocationData.location_city"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Location State</label>
                                    <input type="text" placeholder="Location State" name="location_state" class="form-control" v-model="newLocationData.location_state"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Location Zip</label>
                                    <input type="text" placeholder="Location Zip" name="location_zip" class="form-control" v-model="newLocationData.location_zip"/>
                                </div>

                                <div class="mb-3">
                                    <input type="button" class="btn btn-success" value="update" v-on:click.stop="updateUserSubmit()"/>
                                </div>
                            </form>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton';
import ImageCropper from '@/components/Utilities/ImageCropper';
import UploadService from '@/service/UploadService';
import defaultImage from "@/assets/global-images/noimage.jpg";
    export default {
        name: "MyCompanyView",
        data() {
            return {
                newLocationData : {},
                submitted: false,
                showNewLocationForm: false,
                imageModel: '',
                submitting: false,
                showLogoCard: false,
            };
        },
        components:{
            ImageCropper, SubmitButton
        },
        methods: {
            updateCompanyForm(){
                this.submitting = true;
                this.$store.dispatch('company/updateCompanyData').then((resolve) => {
                    this.submitting = false;
                    this.createToast('Success', 'Company profile successfully updated!', 'success');
                    this.$refs.popupAlert.makeToast();
                }, (reject) => {
                    this.submitting = false;
                    this.createToast('Error', 'Action failed!', 'danger');
                    this.$refs.popupAlert.makeToast();
                });
            },
            createNewLocation(){
                this.$store.dispatch('location/createLocation', this.newLocationData).then((resolve) => {
                    this.locationsData = this.$store.getters['location/locationData'];
                    this.showNewLocationForm = false;
                    this.newLocationData = {}

                }, (reject) => {
                    this.createToast('Error', 'Action failed!', 'danger');
                });
            },
            editLocation(locationID){


            },
            removeLocation(locationID){
                if(confirm("Are you sure you want to remove?")){
                    this.$store.dispatch('location/removeLocation', locationID).then((resolve) => {}, (reject) => {});
                }
            },
            companyLogoSrc(img){
                return (img)? 'http://stmapi.simpletomanage.com/files/' + img + '/' + img + '@800.jpg' : defaultImage;
            }
        },
        computed: {
            companyData(){
                return this.$store.getters['company/companyData'];
            },
            locationData(){
                return this.$store.getters['location/locationData'];
            },
            "columns": function columns() {
                return (this.locationsData.length != 0) ? Object.keys(this.locationsData[0]) : [];
            }
        },
        watch: {
            imageModel: function (formData) {
                return new Promise((resolve, reject) => {
                    UploadService.uploadCompanyLogo(formData).then((response) => {
                       this.$store.dispatch('company/updateCompanyDataCompanyLogo', response.data);

                    }, (error) => {
                       console.log(error)
                    });
                });
            }
        },
        mounted() {
            /** Populate the Company Data**/
            this.$store.dispatch('company/setCompanyData').then((success) => {
               console.log(success);
            },(reject) => {
                /**
                 * ToDo
                 * show an error message
                 **/
                console.log(reject);
            });

            /** Populate the Locations Data**/
            this.$store.dispatch('location/setLocationData').then((success) => {
                console.log(success);
            },(reject) => {
                /**
                 * ToDo
                 * show an error message
                 **/
                console.log(reject);
            });

        }
    };
</script>
