<template>
    <form @submit.prevent="submitEmployeeForm">
        <div class="mb-3">
            <label class="form-label">First Name</label>
            <input type="text" placeholder="First Name" class="form-control" name="employee_first_name" v-model="workingEmployeeData.employee_first_name" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_first_name', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Middle Name</label>
            <input type="text" placeholder="Middle Name" class="form-control" name="employee_middle_name" v-model="workingEmployeeData.employee_middle_name" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_middle_name', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input type="text" placeholder="Last Name" class="form-control" name="employee_last_name" v-model="workingEmployeeData.employee_last_name" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_last_name', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label" for="title">Employee Title</label>
            <div class="float-end">
                <a v-if="!isTitleFormOpen" @click.prevent="titleForm($event, true)" class="btn btn-info text-white">
                    <i class="bi bi-plus-circle-fill"></i> Add
                </a>
                <a v-else @click="titleForm($event, false)" class="btn btn-info text-white">
                    <i class="bi bi-times"></i> Close
                </a>
            </div>

            <div v-if="!isTitleFormOpen" class="select-wrapper">
                <span @click="setEdiTtitle()">Edit</span>
                <select ref="employeeTitleID" v-model="workingEmployeeData.employee_titleID" class="form-control" @change="updateWorkingEmployeeDataField('employee_titleID', $event.target.value)">
                    <option class="row"
                        v-for="item, index in employeeTitleList"
                        :key="index"
                        :mValue="item"
                        :selected="item.employee_titleID == workingEmployeeData.employee_titleID"
                        :value="item.employee_titleID">
                            {{item?.employee_title}}
                    </option>
                </select>
            </div>
             <div v-else>
                <div class="form-label">
                    <input type="text" placeholder="Enter a new Employee Title" class="form-control" :value="workingEmployeeTitleData.employee_title" @keyup.stop="updateWorkingEmployeeTitleDataField('employee_title', $event.target.value)" autocomplete="off" />
                </div>
                <div class="mb-3">
                    <label class="form-label">&nbsp;</label>
                    <input @click="submitEmployeeTitle" type="submit" class="btn btn-success" value="submit"/>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Street</label>
            <input type="text" placeholder="Street" class="form-control" name="employee_street" v-model="workingEmployeeData.employee_street" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_street', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Street 2</label>
            <input type="text" placeholder="Street 2" class="form-control" name="employee_street2" v-model="workingEmployeeData.employee_street2" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_street2', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">City</label>
            <input type="text" placeholder="City" class="form-control" name="employee_city" v-model="workingEmployeeData.employee_city" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_city', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">State</label>
            <input type="text" placeholder="State" class="form-control" name="employee_state" v-model="workingEmployeeData.employee_state" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_state', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Zip</label>
            <input type="text" placeholder="Zip" class="form-control" name="employee_zip" v-model="workingEmployeeData.employee_zip" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_zip', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Phone 1</label>
            <input type="text" placeholder="Phone 1" class="form-control" name="employee_phone1" v-model="workingEmployeeData.employee_phone1" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_phone1', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Phone 2</label>
            <input type="text" placeholder="Phone 2" class="form-control" name="employee_phone2" v-model="workingEmployeeData.employee_phone2" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_phone2', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="text" placeholder="Email" class="form-control" name="employee_email" v-model="workingEmployeeData.employee_email" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_email', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Employee Date of Birth</label>
            <input type="date" placeholder="Employee Date of Birth" class="form-control" name="employee_dob" v-model="workingEmployeeData.employee_dob" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_dob', $event.target.value)"/>
        </div>

        <div class="form-group mb-3">
            <div class="form-check form-check-inline" v-for="optionName, optionValue in genderOptions" :key="optionValue">
                <input class="form-check-input" type="radio" name="employee_gender" :value="optionValue"
                    @change="updateWorkingEmployeeDataField('employee_gender', $event.target.value)"
                    :checked="gender(workingEmployeeData.employee_gender, optionValue)"
                    v-model="workingEmployeeData.employee_gender">
                <label class="form-check-label">{{ optionName }}</label>
            </div>
        </div>

        <div class="form-check mb-3">
            <label class="form-check-label" for="usCitizen">
                US Citizen
            </label>
            <input class="form-check-input" name="employee_us_citizen" type="checkbox" value="1" id="usCitizen"
                :checked="workingEmployeeData.employee_us_citizen"
                v-model="workingEmployeeData.employee_us_citizen"
                v:change="updateWorkingEmployeeDataField('employee_us_citizen', $event.target.value)">
        </div>

        <div class="form-check mb-3">
            <label class="form-check-label" for="maritalStatus">
                Marital Status
            </label>
            <input type="checkbox" class="form-check-input" value="1" id="maritalStatus" name="employee_marital_status" v-model="workingEmployeeData.employee_marital_status"
                v:hange="updateWorkingEmployeeDataField('employee_marital_status', $event.target.value)"
                :checked="workingEmployeeData.employee_marital_status"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Employee SSN</label>
            <input type="text" placeholder="Employee SSN" class="form-control" name="employee_ssn" v-model="workingEmployeeData.employee_ssn" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_ssn', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Hire Date</label>
            <input type="date" class="form-control" name="employee_hire_date" v-model="workingEmployeeData.employee_hire_date" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_hire_date', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Termination Date</label>
            <input type="date" class="form-control" name="employee_termination_date" v-model="workingEmployeeData.employee_termination_date" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_termination_date', $event.target.value)"/>
        </div>

        <div class="mb-3">
            <label class="form-label">Termination Reason</label>
            <textarea name="vendor_note" placeholder="Termination Reason" v-on:keyup.stop="updateWorkingEmployeeDataField('employee_termination_reason', $event.target.value)" class="form-control" v-model="workingEmployeeData.employee_termination_reason"></textarea>

        </div>
        <SubmitButton :submitting="submitting" width="w-100"/>
    </form>
</template>
<script>
// Services
import employeeTitleService from '@/service/EmployeeTitleService';
import employeeService from '@/service/EmployeeService';
    export default {
        name: "EmployeeForm",
        data(){
            return {
                isTitleFormOpen: false,
                isTitleFormEditMode: true,
                employeeTitleList: [],
                workingEmployeeTitleData: {},
                genderOptions: {
                    0: 'Male',
                    1: 'Female',
                },
                submitting: false
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            pulledEmployeeWorkingData: {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['employeeAdded', 'employeeUpdated'],
        computed: {
            workingEmployeeData(){
                if(this.editing){
                    return this.pulledEmployeeWorkingData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingEmployeeTitleDataField(field, value){
                this.workingEmployeeTitleData[field] = value;
            },
                     updateWorkingEmployeeDataField(field, value){
                this.workingEmployeeData[field] = value;
            },
            submitEmployeeForm(){
                this.submitting = true;
                let data = this.workingEmployeeData;
                if(!this.editing){
                    new Promise((resolve, reject) => {
                        if(data.employee_first_name && data.employee_last_name){
                            employeeService.create(data).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('employeeAdded', response.data.record);
                                    this.createToast('Success', 'Employee successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Vendor Data to Send');
                        }
                    });
                } else {
                    new Promise((resolve, reject) => {
                        employeeService.update(data.employeeID, data).then((response) => {
                            this.submitting = false;
                            if(response.data.status === 200){
                                this.$emit('employeeUpdated');
                                this.createToast('Success', 'Employee successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            submitEmployeeTitle(){
                /**ToDo
                 * emit Wait gif overlay
                 **/
                if(this.isTitleFormEditMode){
                   new Promise((resolve, reject) => {
                        employeeTitleService.update(this.workingEmployeeTitleData.employee_titleID, this.workingEmployeeTitleData).then((response) => {
                        if(response.data.status === 200){
                                let payload = response.data.record;
                                const index = this.employeeTitleList.map(x => {
                                    return x.employee_titleID;
                                }).indexOf(payload.employee_titleID);
                                if(index !== -1){
                                    this.employeeTitleList[index] = payload;
                                }
                                this.isTitleFormOpen = false;
                                this.createToast('Success', 'Employee title successfully updated!', 'success');
                                resolve(response);
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
                else{
                    new Promise((resolve, reject) => {
                        if(this.workingEmployeeTitleData.employee_titleID){
                            employeeTitleService.create(this.workingEmployeeTitleData).then((response) => {
                                if(response.data.status == 200){
                                    this.employeeTitleList.push(response.data.record);
                                    this.createToast('Success', 'Employee title successfully added!', 'success');
                                    this.isTitleFormOpen = false;
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                            }
                        else{
                            this.isTitleFormOpen = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Employee Data to Send');
                        }
                    });
                }
            },
            titleForm(event, value){
                if(!value){
                    this.pullEmployeeTitleList();
                }
                this.workingEmployeeTitleData.employee_title = '';
                this.isTitleFormOpen = value;
                this.isTitleFormEditMode = false;
            },
            setEdiTtitle(){
                this.isTitleFormOpen = true;
                this.isTitleFormEditMode = true;
                let employee_titleID = this.$refs.employeeTitleID.value;
                let setTitle = this.employeeTitleList.filter(item => item.employee_titleID == employee_titleID)[0];
                this.workingEmployeeTitleData = setTitle;
            },
            pullEmployeeTitleList(){
                new Promise((resolve, reject) => {
                    employeeTitleService.getEmployeeTitleList().then((response) => {
                        if(response.data.status === 200){
                            this.employeeTitleList = response.data.record;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            gender(gender, value){
                return (gender == value)? true: false;
            },
            clearFormData(){
                //clear the form & refocus for next entry
                this.workingEmployeeData = {};
            },
        },
        mounted(){
            this.pullEmployeeTitleList();
        }
    };
</script>
<style scoped>
.select-wrapper {
  position: relative;
}

.select-wrapper span {
  content: "Edit";
  font-size: 1rem;
  top: 12px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}
</style>
