import http from "../api";

class PurchaseOrderService {
    /**
     * Get Vendors with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/PurchaseOrder", {params: parameters});
    }

    getByID(ID) {
        return http.get('/PurchaseOrder/'+ID);
    }

    create(data) {
        return http.post("/PurchaseOrder", data);
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/PurchaseOrder', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/PurchaseOrder/', {params: {purchase_orderID: id}});
    }
}

export default new PurchaseOrderService();