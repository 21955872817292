<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Estimate</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <div class="card-body">
                        <EstimateForm 
                            @estimateAdded="estimateAdded"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EstimateForm from '@/components/Estimates/EstimateForm';
    export default {
        name: "EstimateCreateView",
        components: {
            EstimateForm
        },
        methods: {
            estimateAdded(estimate){
                this.$router.push({ name: 'EstimateDetailView', params: {ID: estimate.estimateID}});
            }
        }
    };
</script>