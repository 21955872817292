<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">Supply Item Form</div>
                    <div class="card-body">
                        <SupplyItemForm
                            :pulledWorkingSupplyItemData="pulledWorkingSupplyItemData"
                            :editing="true"
                            @supplyItemUpdated="supplyItemUpdated"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SupplyItemForm from '@/components/Supplys/SupplyItemForm';
// Services
import supplyItemService from '@/service/SupplyItemService';
    export default {
        name: "SupplyDetailView",
        components: {
            SupplyItemForm,
        },
        data(){
            return {
                pulledWorkingSupplyItemData: {},
                supply_categoryID: null,
            }
        },
        methods: {
            pullWorkingSupplyItemData(){
                this.supply_categoryID = this.$route.params.ID;
                return new Promise((resolve, reject) => {
                    supplyItemService.getByID(this.supply_categoryID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingSupplyItemData = response.data.record;
                           resolve();
                        }
                       else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                       }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    })
                });
            },
            supplyItemUpdated(){
                this.$router.push({name: 'SupplyView'});
            }

        },
        mounted(){
           this.pullWorkingSupplyItemData();
        }
    };
</script>
