import http from "../api";

class JobService {

    /**
     * Get Jobs with pagination and sort clauses
     * @param {object} parameters {pagStart, pagLimit, orderBy, orderDirection}
     */
    getPaginatedtList(parameters){
        return http.get("/Job", {params: parameters});
    }

    getByID(id){
        return http.get("/Job/"+id);
    }

    create(data) {
        return http.post("/Job", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Job', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Job', {params: {jobID: id}});
    }
}

export default new JobService();