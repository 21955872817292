<template>
    <form v-on:submit.prevent="submitSupplyCategoryForm">
        <div class="mb-3">
            <label class="form-label">Category Name</label>
            <input type="text" v-model="workingSupplyCategoryData.supply_category_name" placeholder="Category Name" class="form-control" name="supply_category_name" v-on:keyup.stop="updateWorkingSupplyCategoryData('supply_category_name', $event.target.value)" autocomplete="off" />
        </div>

        <div v-if="supplyCategories">
            <div v-if="!editing">
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" value="" id="subCatCheck" v-model="makeSubCategoryCheck">
                    <label class="form-check-label" for="subCatCheck">
                        Make this a Sub-Category
                    </label>
                </div>

                <div class="form-group mb-3" v-if="makeSubCategoryCheck">
                    <label>Choose The Parent Category</label>
                    <select name="parent_categoryID" class="form-control" @change="updateWorkingSupplyCategoryData('parent_categoryID', $event.target.value)">
                        <option
                            v-for="rowsss, index in supplyCategories"
                            :key="index"
                            :value="rowsss.parent_categoryID"
                            >
                            {{ rowsss.supply_category_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <SubmitButton :submitting="submitting"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import supplyCategoryService from '@/service/SupplyCategoryService';
    export default {
        name: "SupplyCategoryForm",
        components: { SubmitButton },
        data() {
            return {
                categoryID: null,
                makeSubCategoryCheck: false,
                submitting: false,
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            supplyCategories: {
                required: false,
            },
            pulledWorkingSupplyCategoryData: {
                type: Object,
                required: false,
                default: null,
            }
        },
        emits: ['supplyCategoryAdded'],
        computed: {
            workingSupplyCategoryData(){
                if(this.editing){
                    return this.pulledWorkingSupplyCategoryData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingSupplyCategoryData(field, value) {
                this.workingSupplyCategoryData[field] = value;
            },
            submitSupplyCategoryForm(){
                this.submitting = true;
                if(!this.editing){
                    return new Promise((resolve, reject) => {
                        if(this.workingSupplyCategoryData.supply_category_name){
                            supplyCategoryService.create(this.workingSupplyCategoryData).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('supplyCategoryAdded')
                                    this.createToast('Success', 'Supply Category Successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            reject('No Job Data to Send');
                        }
                    });
                }
                else{
                return new Promise((resolve, reject) => {
                    supplyCategoryService.update(this.workingSupplyCategoryData.supply_categoryID, this.workingSupplyCategoryData).then((response) => {
                        this.submitting = false;
                        if(response.data.status === 200){
                            this.$router.push({name: 'SupplyView'});
                            this.createToast('Success', 'Supply Category Successfully updated!', 'success');
                            this.clearFormData();
                            resolve(response);
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                this.makeSubCategoryCheck = false;
            },
        },
    };
</script>
