<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">Supply Category Item Form</div>
                    <div class="card-body">
                        <SupplyCategoryForm
                            :pulledWorkingSupplyCategoryData="pulledWorkingSupplyCategoryData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SupplyCategoryForm from '@/components/Supplys/SupplyCategoryForm';
import supplyCategoryService from '@/service/SupplyCategoryService';
    export default {
        name: "SupplyCategoryDetailView",
        components: {
            SupplyCategoryForm,
        },
        data(){
            return {
                pulledWorkingSupplyCategoryData: {},
                supply_itemID: null,
            }
        },
        methods: {
            pullWorkingSupplyCategoryData(){
                this.supply_itemID = this.$route.params.ID;
                return new Promise((resolve, reject) => {
                    supplyCategoryService.getByID(this.supply_itemID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingSupplyCategoryData = response.data.record;
                           resolve();
                        }
                        else{
                           this.createToast('Error', response.data.message, 'danger');
                           reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    })
                });
            },
        },
        mounted(){
           this.pullWorkingSupplyCategoryData();
        }
    };
</script>
