<template>
    <div class="container-fluid app-view-container mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>{{ pulledContactWorkingData.contact_fname }} {{ pulledContactWorkingData.contact_lname }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <ContactForm
                            :pulledContactWorkingData="pulledContactWorkingData"
                            :editing="true"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h6>Notes</h6>
                    </div>
                    <div class="card-body">
                        <!-- Note Form -->
                        <form id="newContactNote" @submit.prevent="createNewNote">
                            <div class="mb-3">
                                <label class="form-label">New Contact Note</label>
                                <textarea placeholder="New Contact Note" name="contact_note" class="form-control" v-model="newNoteInput"></textarea>
                            </div>
                            <SubmitButton :submitting="noteSubmitting"/>
                        </form>
                        <!-- /Note forceRerender Form -->

                        <!-- Contact Notes -->
                        <div v-if="pulledContactWorkingData.contactNotes" class="mt-3">
                            <div class="card mb-2" v-for="row in pulledContactWorkingData.contactNotes" v-bind:key="row.contact_notesID">
                                <div class="card-body">
                                    <p>{{ row.contact_note }}</p>
                                </div>
                                <div class="card-footer">
                                    <span class="float-start">{{ row.user.user_fname }} {{ row.user.user_lname }}</span>&nbsp; <span class="float-end">{{ row.created }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- /Contact Notes -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContactForm from '@/components/Contacts/ContactForm';
import contactService from '@/service/ContactService';
import SubmitButton from '@/components/Utilities/SubmitButton'
    export default {
        name: "ContactDetailView",
        components: {
            ContactForm, SubmitButton
        },
        data(){
            return {
                newNoteInput: '',
                pulledContactWorkingData: {},
                noteSubmitting: false
            }
        },
        methods: {
            createNewNote: function() {
                if(this.newNoteInput && this.newNoteInput != ''){
                    this.noteSubmitting = true;
                    let data = {contact_note: this.newNoteInput, contactsID: this.pulledContactWorkingData.contactsID}
                    contactService.createContactNote(data).then((response) => {
                        this.noteSubmitting = false;
                        if(response.data.status === 200){
                            this.getContactByID();
                            this.newNoteInput = '';
                            this.createToast('Success', 'Note successfully added!', 'success');
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.noteSubmitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                }
                else{
                    /**ToDo
                     * Handle empty note
                     **/
                }
            },
            getContactByID(){
                this.contactID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    contactService.getByID(this.contactID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledContactWorkingData = response.data.record;
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
        },
        mounted(){
            this.getContactByID();
        }
    };
</script>
