
<template>
    <div class="container-fluid app-view-containe mt-4">
        <div class="row">
            <div class="col-12 app-view-title">
                <h6>Sales Estimate</h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="mb-4">
                    <div class="card-body">
                        <EstimateForm
                            @estimateUpdated="estimateUpdated"
                            :pulledWorkingEstimateData="pulledWorkingEstimateData"
                            :editing="true"
                        />
                        <div class="mt-3">
                            <div v-if="!estimateItemList.length" class="mt-3 ,">
                                <div v-if="!estimateItemList.length && !estimateItemListLoading"></div>
                                <div v-else-if="!estimateItemList.length && estimateItemListLoading">Loading...</div>
                                <div v-else class="text-danger">Error...</div>
                            </div>
                            <div v-else><br>
                                <!-- Contact Table -->
                                <h4>Estimate Item(s)</h4><hr>
                                <table class="table responsive-table">
                                    <thead>
                                    <tr>
                                        <th scope="col" style="width: 40px;">&nbsp;</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col" class="text-center">Quantity</th>
                                        <th scope="col">Cost</th>
                                        <th scope="col">Tax</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row, index in estimateItemList" :key="index">
                                            <td style="width: 40px;">
                                                <button type="button" @click="removeEstimateItem(row)" class="ml-2 btn btn-danger btn-sm">x</button>
                                            </td>
                                            <td data-title="Item Name" class="text-start" scope="row" v-if="row.product?.product_name">{{row.product?.product_name}} </td>
                                            <td data-title="Item Name" scope="row" v-else>{{row.product_custom_name}} </td>
                                            <td data-title="Quantity">{{row.estimate_item_quantity}}</td>
                                            <td data-title="Cost" class="text-end">{{numberWithCommas(row.estimate_item_price)}}</td>
                                            <td data-title="Tax" class="text-end">{{numberWithCommas(row.estimate_item_tax)}}</td>
                                            <td data-title="Total" class="text-end">{{numberWithCommas(row.estimate_item_total)}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>

                                    </tr>
                                    </tfoot>
                                </table>
                                <!-- /Contact Table -->
                            </div>

                            <div class="clearfix mb-3 mt-3">
                                <button type="button" class="btn btn-outline-secondary float-start" @click="showItemForm = !showItemForm"><i class="bi bi-plus-circle-fill"></i>&nbsp;  add new item</button>
                            </div>

                            <!-- New Form-->
                            <div class="mb-3">
                                <div v-if="showItemForm">
                                     <div class="row mb-3">
                                        <div class="col-12">
                                            <label class="form-label">Product Item:</label>
                                              <div class="float-end" @click="insertProductTypingActive = !insertProductTypingActive">
                                                <a v-if="!insertProductTypingActive" class="btn btn-info text-white">
                                                    <i class="bi bi-pencil"></i>&nbsp; Type a new product
                                                </a>
                                                <a v-else class="btn btn-info text-white">Cancel</a>
                                            </div>

                                            <select v-if="!insertProductTypingActive" class="form-control"
                                                @change="updateWorkingEstimateItenDataField('productsID', $event.target.value)">
                                                <option :value="null" disabled selected> -- select product -- </option>
                                                <option
                                                    v-for="item, index in productList"
                                                    :key="index"
                                                    :value="item.productsID"
                                                    :selected= "item.productsID == workingEstimateItemData.productsID"
                                                    >
                                                    {{ item.product_name }}
                                                </option>
                                            </select>
                                            <input v-else type="text" placeholder="Type a product" class="form-control" v-model="workingEstimateItemData.product_custom_name" v-on:keyup.stop="updateWorkingEstimateItenDataField('product_custom_name', $event.target.value)"/>
                                            <span class="text-danger">{{ errors.productError }}</span>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Item Note</label>
                                        <textarea v-model="workingEstimateItemData.estimate_item_note" placeholder="Item Note" class="form-control"
                                            @keyup.stop="updateWorkingEstimateItenDataField('estimate_item_note', $event.target.value)"
                                        ></textarea>
                                    </div>

                                    <div class="row mt-3 mb-3">
                                        <div class="col-12 col-md-6 mb-3">
                                            <label class="form-label">Price:</label>
                                            <div class="input-group">
                                                <input type="number" v-model="workingEstimateItemData.estimate_item_price" class="form-control" placeholder="0" min="1"
                                                v-on:keyup.stop="updateWorkingEstimateItenDataField('estimate_item_price', $event.target.value)" autocomplete="off">
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 mb-3">
                                            <label class="form-label">Tax:</label>
                                            <div class="input-group">
                                                <input type="number" v-model="workingEstimateItemData.estimate_item_tax" class="form-control" placeholder="0" min="1"
                                                v-on:keyup.stop="updateWorkingEstimateItenDataField('estimate_item_tax', $event.target.value)" autocomplete="off">
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 mb-3">
                                            <label class="form-label">Quantity:</label>
                                            <div class="input-group">
                                                <input type="number" v-model="workingEstimateItemData.estimate_item_quantity" class="form-control"   placeholder="0" min="1"
                                                   @keyup.stop="updateWorkingEstimateItenDataField('estimate_item_quantity', $event.target.value)" autocomplete="off">
                                                <button @click="addNewEstimateItem" class="btn btn-outline-secondary" type="button">Add Iten</button>
                                             </div>
                                        </div>
                                    </div>
                                    <!-- /New Form-->
                                </div>

                                <div v-if="estimateItemList.length">
                                    <div class="alert alert-warning clearfix">
                                        <div class="float-start">
                                           <h5 class="text-start">
                                                Total:
                                           </h5>
                                        </div>
                                         <div class="float-end">
                                           <ul class="text-end list-group">
                                                <li class="list-group-item">Sub Total&nbsp;&nbsp;
                                                    <strong>{{numberWithCommas(estimateItemSubTotal)}}</strong>
                                                </li>
                                                <li class="list-group-item">Tax&nbsp;&nbsp;
                                                    <strong>{{numberWithCommas(estimateItemTax)}}</strong>
                                                </li>
                                                <li class="list-group-item">Total&nbsp;&nbsp;
                                                    <strong>{{numberWithCommas(estimateItemGrandTotal)}}</strong>
                                                </li>
                                          </ul>
                                        </div>
                                    </div>
                                     <div class="float-end" @click="submitEstimateMainForm()">
                                        <SubmitButton :submitting="submitting" label="Save Estimate" label_action="Saving Estimate" />
                                    </div>
                                    <div class="float-end" @click.prevent="convertToSale()">
                                        <div class="mb-3 mt-4 m-y">
                                            <button v-if="!converting" type="button" class="btn btn-large btn-info text-white">Convert to sale</button>
                                            <button v-else type="button" disabled="true" class="btn btn-large btn-success btn-disabled">Converting...</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="col-md-4">

				<EIJAssetForm
					asset-parent-type="estimate"
					:asset-i-d=estimateID
					@new-asset-uploaded="newAssetUploaded"
				/>

				<EIJAssetGallery
					:assets="workingEstimateFileAssets"
				/>

			</div>
        </div>
    </div>
</template>
<script>
import EstimateForm from '@/components/Estimates/EstimateForm';
import EIJAssetForm from '@/components/EIJ_Assets/EIJAssetForm';
import EIJAssetGallery from "@/components/EIJ_Assets/EIJAssetGallery";
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import estimateService from '@/service/EstimateService';
import estimateItemService from '@/service/EstimateItemService';
import productService from '@/service/ProductService';
import invoiceService from '@/service/InvoiceService';
import invoiceItemService from '@/service/InvoiceItemService';
    export default {
        name: "EstimateDetailView",
        components: {
			EIJAssetForm,
			EIJAssetGallery,
            EstimateForm,
			SubmitButton,
        },
        data(){
            return{
                errors: {},
				estimateID: null,
                estimateItemListLoading: false,
                estimateItemList: [],
                productList: [],
                pulledWorkingEstimateData: {},
                workingEstimateItemData: {},
				workingEstimateFileAssets: {},
                showItemForm: false,
                submitting: false,
                converting: false,
                insertProductTypingActive: false,
            }
        },
        computed: {
            estimateItemSubTotal(){
                if(this.estimateItemList){
                    return this.estimateItemList.reduce(function(sum, current){
                        let total = parseFloat(+sum) + +parseFloat(current.estimate_item_price);
                        return (Math.round(total).toFixed(2));
                    }, 0);
                } else {
                    return 0.00;
                }
            },
            estimateItemTax(){
                if(this.estimateItemList){
                    return this.estimateItemList.reduce(function(sum, current){
                        let total = parseFloat(+sum) + +parseFloat(current.estimate_item_tax);
                        return (Math.round(total).toFixed(2));
                    }, 0);
                } else {
                    return 0.00;
                }
            },
            estimateItemGrandTotal(){
                if(this.estimateItemList){
                    return this.estimateItemList.reduce(function(sum, current){
                        let total = parseFloat(+sum) + +parseFloat(current.estimate_item_total);
                        return (Math.round(total).toFixed(2));
                    }, 0);
                } else {
                    return 0.00;
                }
            },
        },
        methods: {
            updateWorkingEstimateDataField(field, value){
                this.workingEstimateItemData[field] = value;
            },
            updateWorkingEstimateItenDataField(field, value){
                this.workingEstimateItemData[field] = value;
            },
            getEstimateByID(){
                this.vendorID = this.$route.params.ID;
                new Promise((resolve, reject) => {
                    estimateService.getByID(this.vendorID).then((response) => {
                        if(response.data.status === 200){
                           this.pulledWorkingEstimateData = response.data.record;
                           this.estimateItemList = response.data.record.estimate_items;
                           this.workingEstimateFileAssets = response.data.record.fileAssets;
                           resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            addNewEstimateItem(){
                let workingData = this.workingEstimateItemData;
                if((!workingData.productsID) && (!workingData.product_custom_name)){
                    this.errors.productError = "Product is required!";
                }

                if(Object.keys(this.errors).length) {
                    return;
                }

                if(this.insertProductTypingActive){
                    this.updateWorkingEstimateItenDataField('productsID', null);
                }
                else {
                    this.updateWorkingEstimateItenDataField('product_custom_name', null);
                }

                let estimateID = this.$route.params.ID,
                    itemTotal = (+workingData.estimate_item_price + +workingData.estimate_item_tax);

                this.updateWorkingEstimateItenDataField('estimateID', parseInt(estimateID));
                this.updateWorkingEstimateItenDataField('estimate_item_total', parseInt(itemTotal));

                return new Promise((resolve, reject) => {
                    if(this.workingEstimateItemData.estimateID){
                        estimateItemService.create(this.workingEstimateItemData).then((response) => {
                            if(response.data.status === 200){
                                let data = response.data.record;
                                if(!this.estimateItemList){
                                    this.estimateItemList = [data];
                                } else {
                                    this.estimateItemList.push(data);
                                }
                                this.showItemForm = false;
                                this.clearworkingEstimateItemData();
                                this.createToast('Success', 'Estimate item successfully added!', 'success');
                                resolve();
                            }
                            else{
                                this.createToast('Error', response.data.message, 'danger');
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    }
                    else{
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject('No Job Data to Send');
                    }
                });
            },
            getProductList(){
                return new Promise((resolve, reject) => {
                    productService.getAll().then((response) => {
                        if(response.data.status === 200){
                            this.productList = response.data.record;
                            resolve();
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                    });
                });
            },
            clearworkingEstimateItemData(){
                this.workingEstimateItemData = {};
            },
            removeEstimateItem(data){
                return new Promise((resolve, reject) => {
                   estimateItemService.delete(data.estimate_itemID).then((response) => {
                       const index = this.estimateItemList.map(x => {
                            return x.estimate_itemID ;
                        }).indexOf(data.estimate_itemID );
                        if(index !== -1) {
                            this.estimateItemList.splice(index, 1);
                        }
                        this.createToast('Success', 'Estimate item successfully deleted!', 'success');
                        resolve();
                   }, (error) => {
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                   });
                });
            },
            numberWithCommas(x) {
                return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            estimateUpdated(){
                return false;
            },
            submitEstimateMainForm(){
                this.submitting = true;
                let data = this.pulledWorkingEstimateData;

                data.estimate_sub_total = this.estimateItemSubTotal;
                data.estimate_sales_tax = this.estimateItemTax;
                data.estimate_total = this.estimateItemGrandTotal;

                return new Promise((resolve, reject) => {
                    estimateService.update(data.estimateID, data).then((response) => {
                        this.submitting = false;
                        if(response.data.status === 200){
                            this.createToast('Success', 'Estimate successfully updated!', 'success');
                            this.$router.push({name: 'EstimateView'});
                            resolve(response);
                            return true;
                        }
                        else{
                            this.createToast('Error', 'Estimate' + response.data.message, 'danger');
                            reject();
                            return false;
                        }
                    }, (error) => {
                        this.submitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                        return false;
                    });
                });
            },
            convertToSale(){
                this.converting = true;
                let data = this.pulledWorkingEstimateData,
                    newData = {};

                newData.estimateID = data.estimateID;
                newData.customersID = data.customersID;
                newData.invoice_title = data.estimate_title;
                newData.invoice_description = data.estimate_description;
                newData.invoice_sub_total = this.estimateItemSubTotal;
                newData.invoice_sales_tax = this.estimateItemTax;
                newData.invoice_total = this.estimateItemGrandTotal;

                new Promise((resolve, reject) => {
                    if(newData.customersID && newData.invoice_title){
                        this.submitEstimateMainForm();
                        invoiceService.create(newData).then((response) => {
                            if(response.data.status == 200){
                                this.estimateItemList.forEach((data) => {
                                    let invoiceItemData = {};
                                    invoiceItemData.invoiceID = response.data.record.invoiceID;
                                    invoiceItemData.productsID = data.productsID;
                                    invoiceItemData.product_name = data.product_name;
                                    invoiceItemData.invoice_item_price = data.estimate_item_price;
                                    invoiceItemData.invoice_item_quantity = data.estimate_item_quantity;
                                    invoiceItemData.invoice_item_tax = data.estimate_item_tax;
                                    invoiceItemData.invoice_item_total = data.estimate_item_total;
                                    invoiceItemData.invoice_item_note = data.estimate_item_note;
                                    invoiceItemService.create(invoiceItemData).then((response) => {
                                        console.log('Item Added');
                                    });
                                });

                                this.createToast('Success', 'Estimate successfully converted to sale!', 'success');
                                this.converting = false;
                                resolve(response.data.record);
                                this.$router.push({name: 'SalesInvoiceDetailView', params: {ID: response.data.record.invoiceID}});
                            }
                            else{
                                this.createToast('Error', 'Invoice' + response.data.message, 'danger');
                                this.converting = false;
                                reject(response.data.message);
                            }
                        }, (error) => {
                            this.createToast('Error', 'Action failed!', 'danger');
                            this.converting = false;
                            reject(error);
                        });


                    }
                    else{
                        reject('No Invoice Data to Send');
                    }
                });
            },
			newAssetUploaded(record){
            	this.workingEstimateFileAssets.push(record);
			}
        },
        mounted(){
            this.getEstimateByID();
            this.getProductList();
            this.estimateID = this.$route.params.ID;
        }
    };
</script>
