<template>
	<div>

	</div>
</template>

<script>
export default {
	name: "EmployeeScheduleView"
}
</script>

<style scoped>

</style>
