<template>
	<div>
		<div class="sidebar-logo-container">
			<img src="../assets/global-images/stm-logo.png"/>
		</div>

		<nav class="sidebar-nav" :class="sideBarOpen ? '' : 'hidden'" id="main-nav">
			<ul class="list-unstyled ps-0">

				<!-- DashBoard -->
				<li class="mb-1">
					<router-link :to="{ name: 'DashboardHome', params: {}}">Dashboard</router-link>
				</li>

				<!-- My Profile -->
				<li class="mb-1">
					<router-link :to="{ name: 'MyProfileView', params: {}}">My Profile</router-link>
				</li>

				<!-- My Company -->
				<li class="mb-1">
					<router-link :to="{ name: 'MyCompanyView', params: {}}">My Company</router-link>
				</li>

				<!-- Employees -->
				<li class="mb-1">
					<router-link :to="{ name: 'EmployeesView', params: {}}">Employees</router-link>
					<ul class="sub-nav list-unstyled">
						<li>
							<router-link :to="{ name: 'EmployeeScheduleView', params: {}}">Schedule</router-link>
						</li>
					</ul>
				</li>

				<!-- Customers -->
				<li class="mb-1">
					<router-link :to="{ name: 'CustomersView', params: {}}">Customers</router-link>
				</li>

				<!-- Contacts -->
				<li class="mb-1">
					<router-link :to="{ name: 'ContactsView', params: {}}">Contacts</router-link>
				</li>

				<!-- Jobs -->
				<li class="mb-1">
					<router-link :to="{ name: 'JobsView', params: {}}">Jobs</router-link>
				</li>

				<!-- Sales -->
				<li class="mb-1">
					<router-link :to="{ name: 'SalesView', params: {}}" class="">Sales</router-link>

					<ul class="btn-toggle-nav list-unstyled sub-nav" id="sales-collapse">
						<!-- Estimates -->
						<li>
							<router-link :to="{ name: 'EstimateView', params: {}}">Estimates</router-link>
						</li>
						<!-- Invoices -->
						<li>
							<router-link :to="{ name: 'InvoicesView', params: {}}">Invoices</router-link>
						</li>
						<!-- Products -->
						<li class="mb-1">
							<router-link :to="{ name: 'ProductsView', params: {}}">Products</router-link>
						</li>
					</ul>

				</li>

				<!-- Inventory -->
				<li class="mb-1">
					<router-link :to="{ name: 'InventoryView', params: {}}">Inventory</router-link>
				</li>

				<!-- Vendors -->
				<li class="mb-1">
					<router-link :to="{ name: 'VendorsView', params: {}}">Vendors</router-link>
					<ul class="sub-nav list-unstyled">
						<!-- Supplies  -->
						<li class="mb-1">
							<router-link :to="{ name: 'SupplyView', params: {}}">Supplies</router-link>
						</li>
						<!-- Purchase Order  -->
						<li class="mb-1">
							<router-link :to="{ name: 'PurchaseOrderView', params: {}}">Purchase Order</router-link>
						</li>
					</ul>
				</li>

				<!-- Marketing -->
				<li class="mb-1">
					<router-link :to="{ name: 'MarketingView', params: {}}">Marketing</router-link>
				</li>

				<!-- To Do -->
				<li class="mb-1">
					<router-link :to="{ name: 'ToDoView', params: {}}">To Do</router-link>
				</li>

				<li class="mt-3 border-top"></li>

				<!-- Exit -->
				<li class="mb-1">
					<a class="" href="" v-on:click.stop.prevent="exitApp()">Log Out</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import {mapState} from 'vuex';

export default {
	name: 'Sidebar',
	computed: {
		...mapState(['sideBarOpen'])
	},
	methods: {
		exitApp() {
			this.$store.dispatch('user/logOut').then((resolve) => {
				this.$router.push("/");
			}, (reject) => {
				console.log(reject);
			});
		}
	}
};
</script>

<style scoped lang="scss">
.sidebar-logo-container {
	padding: 15px 0px 15px 0px;
	border-bottom: 1px solid $slate-gray;
}

.sidebar-logo-container img {
	height: 45px;
	width: auto;
}

.sub-nav{
	padding: 3px 0px 0px 10px;
	margin: 0px;
}

.sub-nav li{
	padding: 0px;
	margin: 0px;
}


</style>
