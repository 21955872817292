<template>
    <form @submit.prevent="submitProductCategoryForm">
        <div class="mb-3">
            <label class="form-label">Category Name</label>
            <input type="text" placeholder="Category Name" name="product_category_name" class="form-control" v-model="workingProductCategoryData.product_category_name"/>
        </div>
        <div v-if="!editing" class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="subCatCheck" v-model="makeSubCategoryCheck">
            <label class="form-check-label" for="subCatCheck">
                Make this a Sub-Category
            </label>
        </div>
        <div class="form-group" v-if="makeSubCategoryCheck">
            <label>Choose The Parent Category</label>
            <select name="parent_categoryID" v-model="workingProductCategoryData.parent_categoryID" class="form-control" @change="updateWorkingProductCategoryData('parent_categoryID', $event.target.value)">
                <option value="">Select Category</option>
                <option
                    v-for="row, index in productCategories"
                    :key="index"
                    :value="row.product_categoryID">
                    {{ row.product_category_name }}
                </option>
            </select>
        </div>
        <SubmitButton :submitting="submitting"/>
    </form>
</template>
<script>
import SubmitButton from '@/components/Utilities/SubmitButton'
// Services
import productCategoryService from '@/service/ProductCategoryService';
    export default {
        name: "ProductCategoryForm",
        components: { SubmitButton },
        data() {
            return {
                categoryID: null,
                makeSubCategoryCheck: false,
                newParentCategorySelected: null,
                submitting: false,
            }
        },
        props: {
            editing: {
                type: Boolean,
                required: false,
                default: false,
            },
            productCategories: {
                required: false,
            },
            pulledWorkingProductCategoryData: {
                type: Object,
                required: false,
                default: null,
            }
        },
        emits: ['productCategoryAdded'],
        computed: {
            workingProductCategoryData(){
                if(this.editing){
                    return this.pulledWorkingProductCategoryData;
                } else {
                    return {};
                }
            },
        },
        methods: {
            updateWorkingProductCategoryData(field, value) {
                this.workingProductCategoryData[field] = value;
            },
            submitProductCategoryForm(){
                this.submitting = true;
                if(!this.editing){
                    return new Promise((resolve, reject) => {
                        if(this.workingProductCategoryData.product_category_name){
                            productCategoryService.create(this.workingProductCategoryData).then((response) => {
                                this.submitting = false;
                                if(response.data.status == 200){
                                    this.$emit('productCategoryAdded', response.data.record)
                                    this.createToast('Success', 'Product category successfully added!', 'success');
                                    this.clearFormData();
                                    resolve(response.data.record);
                                }
                                else{
                                    this.createToast('Error', response.data.message, 'danger');
                                    reject(response.data.message);
                                }
                            }, (error) => {
                                this.submitting = false;
                                this.createToast('Error', 'Action failed!', 'danger');
                                reject(error);
                            });
                        }
                        else{
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject('No Job Data to Send');
                        }
                    });
                }
                else{
                return new Promise((resolve, reject) => {
                    productCategoryService.update(this.workingProductCategoryData.product_categoryID, this.workingProductCategoryData).then((response) => {
                        this.submitting = false;
                        if(response.data.status === 200){
                            this.createToast('Success', 'Product category successfully updated!', 'success');
                            this.$router.push({name: 'ProductsView'});
                            this.clearFormData();
                            resolve(response);
                        }
                        else{
                            this.createToast('Error', response.data.message, 'danger');
                            reject(response.data.message);
                        }
                    }, (error) => {
                            this.submitting = false;
                            this.createToast('Error', 'Action failed!', 'danger');
                            reject(error);
                        });
                    });
                }
            },
            clearFormData(){
                this.makeSubCategoryCheck = false;
                this.workingProductCategoryData.product_category_name = '';
            },
        },
    };
</script>
