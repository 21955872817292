import locationService from '../../service/LocationService';
export const namespaced = true;

export const state = {
    locationData: []
};

export const getters = {
    locationData: state => {
        return state.locationData
    },
};

export const mutations = {
    updateLocationData(state, locationData){
        state.locationData = locationData
    },
    addNewLocation(state, locationData){
        state.locationData.push(locationData);
    },
    removeLocation(state, locationID){
        const index = state.locationData.map(x => {
            return x.locationID;
        }).indexOf(locationID);
        if(index !== -1) {
            state.locationData.splice(index, 1);
        }
    },
};

export const actions = {
    createLocation({commit, state}, payload){
        return new Promise((resolve, reject) => {
            locationService.create(payload).then((response) => {
                if(response.data.status === 200){
                    commit('addNewLocation', response.data.record);
                    resolve();
                }
                 else{
                     reject(response.data.message);
                 }
            }, (error) => {
                reject(error);
            });
        });
    },
    setLocationData({commit, state}){
        return new Promise((resolve, reject) => {
            locationService.getAll().then((response) => {
                if(response.data.status === 200){
                    commit('updateLocationData', response.data.record);
                    resolve();
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    updateLocationData({commit, state}, payload){
        return new Promise((resolve, reject) => {
            locationService.update(state.locationData[payload.locationsID].locationsID, state.locationData[payload.locationsID]).then((response) => {
                if(response.data.status === 200){
                    console.log(response.data.record);
                    commit('updateUserData', response.data.record);
                    resolve(response);
                }
                else{
                    reject(response.data.message);
                }
            }, (error) => {
                reject(error);
            });
        });
    },
    removeLocation({commit, state}, payload){
        return new Promise((resolve, reject) => {
           locationService.delete(payload).then((response) => {
               commit('removeLocation', payload);
               resolve();
           }, (error) => {
              reject(error);
           });
        });
    },
};
