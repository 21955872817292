<template>
    <div class="container-fluid">
        <form @submit.prevent="submit" class="login-form center-box">
            <h4 class="text-center">Reset Password</h4>
            <div class="mb-2 mt-1 row">
                <label for="password" class="col-sm-12 col-form-label">New Password</label>
                <div class="col-sm-12">
                    <input
                      type="text"
                      class="form-control"
                      v-model="password"
                      placeholder="Type new password here..."
                      autocomplete="off"
                  />
                </div>
            </div>
            <div class="mb-2 mt-1 row">
                <label for="password" class="col-sm-12 col-form-label">Re-type New Password</label>
                <div class="col-sm-12">
                    <input
                      type="text"
                      class="form-control"
                      v-model="passwordConfirm"
                      placeholder="Re-type your new password here..."
                      autocomplete="off"
                  />
                </div>
            </div>
            <SubmitButton
                :submitting="submitting"
                label="Submit"
                label_action="Submitting...."
                width="w-100"/>
        </form>
    </div>
</template>
<script>
import http from "@/api";
    export default {
        name: "ResetPassword",
        data: function() {
            return {
                password: '',
                passwordConfirm: '',
                submitting: false,
            };
        },
        methods: {
            submit(){
                this.submitting = true;
                const data = {
                    key: this.$route.query.key,
                    email: this.$route.query.email,
                    password: this.password,
                    passwordConfirm: this.passwordConfirm,
                    reset: true
                };

                new Promise((resolve, reject) => {
                    http.post("public/reset-password/", JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Credentials': true,
                                'Content-Type': 'application/json; charset=UTF-8'
                            }
                        }).then((response) => {
                            if(response.data.status === 200){
                                this.submitting = false;
                                this.createToast('Success', response.data.message, 'success');
                                this.$router.push({name: 'Home'});
                            }
                            else {
                                this.email = '';
                                this.submitting = false;
                                this.createToast('User Error', response.data.message, 'danger');
                            }
                        });
                    }, (error) => {
                        this.submitting = false;
                        this.createToast('Error', 'Action failed!', 'danger');
                        reject(error);
                });
            }
        },
    };
</script>
