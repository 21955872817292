import http from "../api";

class ProductService {
    getAll() {
        return http.get("/Product");
    }

    getByID(ID) {
        return http.get('/Product/'+ID);
    }

    create(data) {
        return http.post("/Product", data);
    }

    search(parameters){
        return http.get("/public/search/", {params: parameters});
    }

    update(id, data) {
        data.ID = id;
        return http.patch('/Product', JSON.stringify(data));
    }

    delete(id) {
        return http.delete('/Product/'+id);
    }

}

export default new ProductService();
